import React, { Component } from 'react';

export default function PrimaryButton(props) {
  const {
    label,
    placeholder,
    onClick,
    style,
    type,
    icon,
    isLoading } = props;

  var styleModifiers = "";
  if (style) {
    if (style instanceof Array) {
      style.forEach(element => {
        styleModifiers = styleModifiers + " is-" + element;
      });
    } else {
      styleModifiers = " is-" + style;
    }
  }
  var loadingState = isLoading ? " is-loading" : "";
  const buttonStyles = "button is-rounded is-primary" + styleModifiers + loadingState;

  const buttonIcon = (
    <span className="icon">
      {icon}
    </span>
  );

  return (
    <button className={buttonStyles} placeholder={placeholder} onClick={onClick} type={type}>
      {icon && buttonIcon}
      <span>
        {label}
      </span>
    </button>
  );
}