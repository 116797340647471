import React, { useEffect, Fragment } from "react";
import stylesheet from "../Pages.module.scss";
import Header from "../../common/typography/Header";
import Paragraph from "../../common/typography/Paragraph";
import SocialLinks from "../SocialLinks";

const Contact = () => {
  useEffect(() => {
    document.title = "Contact / Animatik";
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Header
        level={2}
        size={"medium"}
        message={"Contact"}
        styles={{ marginBottom: "0.75rem" }}
      />
      <Paragraph>
        For all general inquiries, contact us by email at{" "}
        <a href="mailto:hello@animatik.co" target="_blank" className="link">
          hello@animatik.co
        </a>
        . Or, you can interact with us through other platforms we are active on:
      </Paragraph>
      <div className={stylesheet.socials}>
        <Paragraph>
          <SocialLinks />
        </Paragraph>
      </div>
    </Fragment>
  );
};
export default Contact;
