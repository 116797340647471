import React, { useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "../../common/typography/Header";
import Paragraph from "../../common/typography/Paragraph";

const Privacy = () => {
  useEffect(() => {
    document.title = "Privacy policy / Animatik";
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Header
        level={2}
        size={"medium"}
        message={"Privacy policy"}
        styles={{ marginBottom: "0.75rem" }}
      />
      <Paragraph>Updated November 12, 2020.</Paragraph>
      <Paragraph>
        Animatik is committed to protecting the privacy of personal information.
        Amendments to this Privacy policy will be posted on our site and will be
        effective when posted. Your continued use of the site and services
        following the posting of any amendment to this Privacy policy
        constitutes your acceptance of such amendment. If you have any questions
        or comments about this Privacy policy or your personal information,{" "}
        <Link className="link" to={`/about/contact`}>
          contact us
        </Link>
      </Paragraph>
      <Header
        level={3}
        size={"small"}
        message={"Information collection and use"}
      />
      <Paragraph>
        When you register as a user of our site and services, we ask for
        personal information that will be used to activate your account, provide
        services to you, communicate with you about the status of your account,
        and for other purposes set out in this Privacy policy. You will also be
        asked to create a username and private password, which will become part
        of your account information.
      </Paragraph>
      <Paragraph>
        By providing personal information to us, you voluntarily consent to the
        collection, use, and disclosure of such personal information as
        specified in this Privacy policy. The processing of this information is
        necessary for providing our services, which are further explained below.
      </Paragraph>
      <Paragraph>
        Your personal information may be stored and processed in any country
        where we have facilities or in which we engage third party service
        providers. By using our service, you consent to the transfer of
        information to countries outside your country of residence, which may
        have different data protection rules than in your country. While such
        information is outside of Canada, it is subject to the laws of the
        country in which it is held, and may be subject to disclosure to the
        governments, courts or law enforcement or regulatory agencies of such
        other country, pursuant to the laws of such country. However, our
        practices regarding your personal information will at all times continue
        to be governed by this Privacy policy and, if applicable, we will comply
        with the General Data Protection Regulation requirements providing
        adequate protection for the transfer of personal information from the
        EU/EEA to third country.
      </Paragraph>
      <Header level={3} size={"small"} message={"Age of consent"} />
      <Paragraph>
        We do not knowingly provide our site and services to, and will not
        knowingly collect the personal information from anyone under the minimum
        age of consent. The minimum age of consent is thirteen (13), or sixteen
        (16) years old in the EU/EEA, or any such higher age that is required in
        your country to register for or use our services. Additionally, if you
        are not old enough to be able to consent to our Privacy policy in your
        country, your parent or guardian must agree to the policy on your
        behalf. If you have any concerns about you or your child's personal
        information,{" "}
        <Link className="link" to={`/about/contact`}>
          contact us
        </Link>
        .
      </Paragraph>
      <Paragraph>
        Our site and services are not intended for children under the minimum
        age of consent: thirteen (13) years of age, or sixteen (16) in the
        EU/EEA, or any such higher age in your country. No one under the minimum
        age of consent may provide any personal information on our site or
        through our services. We do not knowingly collect personal information
        from children under the minimum age of consent. If you are under this
        minimum age, do not provide any information on our site or through our
        services, or use any of the interactive public-facing features we offer
        such as comments. Do not provide us with your name, address, number,
        email, or any screen or user name that you may use. If we learn we have
        collected or received information from a child under the minimum age of
        consent, we will delete that information. If you believe we have any
        information from or about a child under the minimum age of consent,{" "}
        <Link className="link" to={`/about/contact`}>
          contact us
        </Link>
        .
      </Paragraph>
      <Header level={3} size={"small"} message={"Rights to your information"} />
      <Paragraph>
        You have the right to access and edit your information at any time. On
        written request and subject to proof of identity, you may access the
        personal information that we hold, used or communicated and ask that any
        necessary corrections be made, where applicable, as authorized or
        required by law.
      </Paragraph>
      <Paragraph>
        Under the General Data Protection Regulation in the EU, you may be
        entitled to additional rights to your information. If you want to learn
        more about your rights under the GDPR, you can visit the European
        Commission’s{" "}
        <a
          href="https://ec.europa.eu/info/law/law-topic/data-protection_en"
          className={"link"}
          target="_blank"
        >
          page on Data Protection
        </a>
        .
      </Paragraph>
      <Header level={3} size={"small"} message={"Aggregate data"} />
      <Paragraph>
        We may also use your personal information to generate aggregated data
        for internal use and for sharing with others on a selective basis.
        “Aggregated data” means records which have been stripped of personally
        identifable information, and which have been manipulated or combined to
        provide generalized, anonymous information. Your identity and personal
        information will be kept anonymous.
      </Paragraph>
      <Header level={3} size={"small"} message={"External links"} />
      <Paragraph>
        Our site and services may contain links to other sites and we are not
        responsible for the privacy practices or the content of those sites. We
        encourage you to read the privacy policy of linked sites.
      </Paragraph>
      <Header level={3} size={"small"} message={"Cookies"} />
      <Paragraph>
        We use cookies to track user information. Cookies are small amounts of
        data that are transferred to your web browser and are stored on your
        computer’s hard drive. We use cookies to track which page variant a
        visitor has seen, to track if a visitor has clicked on a page variant,
        to monitor traffic patterns and to gauge popularity of our features on
        site and services. We will use this information to deliver relevant
        content and services to you.
      </Paragraph>
      <Header level={3} size={"small"} message={"Security"} />
      <Paragraph>
        We strive to prevent unauthorized access to your personal information,
        however, no data transmission is guaranteed to be 100% secure. We
        maintain reasonable security procedures and practices to protect that
        information from unauthorized disclosure. We will continue to enhance
        security procedures as new technologies and procedures become available.
      </Paragraph>
      <Paragraph>
        Ultimately, you control what personal information you provide while
        using our site and services. You are responsible for maintaining the
        secrecy of your identification, passwords and/or any personal
        information in your possession. We are not responsible for the use by
        others of any information which you provide to them and you should use
        caution in selecting the personal information you provide to others.
        Similarly, we cannot assume any responsibility for the content of any
        personal information or other information which you receive from other
        users through our site or services, and you release us from any and all
        liability in connection with the contents of any personal information or
        other information which you may receive using the site or services.
      </Paragraph>
    </Fragment>
  );
};
export default Privacy;
