// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "input.Admin-module__inviteLink___3QW82{background-color:whitesmoke;color:#f14668;font-size:0.875em;font-weight:normal;padding:0.25em 0.5em 0.25em;border:none;box-shadow:none;min-width:100px}\n", "",{"version":3,"sources":["/tmp/build_951a6463/app/javascript/components/admin/Admin.module.scss"],"names":[],"mappings":"AAEA,uCACE,2BAA4B,CAC5B,aAAc,CACd,iBAAkB,CAClB,kBAAmB,CACnB,2BAA4B,CAC5B,WAAY,CACZ,eAAgB,CAChB,eAAgB","file":"Admin.module.scss","sourcesContent":["@import \"stylesheets/design-tokens.scss\";\n\ninput.inviteLink {\n  background-color: whitesmoke;\n  color: #f14668;\n  font-size: 0.875em;\n  font-weight: normal;\n  padding: 0.25em 0.5em 0.25em;\n  border: none;\n  box-shadow: none;\n  min-width: 100px;\n}\n"]}]);
// Exports
exports.locals = {
	"inviteLink": "Admin-module__inviteLink___3QW82"
};
module.exports = exports;
