import React, { memo } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import stylesheet from "./Tagging.module.scss";

const TagSuggestion = memo((props) => {
  const { item, query } = props;

  const highlightPattern = (text, pattern) => {
    const splitText = text.split(pattern);

    if (splitText.length <= 1) {
      return text;
    }

    const matches = text.match(pattern);

    return splitText.reduce(
      (arr, element, index) =>
        matches[index]
          ? [
              ...arr,
              element,
              <mark key={index + "-" + query + "-" + matches[index]}>
                {matches[index]}
              </mark>,
            ]
          : [...arr, element],
      []
    );
  };

  return (
    <div id={item.id} className={stylesheet.tagSuggestion}>
      <div className={stylesheet.tagName}>
        {highlightPattern(item.name.toLowerCase(), query.toLowerCase())}
      </div>
      <div className={`${stylesheet.tagCount} tag-count`}>
        <span className={`icon is-small ${stylesheet.tagIcon}`}>
          <FontAwesomeIcon icon={["fa", "tags"]} />
        </span>
        <span className={stylesheet.tagNumber}>{item.taggings_count}</span>
      </div>
    </div>
  );
});
TagSuggestion.propTypes = {
  item: PropTypes.object,
  query: PropTypes.string,
};
export default TagSuggestion;
