import React, { Component } from "react";
import { debounce } from "lodash";
import { findUsernameAvailability, submitUserData } from "./UserAPI";
import { validate, isValid, mapUserModelErrors } from "./UserValidator";
import { Link } from "react-router-dom";
import stylesheet from "../pages/Pages.module.scss";
import FocusTrap from "focus-trap-react";
import Section from "../common/layout/Section";
import TextInput from "../common/TextInput";
import Button from "../common/buttons/Button";
import Header from "../common/typography/Header";
import DatePicker from "./settings/DatePicker";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      new_password: "",
      year: "",
      month: "",
      day: "",
      birthdate_error: "",
      errors: "",
      username_error: "",
      usernameSuccess: "",
      email_error: "",
      new_password_error: "",
      showSpinner: false,
      showErrorWell: false,
    };
  }

  componentDidMount() {
    document.title = "Join / Animatik";
  }

  validateUsername = debounce(() => {
    const { username } = this.state;
    findUsernameAvailability(username).then((response) => {
      if (response.data.available === false) {
        this.setState({
          username_error: "Username is already taken",
        });
      } else {
        this.setState({
          usernameSuccess: "Username is available. It's all yours!",
        });
      }
    });
  }, 500);

  debouncedValidation = debounce((input) => {
    var validatedInput = validate(input);
    this.setState(validatedInput);

    if (Object.keys(input)[0] === "username") {
      if (isValid(validatedInput)) {
        this.setState({ username_error: "", usernameSuccess: "" });
        this.validateUsername();
      } else {
        this.setState({ usernameSuccess: "" });
      }
    }
  }, 100);

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value, [name + "_error"]: "" });
  };

  validate = (event) => {
    const { name, value } = event.target;
    this.debouncedValidation({ [name]: value });
  };

  setDateProperty = (field, value) => {
    this.setState({ [field]: value, birthdate_error: "" });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      username,
      email,
      new_password,
      year,
      month,
      day,
      showErrorWell,
    } = this.state;

    /* Check if the error well indicating the user wasn't invited is present, if so, prevent additional API calls. */
    if (showErrorWell) {
      return;
    }

    /* Extract the unique invite code from the URL and include in API request */
    // const inviteCode = this.props.location.search.replace("?code=", "");

    /* First check that the user has entered a birth date. Return if any fields are empty. */
    if (year === "" || month === "" || day === "") {
      this.setState({
        birthdate_error: "Enter your date of birth.",
      });
      return;
    }

    const user = {
      username: username,
      email: email,
      password: new_password,
      birthdate: new Date(year, month, day, 0, 0, 0, 0),
    };
    const validatedInput = validate(user);
    if (!isValid(validatedInput)) {
      this.setState(validatedInput);
    } else {
      this.setState({ showSpinner: true });
      const userNested = {
        user: user,
        // invite_code: inviteCode,
      };
      submitUserData(userNested)
        .then((response) => {
          if (response.data.user) {
            this.props.handleLogin(response.data);

            setTimeout(
              function () {
                this.redirect();
              }.bind(this),
              400
            );
          } else if (!response.data.invited) {
            this.setState({
              showErrorWell: true,
              showSpinner: false,
            });
          } else {
            this.setState(mapUserModelErrors(response.data.errors));
            this.setState({ showSpinner: false });
          }
        })
        .catch((error) => console.log("api errors:", error));
    }
  };

  redirect = () => {
    this.props.history.push({
      pathname: `/`,
      state: { showWelcomeModal: true },
    });
  };

  render() {
    const {
      username,
      email,
      new_password,
      year,
      month,
      day,
      username_error,
      usernameSuccess,
      email_error,
      new_password_error,
      birthdate_error,
      showSpinner,
      showErrorWell,
    } = this.state;

    const inviteNotFoundMessage = (
      <article className="message is-warning">
        <div className="message-body">
          <Header
            level={2}
            size={"small"}
            message={"Your invite didn't work"}
          />
          But don't worry! If you need an invite link,{" "}
          <Link
            className="link"
            to={{
              pathname: `/`,
            }}
          >
            join the waitlist
          </Link>
          .<br />
          <br />
          Or, If your invite link isn't working, contact{" "}
          <strong>
            <a href="mailto:hello@animatik.co" target="_blank">
              hello@animatik.co
            </a>
          </strong>
          .
        </div>
      </article>
    );

    return (
      <Section showBackground={true}>
        <div className={stylesheet.smallContainer}>
          <div className={stylesheet.headline}>
            <Header
              level={1}
              size={"big"}
              message={"Join Animatik"}
              classes={stylesheet.signInHeader}
            />
            <div className="content">
              <p>
                Already a member?{" "}
                <Link
                  className="link"
                  to={{
                    pathname: `/signin`,
                  }}
                >
                  Sign in
                </Link>
                .
              </p>
            </div>
            {showErrorWell && inviteNotFoundMessage}
          </div>
          <form onSubmit={this.handleSubmit} autoComplete="new-password">
            <TextInput
              label="Username"
              placeholder="Username"
              name="username"
              value={username}
              onChange={this.handleChange}
              onBlur={this.validate}
              error={username_error}
              success={usernameSuccess}
              autocomplete={"new-password"}
            />
            <TextInput
              label="Email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={this.handleChange}
              onBlur={this.validate}
              error={email_error}
              autocomplete={"new-password"}
            />
            <DatePicker
              year={year}
              month={month}
              day={day}
              error={birthdate_error}
              setDateProperty={this.setDateProperty}
              helperCopy={`This will not be shown publicly.`}
            />
            <TextInput
              label="Password"
              placeholder="Password"
              name="new_password"
              type="password"
              value={new_password}
              onChange={this.handleChange}
              onBlur={this.validate}
              error={new_password_error}
              autocomplete={"new-password"}
            />
            <p className="content help">
              By joining, you agree to our{" "}
              <Link className="link" to={`/about/terms`}>
                terms
              </Link>{" "}
              and{" "}
              <Link className="link" to={`/about/privacy`}>
                policies
              </Link>
              .
            </p>
            <div className="field is-grouped">
              <div className="control">
                <Button
                  kind="primary"
                  label="Join"
                  placeholder="submit"
                  type="submit"
                  showSpinner={showSpinner}
                />
              </div>
            </div>
          </form>
        </div>
      </Section>
    );
  }
}
export default Signup;
