import React, { memo } from "react";
import PropTypes from "prop-types";
import Tag from "./Tag";
import stylesheet from "./Tagging.module.scss";

const TagList = memo((props) => {
  const { tags, classes } = props;

  const allTags = tags.map((tag, index) => (
    <Tag key={tag + "-" + index} tag={tag} />
  ));

  return (
    <div className={`${stylesheet.tagList} ${classes ? classes : null}`}>
      {allTags}
    </div>
  );
});
TagList.propTypes = {
  tags: PropTypes.array,
  classes: PropTypes.string,
};
export default TagList;
