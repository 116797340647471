import React from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import stylesheet from "./Uploading.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Slider from "../controls/Slider";
import ModalBody from "../modal/ModalBody";
import ButtonsBar from "../modal/ButtonsBar";
import Button from "../buttons/Button";

class ImageCropper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1 / 1,
      imageName: "",
      croppedAreaPixels: null,
      newImage: null,
      isLoading: false,
      initialCrop: false,
    };
  }

  componentDidMount() {
    if (this.state.initialCrop === false) {
      this.setState({
        initialCrop: true,
      });
    }

    if (this.props.selectedImage !== null) {
      this.setState({
        image: this.props.selectedImage.preview,
        imageName: this.props.selectedImage.path,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedImage !== prevProps.selectedImage) {
      if (this.props.selectedImage !== null) {
        this.setState({
          image: this.props.selectedImage.preview,
          imageName: this.props.selectedImage.path,
        });
      }
    }
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = async (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels: croppedAreaPixels,
    });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  getCroppedImage = async () => {
    this.setState({
      isLoading: true,
    });
    try {
      const croppedImage = await getCroppedImg(
        this.state.image,
        this.state.croppedAreaPixels
      );
      var newFile = new File([croppedImage], this.state.imageName, {
        lastModified: 1534584790000,
      });
      Object.assign(newFile, { preview: URL.createObjectURL(newFile) });
      this.props.setCroppedThumbnail(newFile);

      if (this.props.form) {
        this.props.handleSubmit();
      } else {
        this.props.toggleCropperModal();
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const {
      cropperModalTitle,
      buttonLabel,
      showBackButton,
      cropShape,
    } = this.props;

    return (
      <div>
        <ModalBody
          modalTitle={cropperModalTitle ? cropperModalTitle : "Crop image"}
        >
          <div className={stylesheet.cropWindow}>
            <Cropper
              image={this.state.image}
              crop={this.state.crop}
              zoom={this.state.zoom}
              maxZoom={5}
              aspect={
                this.props.aspectRatio
                  ? this.props.aspectRatio
                  : this.state.aspect
              }
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}
              cropShape={cropShape ? cropShape : "rect"}
            />
          </div>
          <div className={stylesheet.zoomControls}>
            <div className={stylesheet.zoomOut}>
              <span className="icon">
                <FontAwesomeIcon icon={["fa", "search-minus"]} />
              </span>
            </div>
            <Slider
              value={this.state.zoom}
              min={1}
              max={5}
              step={0.01}
              onChange={this.onZoomChange}
            />
            <div className={stylesheet.zoomIn}>
              <span className="icon">
                <FontAwesomeIcon icon={["fa", "search-plus"]} />
              </span>
            </div>
          </div>
        </ModalBody>
        <ButtonsBar>
          {showBackButton && (
            <Button
              label={"Back"}
              kind={"ternary"}
              type={"button"}
              onClick={this.props.previousStep}
            />
          )}
          <Button
            label={buttonLabel ? buttonLabel : "Upload"}
            kind={"primary"}
            type="button"
            showSpinner={this.state.isLoading}
            onClick={this.getCroppedImage}
          />
        </ButtonsBar>
      </div>
    );
  }
}
export default ImageCropper;
