import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

import { globalContext } from "../util/globalContext";
import { fetchLoginStatus } from "../util/SessionsAPI.js";
import { fetchOnboardingStatus } from "./users/UserAPI";
import { useGoogleAnalytics } from "./hooks/useGoogleAnalytics.js";

import Navbar from "./navigation/Navbar";
import Footer from "./navigation/Footer";
import Explore from "./pages/Explore";
import Critiques from "./critiques/Critiques";
import DesignSystem from "./pages/DesignSystem";
import ModalSample from "./pages/ModalSample";
import PageNotFound from "./pages/PageNotFound";
import About from "./pages/Policies/About";
import WritingFeedback from "./pages/Guides/WritingFeedback";
import Loading from "./pages/Loading";
import Login from "./users/Login";
import Register from "./users/Register";
import EditProfile from "./users/settings/EditProfile";
import EditAccount from "./users/settings/EditAccount";
import DisplayImageWizard from "./users/settings/DisplayImageWizard";
import ProfileCoverWizard from "./users/settings/ProfileCoverWizard";
import Profile from "./users/profile/Profile";
import AllUsers from "./users/AllUsers";
import ArtworkWizard from "./artwork/ArtworkWizard";
import ArtworkEditor from "./artwork/Wizard/ArtworkEditor";
import ViewArtwork from "./artwork/View/ViewArtwork";
import ViewCollection from "./collections/ViewCollection";
import NewCollection from "./collections/NewCollection";
import NewPost from "./posts/NewPost";
import ViewPost from "./posts/ViewPost";
import Posts from "./posts/Posts";
import Search from "./search/Search";
import Feed from "./feed/Feed";
import NotificationDashboard from "./notifications/NotificationDashboard";
import Invites from "./admin/Invites";
import ToTopButton from "./common/buttons/ToTopButton";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCog,
  faAngleDown,
  faExclamationCircle,
  faCheckCircle,
  faTimesCircle,
  faTimes,
  faEdit,
  faTrashAlt,
  faPaperPlane,
  faInfoCircle,
  faTools,
  faCrop,
  faFolderOpen,
  faShare,
  faEllipsisV,
  faArrowRight,
  faArrowLeft,
  faExpandAlt,
  faHeart,
  faDownload,
  faChartBar,
  faCompressAlt,
  faChevronRight,
  faChevronLeft,
  faSearch,
  faChartLine,
  faFire,
  faBell,
  faEnvelope,
  faBolt,
  faPlus,
  faPencilAlt,
  faHome,
  faUsers,
  faProjectDiagram,
  faCity,
  faChevronDown,
  faLock,
  faDog,
  faSignOutAlt,
  faSeedling,
  faCheck,
  faMinus,
  faCompass,
  faBars,
  faSignInAlt,
  faClock,
  faStar,
  faEye,
  faAngleRight,
  faTags,
  faCalendar,
  faLink,
  faUserFriends,
  faEyeSlash,
  faToolbox,
  faArrowUp,
  faSearchPlus,
  faSearchMinus,
  faComment,
  faImages,
  faFont,
  faCommentDots,
  faRocket,
  faTasks,
  faAward,
  faLeaf,
} from "@fortawesome/free-solid-svg-icons";
import {
  faDiscord,
  faInstagram,
  faTumblrSquare,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faCog,
  faAngleDown,
  faExclamationCircle,
  faCheckCircle,
  faTimesCircle,
  faTimes,
  faEdit,
  faTrashAlt,
  faPaperPlane,
  faInfoCircle,
  faTools,
  faCrop,
  faHeart,
  faFolderOpen,
  faShare,
  faEllipsisV,
  faArrowRight,
  faArrowLeft,
  faExpandAlt,
  faDownload,
  faChartBar,
  faCompressAlt,
  faChevronLeft,
  faChevronRight,
  faSearch,
  faChartLine,
  faFire,
  faBell,
  faEnvelope,
  faBolt,
  faPlus,
  faPencilAlt,
  faHome,
  faUsers,
  faProjectDiagram,
  faCity,
  faChevronDown,
  faLock,
  faDog,
  faSignOutAlt,
  faSeedling,
  faCheck,
  faMinus,
  faCompass,
  faBars,
  faPlus,
  faSignInAlt,
  faClock,
  faStar,
  faEye,
  faAngleRight,
  faDiscord,
  faTwitter,
  faInstagram,
  faTumblrSquare,
  faTags,
  faCalendar,
  faLink,
  faUserFriends,
  faEyeSlash,
  faToolbox,
  faArrowUp,
  faSearchPlus,
  faSearchMinus,
  faComment,
  faImages,
  faFont,
  faCommentDots,
  faRocket,
  faTasks,
  faAward,
  faLeaf
);

function ModalSwitches(props) {
  let location = useLocation();
  let background = location.state && location.state.background;

  const { children, currentUser, showToast, refreshOnboarding } = props;

  return (
    <div>
      <Switch location={background || location}>{children}</Switch>

      {background && <Route path="/users" children={<AllUsers />} />}
      {background && <Route path="/modal-sample" children={<ModalSample />} />}
      {background && (
        <Route
          exact
          path="/edit-display-image"
          children={<DisplayImageWizard />}
        />
      )}
      {background && (
        <Route
          exact
          path="/edit-profile-cover"
          children={<ProfileCoverWizard />}
        />
      )}
      {background && (
        <Route
          path="/new/art"
          children={<ArtworkWizard currentUser={currentUser} />}
        />
      )}
      {background && (
        <Route path="/art/edit/:id" children={<ArtworkEditor />} />
      )}
      {background && (
        <Route path="/new/collection" children={<NewCollection />} />
      )}
      {background && (
        <Route path="/collections/edit/:id" children={<NewCollection />} />
      )}
      {background && (
        <Route
          path="/new/post"
          children={
            <NewPost
              currentUser={currentUser}
              showToast={showToast}
              refreshOnboarding={refreshOnboarding}
            />
          }
        />
      )}
      {background && (
        <Route
          path="/posts/edit/:id"
          children={<NewPost currentUser={currentUser} showToast={showToast} />}
        />
      )}
    </div>
  );
}

function Routes(props) {
  const {
    AdminRoute,
    LoggedInRoute,
    PrivateRoute,
    isLoggedIn,
    isLoading,
    handleLogin,
    handleSettingsChange,
    handleLogout,
    showToast,
    refreshOnboarding,
    user,
  } = props;

  useGoogleAnalytics(user, isLoading);

  return (
    <div className="header-offset">
      <ModalSwitches
        currentUser={user}
        showToast={showToast}
        refreshOnboarding={refreshOnboarding}
      >
        <Route
          exact
          path="/signin"
          render={(props) => <Login {...props} handleLogin={handleLogin} />}
        />
        {/* {isLoading ? <Loading /> : !isLoggedIn && <Redirect to="/signin" />} */}
        <Route exact path="/" children={<Explore currentUser={user} />} />
        <Route
          exact
          path="/critiques"
          render={(props) => <Critiques {...props} />}
        />
        <Route
          exact
          path="/posts"
          render={(props) => (
            <Posts currentUser={user} showToast={showToast} {...props} />
          )}
        />
        <Route
          exact
          path="/join"
          render={(props) => <Register {...props} handleLogin={handleLogin} />}
        />
        <Route
          path={"/about"}
          render={({ match: { path } }) => (
            <About isLoggedIn={isLoggedIn} path={path} />
          )}
        />
        <Route
          exact
          path="/art/:id"
          render={(props) => <ViewArtwork {...props} />}
        />
        <Route
          exact
          path="/posts/:id"
          render={(props) => (
            <ViewPost currentUser={user} showToast={showToast} {...props} />
          )}
        />
        <Route
          exact
          path="/collections/:id"
          render={(props) => <ViewCollection {...props} />}
        />
        <Route exact path="/search" render={(props) => <Search {...props} />} />
        <Route exact path="/logout" children={<Loading />} />
        <Route
          exact
          path="/design"
          render={(props) => <DesignSystem {...props} />}
        />
        {/* <Route
          exact
          path="/guides/writing-feedback"
          render={(props) => <WritingFeedback {...props} />}
        /> */}
        <LoggedInRoute exact path="/following" component={Feed} />
        <LoggedInRoute
          exact
          path="/notifications"
          component={NotificationDashboard}
        />
        <PrivateRoute
          exact
          path="/settings/profile"
          component={EditProfile}
          handleSettingsChange={handleSettingsChange}
          handleLogout={handleLogout}
        />
        <PrivateRoute
          exact
          path="/settings/account"
          component={EditAccount}
          handleSettingsChange={handleSettingsChange}
          handleLogout={handleLogout}
        />
        <AdminRoute exact path="/admin/invites" component={Invites} />
        <Route
          path="/not-found"
          render={(props) => <PageNotFound {...props} />}
        />
        <Route
          path={"/:username"}
          render={({ match: { path } }) => (
            <Profile currentUser={user} showToast={showToast} path={path} />
          )}
        />
      </ModalSwitches>
    </div>
  );
}

function App() {
  const [user, setUser] = useState({});
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [onboarding, setOnboarding] = useState({});

  useEffect(() => {
    loginStatus();
  }, []);

  const loginStatus = () => {
    fetchLoginStatus()
      .then((response) => {
        if (response.data.meta.logged_in) {
          handleLogin(response.data);
        } else {
          handleLogout();
        }
      })
      .catch((error) => {
        handleLogout();
      });
  };

  const refreshOnboarding = () => {
    fetchOnboardingStatus()
      .then((response) => {
        if (response.data) {
          setOnboarding(response.data.onboarding);
        }
      })
      .catch((error) => {
        handleLogout();
      });
  };

  const handleLogin = (data) => {
    setUser(data.user);
    setOnboarding(data.meta.onboarding);
    setLoggedIn(true);
    setLoading(false);
  };

  const handleLogout = () => {
    setLoading(false);
    setLoggedIn(false);
    setOnboarding({});
    setUser({});
  };

  const handleSettingsChange = (updatedUser) => {
    setUser(updatedUser);
  };

  const showToast = (message) => {
    toast(message);
  };

  /* Checks if the current user is logged in */
  const LoggedInRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isLoading ? (
          <Loading />
        ) : isLoggedIn === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );

  /* Similar to LoggedInRoute check, but additionally checks if the logged in user is an admin */
  const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isLoading ? (
          <Loading />
        ) : isLoggedIn === true && user.is_admin ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );

  const PrivateRoute = ({
    component: Component,
    handleSettingsChange,
    handleLogout,
    ...rest
  }) => (
    <Route
      {...rest}
      render={(props) =>
        isLoading ? (
          <Loading />
        ) : isLoggedIn === true ? (
          <Component
            {...props}
            handleSettingsChange={handleSettingsChange}
            handleLogout={handleLogout}
          />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );

  const globalContextProps = {
    isLoggedIn: isLoggedIn,
    isLoading: isLoading,
    currentUser: user,
    onboarding: onboarding,
    showToast: showToast,
    refreshOnboarding: refreshOnboarding,
  };

  return (
    <div>
      <globalContext.Provider value={globalContextProps}>
        <BrowserRouter>
          <Navbar handleLogout={handleLogout} />
          <Routes
            AdminRoute={AdminRoute}
            LoggedInRoute={LoggedInRoute}
            PrivateRoute={PrivateRoute}
            isLoggedIn={isLoggedIn}
            isLoading={isLoading}
            handleLogin={handleLogin}
            handleSettingsChange={handleSettingsChange}
            handleLogout={handleLogout}
            showToast={showToast}
            refreshOnboarding={refreshOnboarding}
            user={user}
          />
          <ToTopButton />
          <Footer />
          <ToastContainer
            position="bottom-center"
            autoClose={6000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </BrowserRouter>
      </globalContext.Provider>
    </div>
  );
}
export default App;
