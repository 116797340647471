import React, { lazy } from "react";
import { Suspense } from "react";
import Loading from "../pages/Loading";
const Comments = lazy(() => import("./Comments"));

function CommentContainer(props) {
  const {
    parentId,
    comments,
    newCommentHandler,
    loadCommentHandler,
    newCommentHelperCopy,
  } = props;
  return (
    <div>
      <Suspense fallback={<Loading />}>
        <Comments
          parentId={parentId}
          comments={comments}
          newCommentHandler={newCommentHandler}
          loadCommentHandler={loadCommentHandler}
          newCommentHelperCopy={newCommentHelperCopy}
        />
      </Suspense>
    </div>
  );
}
export default CommentContainer;
