import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function IconButton(props) {
  const { onClick, icon, iconRight, kind, ariaLabel, value, id } = props;

  let styles = "button is-icon-button is-rounded";
  let iconButtonStyles = kind ? styles + " is-" + kind : styles;

  return (
    <span>
      <button
        id={id}
        className={iconButtonStyles}
        aria-label={ariaLabel}
        onClick={onClick}
        type="button"
      >
        <span className={iconRight ? `icon no-margin-right` : `icon`}>
          <FontAwesomeIcon icon={icon} />
        </span>
        {iconRight && (
          <span className="icon no-margins">
            <FontAwesomeIcon icon={iconRight} />
          </span>
        )}
        {value && <span className="icon-button-value">{value}</span>}
      </button>
    </span>
  );
}
