import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import stylesheet from "./Critiques.module.scss";

const CritiqueIcon = () => {
  return (
    <div className={stylesheet.critiqueIcon}>
      <span className={`icon is-small ${stylesheet.icon}`}>
        <FontAwesomeIcon icon={["fa", "comment-dots"]} />
      </span>
    </div>
  );
};

export default CritiqueIcon;
