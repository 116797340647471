import React, { Component } from "react";
import FocusTrap from "focus-trap-react";
import Header from "../common/typography/Header";
import TextInput from "../common/TextInput";
import Checkbox from "../common/Checkbox";
import Section from "../common/layout/Section";
import Button from "../common/buttons/Button";
import { loginUser } from "./UserAPI";
import stylesheet from "../pages/Pages.module.scss";
import { Link } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      remember_me: true,
      errors: "",
      usernameError: "",
      showSpinner: false,
    };
  }

  componentDidMount() {
    document.title = "Sign in / Animatik";
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({ usernameError: "" });
  };

  toggleRememberMe = () => {
    this.setState({
      remember_me: !this.state.remember_me,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ showSpinner: true });
    const { username, password, remember_me } = this.state;
    const user = {
      username: username,
      password: password,
      remember_me: remember_me,
    };

    loginUser(user)
      .then((response) => {
        if (response.data.meta && response.data.meta.logged_in) {
          this.props.handleLogin(response.data);
          setTimeout(
            function () {
              this.redirect();
            }.bind(this),
            400
          );
        } else {
          this.setState({
            usernameError: response.data.errors[0],
          });
          this.setState({ showSpinner: false });
        }
      })
      .catch((error) => console.log("api errors:", error));
  };

  redirect = () => {
    this.props.history.push("/");
  };

  render() {
    const {
      username,
      password,
      remember_me,
      usernameError,
      showSpinner,
    } = this.state;

    return (
      <Section showBackground={true}>
        <div className={stylesheet.smallContainer}>
          <div className={stylesheet.headline}>
            <Header
              level={1}
              size={"big"}
              message={"Sign in to Animatik"}
              classes={stylesheet.signInHeader}
            />
            <div className="content">
              <p>
                Not a member yet?{" "}
                <Link
                  className="link"
                  to={{
                    pathname: `/join`,
                  }}
                >
                  Join
                </Link>{" "}
                today.
              </p>
            </div>
          </div>

          <form onSubmit={this.handleSubmit}>
            <TextInput
              label="Username"
              placeholder="Username"
              name="username"
              autocomplete="username"
              value={username}
              onChange={this.handleChange}
              error={usernameError}
            />
            <TextInput
              label="Password"
              placeholder="Password"
              name="password"
              autocomplete="email"
              type="password"
              value={password}
              onChange={this.handleChange}
            />
            {/* <Checkbox
                label="Stay signed in"
                name="remember_me"
                checked={remember_me}
                onChange={this.toggleRememberMe}
              /> */}
            <div className="field is-grouped">
              <div className="control">
                <Button
                  kind="primary"
                  label="Sign in"
                  placeholder="submit"
                  type="submit"
                  showSpinner={showSpinner}
                />
              </div>
            </div>
          </form>
        </div>
      </Section>
    );
  }
}
export default Login;
