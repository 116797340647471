import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import stylesheet from "./Profile.module.scss";
import Header from "../../common/typography/Header";
import Paragraph from "../../common/typography/Paragraph";
import DisplayImage from "../../common/users/DisplayImage";
import AboutItem from "../../common/users/AboutItem";
import Divider from "../../common/layout/Divider";
import OnboardBadge from "../../common/badges/OnboardBadge";

const About = (props) => {
  const { user } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "About " + user.display_name + " / Animatik";
  }, []);

  return (
    <div className={stylesheet.aboutContainer}>
      <div className={stylesheet.accountDetails}>
        <div className={stylesheet.displayImage}>
          <DisplayImage user={user} size={200} link={false} />
        </div>
        <div className={stylesheet.about}>
          <Header
            level={2}
            size={"medium"}
            message={"About " + user.display_name}
          />
          {user.bio && <Paragraph message={user.bio} />}
          {user.pronouns && (
            <AboutItem message={user.pronouns} icon={"seedling"} />
          )}
          {user.website && (
            <AboutItem
              message={user.website.replace(/^https?\:\/\/(www.)?/, "")}
              linkTo={user.website}
              icon={"link"}
            />
          )}
          <AboutItem message={"Joined " + user.created_at} icon={"calendar"} />
        </div>
      </div>

      {user.onboarded_at && (
        <Fragment>
          <Divider kind={"soft"} />
          <Header
            level={2}
            size={"medium"}
            message={"Badges"}
            classes={stylesheet.badgesHeader}
          />
          <OnboardBadge />
        </Fragment>
      )}
    </div>
  );
};

About.propTypes = {
  user: PropTypes.PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
export default About;
