import axios from "axios";
const api = axios.create({
  withCredentials: true,
});
const API_URL = "/api/v1";

// Fetch all artwork
export function fetchAllArtwork(page) {
  return api.get(`${API_URL}/artworks/`, page, {
    withCredentials: true,
  });
}

// Fetch artwork
export function fetchArtwork(id, optionalParams) {
  return api.get(`${API_URL}/artworks/${id}`, optionalParams, {
    withCredentials: true,
  });
}

// Create new artwork
export function submitArtwork(artwork) {
  return api.post(`${API_URL}/artworks`, artwork, {
    withCredentials: true,
  });
}

// Update artwork
export function updateArtwork(id, body) {
  return api.patch(`${API_URL}/artworks/${id}`, body, {
    withCredentials: true,
  });
}

// Delete artwork
export function deleteArtwork(id) {
  return api.delete(`${API_URL}/artworks/${id}`, {
    withCredentials: true,
  });
}

// Like artwork
export function likeArtwork(id) {
  return api.post(`${API_URL}/artworks/${id}/like`, {
    withCredentials: true,
  });
}

// View likers of an artwork
export function fetchLikedByList(id, page) {
  return api.get(`${API_URL}/artworks/${id}/liked_by_users/`, page, {
    withCredentials: true,
  });
}

// Fetch critiques
export function fetchCritiques(page) {
  return api.get(`${API_URL}/artworks/critiques`, page, {
    withCredentials: true,
  });
}
