// Field name constants
const TITLE_FIELD_NAME = "title";
const BODY_FIELD_NAME = "body";

// Validation requirements
const TITLE_MAX = 70;
const BODY_MIN = 1;
const BODY_MAX = 1000;

function _validateTitle(title) {
  return title.length > TITLE_MAX
    ? "Enter a title less than 70 characters."
    : "";
}

function _validateBody(body) {
  return body.length < BODY_MIN || body.length > BODY_MAX
    ? "Enter a body between 1 and 1,000 characters."
    : "";
}

export function isValid(errors) {
  return Object.values(errors).every((error) => error === "");
}

export function validate(userInput) {
  var errors = {};
  for (const field in userInput) {
    // If the input is undefined it means the value is unchanged when updating from Settings.
    if (userInput[field] === undefined) {
      continue;
    }
    if (field === TITLE_FIELD_NAME) {
      errors.title_error = _validateTitle(userInput[field]);
    }
    if (field === BODY_FIELD_NAME) {
      errors.body_error = _validateBody(userInput[field]);
    }
  }
  return errors;
}
