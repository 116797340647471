import React from "react";

export default function Loading(props) {
  const { showCopy, message } = props;

  return (
    <div>
      <section className="section">
        <div className="container has-text-centered">
          <div className="loader-wrapper is-active">
            <div className="loader is-loading"></div>
          </div>
          {showCopy === false ? "" : <p>{message ? message : "Loading..."}</p>}
        </div>
      </section>
    </div>
  );
}
