import React, { useState, useEffect, Fragment, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";

import { globalContext } from "../../../util/globalContext.js";
import { fetchArtwork, likeArtwork } from "../ArtworkAPI";
import { submitArtworkComment, loadComments } from "../../comments/CommentsAPI";
import styles from "../Artwork.module.scss";

import Loading from "../../pages/Loading";
import Gallery from "../../common/gallery/Gallery";
import Heading from "../../common/typography/Heading";
import Paragraph from "../../common/typography/Paragraph";
import Section from "../../common/layout/Section";
import ButtonsBar from "../../common/layout/ButtonsBar";
import ActionButton from "../../common/buttons/ActionButton";
import Button from "../../common/buttons/Button";
import UserTag from "../../common/users/UserTag";
import ColumnLayout from "../../common/layout/ColumnLayout";
import Column from "../../common/layout/Column";
import Grouping from "../../common/layout/Grouping";
import Divider from "../../common/layout/Divider";
import Frame from "./Frame";
import Share from "./Share";
import CollectButton from "../../common/artwork/CollectButton";
import LikesList from "./LikesList";
import EditButton from "../../common/gallery/EditButton";
import CommentContainer from "../../comments/CommentContainer";
import TagList from "../../common/tagging/TagList";
import CritiqueFlag from "../../critiques/CritiqueFlag";

function ViewArtwork(props) {
  const globalState = useContext(globalContext);
  const [isLoading, setIsLoading] = useState(true);

  const [artwork, setArtwork] = useState([]);
  const [likes, setLikes] = useState(null);
  const [currentUserHasLiked, setHasLiked] = useState(false);

  const [user, setUser] = useState([]);
  const [nextId, setNextId] = useState(null);
  const [prevId, setPrevId] = useState(null);
  const [moreFromUser, setMoreFromUser] = useState([]);
  const [relatedArtwork, setRelatedArtwork] = useState([]);

  // Liked List constants
  const [showLikeList, toggleLikeList] = useState(false);

  // Share options modal
  const [showShareOptions, toggleShareOptions] = useState(false);

  // Comments
  const [comments, setComments] = useState([]);

  // Get current user
  const currentUser = globalState.currentUser;
  const history = useHistory();

  // Get location state details
  const location = useLocation();
  const canShowBackButton = location.state && location.state.collectionId;
  const collectionId = location.state ? location.state.collectionId : null;

  // Set the document title
  var title = artwork.title ? artwork.title : "Untitled";
  document.title = title + " by " + user.display_name + " / Animatik";

  useEffect(() => {
    let query = null;
    if (collectionId) {
      query = {
        params: {
          collection_id: collectionId,
        },
      };
    }

    fetchArtwork(props.match.params.id, query)
      .then((response) => {
        if (response.data) {
          window.scrollTo(0, 0);
          setArtwork(response.data.artwork);

          // Load related artwork properties, e.g., user and likes
          setUser(response.data.artwork.user);
          setLikes(response.data.artwork.likes);
          setHasLiked(response.data.meta.likedByCurrentUser);

          // Load comments
          setComments(response.data.meta.comments);

          // Load additional artwork to browse
          setMoreFromUser(response.data.meta.fromUser);
          setRelatedArtwork(response.data.meta.related);

          // Handle next and previous behaviour
          if (response.data.meta.next) {
            setNextId(response.data.meta.next.id);
          } else {
            setNextId(null);
          }
          if (response.data.meta.previous) {
            setPrevId(response.data.meta.previous.id);
          } else {
            setPrevId(null);
          }

          setIsLoading(false);
        } else {
          console.log("ERROR");
        }
      })
      .catch((error) => {
        console.log("api errors:", error);
        props.history.push("/not-found");
      });
  }, [props.match.params.id]);

  const scrollToComments = (ref) => {
    if (ref && location.hash.includes("#comments")) {
      setTimeout(function () {
        ref.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 750);
    }
  };

  const goBack = () => {
    history.push({
      pathname: `/collections/${collectionId}`,
    });
  };

  const nextArtwork = () => {
    toggleLikeList(false);
    toggleShareOptions(false);

    if (collectionId) {
      history.push({
        pathname: `/art/${nextId}`,
        state: { collectionId: collectionId },
      });
    } else {
      history.push({
        pathname: `/art/${nextId}`,
      });
    }
  };

  const prevArtwork = () => {
    toggleLikeList(false);
    toggleShareOptions(false);

    if (collectionId) {
      history.push({
        pathname: `/art/${prevId}`,
        state: { collectionId: collectionId },
      });
    } else {
      history.push({
        pathname: `/art/${prevId}`,
      });
    }
  };

  // Show the modal which will trigger the API call in the LikeList component.
  const toggleLikeListModal = () => {
    toggleLikeList((state) => !state);
  };

  const toggleShareOptionsModal = () => {
    toggleShareOptions((state) => !state);
  };

  // Action handler for the like button
  const like = (id) => {
    if (!currentUser.id) {
      // Redirect guests to login
      history.push(`/signin`);
      return;
    } else if (currentUser.id === artwork.user_id) {
      // Artwork owner cannot like their own work
      toggleLikeListModal();
      return;
    }

    likeArtwork(id)
      .then((response) => {
        if (response.data) {
          setLikes(response.data.newLikeCount);
          setHasLiked(response.data.likedByCurrentUser);
        }
      })
      .catch((error) => {
        console.log("Errors");
      });
  };

  /* Artwork comment handler. Passed to the comments component to handle submitting new comments attached to this art. */
  const newCommentHandler = (parentId, body) => {
    return submitArtworkComment(parentId, body);
  };

  /* Load comment handler. Passed to handle loading more comments attached to this specific artwork. */
  const loadCommentHandler = (parentId, query) => {
    return loadComments(parentId, query);
  };

  // Page layout

  let year = new Date().getFullYear();
  let artwork_date = new Date(artwork.created_at);
  let threeDaysAgo = new Date();
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

  const descriptiveTime =
    artwork.created_at_descriptive === "Just now"
      ? artwork.created_at_descriptive.toLowerCase()
      : artwork.created_at_descriptive + " ago";

  const copyright = (
    <Fragment>
      <span className={styles.copyright}>
        &copy; {year} <Link to={`/${user.username}`}>{user.display_name}</Link>
      </span>
      <span className={styles.published}>
        Published{" "}
        {artwork_date < threeDaysAgo ? artwork.created_at : descriptiveTime}
      </span>
    </Fragment>
  );

  const untitled = <span className={styles.untitled}>—</span>;
  const engagementActions = (
    <Fragment>
      {/* <Button
        type="button"
        kind="tertiary"
        label={"Share"}
        icon={"paper-plane"}
        onClick={() => toggleShareOptionsModal()}
      /> */}
      <CollectButton
        artwork={artwork}
        currentUser={currentUser}
        showToast={globalState.showToast}
      />
      <ActionButton
        label={likes === 0 ? null : likes}
        kind={currentUserHasLiked ? "liked" : likes === 0 ? "fresh" : "like"}
        type="button"
        onClick={() => like(artwork.id)}
        icon={["fa", "heart"]}
        style={"outlined"}
      />
    </Fragment>
  );

  const ownerActions = (
    <Fragment>
      <EditButton id={artwork.id} kind={"outlined"} label={"Edit"} />
    </Fragment>
  );

  const controlNavbar = (
    <div className={styles.controlNavbar}>
      <ColumnLayout verticalAlignment={true}>
        <Column>
          <div className={styles.userTag}>
            <UserTag user={user} />
          </div>
        </Column>
        <Column>
          <div className={styles.buttonsBar}>
            <ButtonsBar alignment={"right"}>
              {engagementActions}
              {user.id === currentUser.id && ownerActions}
            </ButtonsBar>
          </div>
        </Column>
      </ColumnLayout>
    </div>
  );

  const viewArtwork = (
    <Fragment>
      <Section style={"slim"}>{controlNavbar}</Section>

      <Frame
        art={artwork.image}
        next={nextId ? nextArtwork : null}
        prev={prevId ? prevArtwork : null}
        back={canShowBackButton ? goBack : null}
      />

      <Section
        id={"art-details"}
        style={"slim"}
        styles={{ marginBottom: "3.5rem" }}
      >
        <Grouping smallSpacing={"top"}>
          <div className={styles.artTitle}>
            <Heading level={1} levelStyle={4}>
              {artwork.title ? artwork.title : untitled}
            </Heading>
          </div>

          <Paragraph message={artwork.caption} />
          <TagList tags={artwork.tags} />
          <Paragraph kind={"helper"} message={copyright} />

          <Divider kind={"soft"} />
          <ColumnLayout>
            <Column>
              <div className={styles.comments} ref={scrollToComments}>
                <Heading level={2} levelStyle={5}>
                  <span className={styles.commentsHeading}>Comments</span>
                  {artwork.critiques && <CritiqueFlag />}
                </Heading>
                <CommentContainer
                  parentId={artwork.id}
                  comments={comments}
                  newCommentHandler={newCommentHandler}
                  loadCommentHandler={loadCommentHandler}
                  newCommentHelperCopy={
                    artwork.critiques && currentUser.id !== user.id ? (
                      <Fragment>
                        {user.display_name} is open to critiques on this
                        artwork.
                        {/* {user.display_name} is open to critiques on this
                        artwork. See{" "}
                        <Link
                          to={`/guides/writing-feedback`}
                          target="_blank"
                          className="link"
                        >
                          how to provide feedback
                        </Link>{" "}
                        for tips on writing constructive comments. */}
                      </Fragment>
                    ) : (
                      ""
                    )
                  }
                />
              </div>
            </Column>
            <Column>
              {moreFromUser && (
                <Gallery
                  title={"More from " + user.display_name}
                  maximum={6}
                  works={moreFromUser}
                />
              )}
              <Grouping extraSpacing={"top"} classes={"get-sticky"}>
                {relatedArtwork && (
                  <Gallery
                    title={"More like this"}
                    maximum={9}
                    works={relatedArtwork}
                  />
                )}
              </Grouping>
            </Column>
          </ColumnLayout>
        </Grouping>
      </Section>

      <LikesList
        kind={"artwork"}
        showModal={showLikeList}
        toggleModal={toggleLikeListModal}
        likesCount={likes}
        objectId={artwork.id}
      />
      <Share
        showModal={showShareOptions}
        onRequestClose={toggleShareOptionsModal}
      />
    </Fragment>
  );

  return <Fragment>{isLoading ? <Loading /> : viewArtwork}</Fragment>;
}
export default ViewArtwork;
