import React, { Fragment, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import FeedPost from "./FeedPost";
import MoreButton from "../common/MoreButton";

const PaginatedFeed = (props) => {
  const [feedItems, setFeedItems] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const { items, fetchMethod, currentUser, showToast, classes } = props;

  /* When the items prop changes, map props to state so that pagination is handled locally */
  useEffect(() => {
    setFeedItems(items);
    setPage(props.page);
    setPages(props.pages);
  }, [items]);

  /* Load more button handler. Update the page count which will trigger an API call */
  const loadMore = () => {
    if (page < pages) {
      setShowSpinner(true);
      setPage((page) => page + 1);
    }
  };

  /* On page change, load more feed items */
  useEffect(() => {
    if (page > 1) {
      setTimeout(
        function () {
          fetchItems();
        }.bind(this),
        400
      );
    }
  }, [page]);

  /* Fetches the next set of feed items via callback */
  const fetchItems = useCallback(() => {
    const pageParam = {
      params: {
        page: page,
      },
    };
    fetchMethod(pageParam)
      .then((response) => {
        if (response.data) {
          const newArray = [...feedItems, ...response.data.items];
          setFeedItems(newArray);
          setShowSpinner(false);
        } else {
          console.log("ERROR");
        }
      })
      .catch((error) => console.log("api errors:", error));
  });

  const allFeedItems = feedItems.map((item, index) => (
    <FeedPost
      key={item.id + "-" + index}
      kind={item.item_type.toLowerCase()}
      objectData={item}
      currentUser={currentUser}
      showToast={showToast}
      showCommentLink={true}
      classes={classes ? classes : ""}
    />
  ));

  return (
    <div>
      {feedItems && (
        <Fragment>
          {allFeedItems}
          <MoreButton
            showSpinner={showSpinner}
            onClick={loadMore}
            pages={pages}
            page={page}
          />
        </Fragment>
      )}
    </div>
  );
};

PaginatedFeed.propTypes = {
  items: PropTypes.array,
  page: PropTypes.number,
  pages: PropTypes.number,
  fetchMethod: PropTypes.func,
  showToast: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  classes: PropTypes.string,
};
export default PaginatedFeed;
