import React from "react";
import { Link } from "react-router-dom";

export default function Paragraph(props) {
  const { id, children, message, kind, applyFormatting, styles } = props;

  let classNames = "";
  if (kind === "helper") {
    classNames += " has-text-grey";
  }

  let formattedMessage;

  /* If formatting is allowed, replace line breaks, hashtags, and links */
  if (applyFormatting) {
    const reactStringReplace = require("react-string-replace");

    formattedMessage = reactStringReplace(
      message,
      /(\r\n|\r|\n)/g,
      (match, i) => <br key={match + i} />
    );

    formattedMessage = reactStringReplace(
      formattedMessage,
      /#(\w*)/g,
      (match, i) => (
        <Link
          className="inline-link"
          key={match + i}
          to={{
            pathname: "/search",
            search: "?query=" + match,
            state: { query: match },
          }}
        >
          #{match}
        </Link>
      )
    );

    formattedMessage = reactStringReplace(
      formattedMessage,
      /@(\S*)/g,
      (match, i) => (
        <Link key={match + i} className="inline-link" to={`/${match}`}>
          @{match}
        </Link>
      )
    );
  }

  return (
    <div className="content" id={id}>
      <p style={styles} className={classNames}>
        {children ? children : applyFormatting ? formattedMessage : message}
      </p>
    </div>
  );
}
