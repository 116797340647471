import axios from "axios";
const api = axios.create({
  withCredentials: true,
});
const API_URL = "/api/v1";

// Fetch all users
export function fetchUsers(page) {
  return api.get(`${API_URL}/users/`, page, {
    withCredentials: true,
  });
}

// Fetch existing user
export function fetchUser(username) {
  return api.get(`${API_URL}/users/${username}`, {
    withCredentials: true,
  });
}

// Fetch user's art by username. Returns paginated records.
export function fetchArtByUsername(username, params) {
  return api.get(`${API_URL}/users/${username}/load_art`, params, {
    withCredentials: true,
  });
}

// Fetch user's collections by username. Returns paginated records.
export function fetchCollectionsByUsername(username, params) {
  return api.get(`${API_URL}/users/${username}/load_collections`, params, {
    withCredentials: true,
  });
}

// Fetch user's feed items by username. Returns paginated feed of records.
export function fetchFeedItemsByUsername(username, params) {
  return api.get(`${API_URL}/users/${username}/load_feed_items`, params, {
    withCredentials: true,
  });
}

// Fetch the user's collections sorted alphabetically without pagination. This is used for the collect modal.
export function fetchUserCollections(username, optionalParams) {
  return api.get(`${API_URL}/users/${username}/collections`, optionalParams, {
    withCredentials: true,
  });
}

// Create new user
export function submitUserData(user) {
  return api.post(`${API_URL}/users`, user, {
    withCredentials: true,
  });
}

// Update user
export function updateUserData(id, body) {
  return api.patch(`${API_URL}/users/${id}`, body, {
    withCredentials: true,
  });
}

export function updatePassword(body) {
  return api.patch(`${API_URL}/change_password`, body, {
    withCredentials: true,
  });
}

// Update display image
export function updateDisplayImage(body) {
  return api.patch(`${API_URL}/change_display_image`, body, {
    withCredentials: true,
  });
}

// Update cover art
export function updateProfileCover(body) {
  return api.patch(`${API_URL}/change_profile_cover`, body, {
    withCredentials: true,
  });
}

// Delete user
export function deleteUser(id) {
  return api.delete(`${API_URL}/users/${id}`, {
    withCredentials: true,
  });
}

// Create new session
export function loginUser(user) {
  return axios.post(`${API_URL}/login`, user, {
    withCredentials: true,
  });
}

// Check username availability
export function findUsernameAvailability(username) {
  return api.post(
    `${API_URL}/check_username_availability`,
    {
      username: username,
    },
    {
      withCredentials: true,
    }
  );
}

export function fetchFollowers(id, pageParams) {
  return api.get(`${API_URL}/users/${id}/followers`, pageParams, {
    withCredentials: true,
  });
}

export function fetchFollowing(id, pageParams) {
  return api.get(`${API_URL}/users/${id}/following`, pageParams, {
    withCredentials: true,
  });
}

// Retrieve current onboarding status.
export function fetchOnboardingStatus() {
  return api.get(`${API_URL}/onboarding_status`, {
    withCredentials: true,
  });
}
