import React from "react";
import PropTypes from "prop-types";
import stylesheet from "./Controls.module.scss";

const Switch = (props) => {
  const { label, name, id, checked, value, onChange, helperCopy } = props;

  return (
    <div className="field">
      <label className={stylesheet.switch}>
        <input
          type="checkbox"
          id={id ? id : name}
          name={name}
          checked={checked}
          value={value}
          onChange={onChange}
        />
        <span className={`${stylesheet.slider} ${stylesheet.round}`}></span>
      </label>
      <label className={stylesheet.visibleLabel} htmlFor={id ? id : name}>
        {label}
      </label>
      <p className={stylesheet.switchHelperCopy}>{helperCopy}</p>
    </div>
  );
};
Switch.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  helperCopy: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
export default Switch;
