// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "p.Modal-module__modalHelperText___1CihL{margin-top:0.25rem}\n", "",{"version":3,"sources":["/tmp/build_951a6463/app/javascript/components/common/modal/Modal.module.scss"],"names":[],"mappings":"AAAA,wCACE,kBAAmB","file":"Modal.module.scss","sourcesContent":["p.modalHelperText {\n  margin-top: 0.25rem;\n}\n"]}]);
// Exports
exports.locals = {
	"modalHelperText": "Modal-module__modalHelperText___1CihL"
};
module.exports = exports;
