import React from "react";

export default function Grouping(props) {
  const { children, extraSpacing, smallSpacing, alignment, classes } = props;

  let styles = "grouping";

  if (smallSpacing) {
    styles += " has-small-spacing-" + smallSpacing;
  }

  if (extraSpacing) {
    styles += " has-spacing-" + extraSpacing;
  }
  if (alignment) {
    styles += " has-text-" + alignment;
  }

  // let styles = extraSpacing ? "content has-spacing-" + extraSpacing : "content";

  return (
    <div className={`${styles} ${classes ? classes : ""}`}>{children}</div>
  );
}
