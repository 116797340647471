// Field name constants
const TITLE_FIELD_NAME = "title";
const CAPTION_FIELD_NAME = "caption";

// Validation requirements
const TITLE_MIN = 1;
const TITLE_MAX = 70;
const CAPTION_MAX = 1000;

function _validateTitle(title) {
  return title.length < TITLE_MIN || title.length > TITLE_MAX
    ? "Enter a title between 1 and 70 characters."
    : "";
}

function _validateCaption(caption) {
  return caption.length > CAPTION_MAX
    ? "Enter a caption less than 1,000 characters."
    : "";
}

export function isValid(errors) {
  return Object.values(errors).every((error) => error === "");
}

export function validate(userInput) {
  var errors = {};
  for (const field in userInput) {
    // If the input is undefined it means the value is unchanged when updating from Settings.
    if (userInput[field] === undefined) {
      continue;
    }
    if (field === TITLE_FIELD_NAME) {
      errors.title_error = _validateTitle(userInput[field]);
    }
    if (field === CAPTION_FIELD_NAME) {
      errors.caption_error = _validateCaption(userInput[field]);
    }
  }
  return errors;
}
