import React, { Fragment } from "react";
import stylesheet from "./Badges.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const OnboardBadge = () => {
  return (
    <Fragment>
      <ReactTooltip className={stylesheet.tooltip} />
      <div
        className={stylesheet.onboardBadge}
        data-tip="Completed onboarding"
        data-effect="solid"
      >
        <span className={`icon ${stylesheet.icon}`}>
          <FontAwesomeIcon icon={["fa", "leaf"]} />
        </span>
      </div>
    </Fragment>
  );
};
export default OnboardBadge;
