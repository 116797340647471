// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Hooks-module__fadeInSection___kzZm2{opacity:0;transform:translateY(2vh) scale(0.9);visibility:hidden;transition:opacity 0.2s ease-out, transform 0.2s ease-out;will-change:opacity, visibility}.Hooks-module__fadedIn___2hPK9{opacity:1;transform:none;visibility:visible}\n", "",{"version":3,"sources":["/tmp/build_951a6463/app/javascript/components/hooks/Hooks.module.scss"],"names":[],"mappings":"AAIA,qCACE,SAAU,CACV,oCAAqC,CACrC,iBAAkB,CAClB,yDAA0D,CAC1D,+BAAgC,CACjC,+BAGC,SAAU,CACV,cAAe,CACf,kBAAmB","file":"Hooks.module.scss","sourcesContent":["@import \"bulma/sass/utilities/initial-variables\";\n@import \"bulma/sass/utilities/derived-variables\";\n@import \"stylesheets/design-tokens.scss\";\n\n.fadeInSection {\n  opacity: 0;\n  transform: translateY(2vh) scale(0.9);\n  visibility: hidden;\n  transition: opacity 0.2s ease-out, transform 0.2s ease-out;\n  will-change: opacity, visibility;\n}\n\n.fadedIn {\n  opacity: 1;\n  transform: none;\n  visibility: visible;\n}\n"]}]);
// Exports
exports.locals = {
	"fadeInSection": "Hooks-module__fadeInSection___kzZm2",
	"fadedIn": "Hooks-module__fadedIn___2hPK9"
};
module.exports = exports;
