import React, { Fragment } from "react";
import Button from "../common/buttons/Button";
import styles from "./Common.module.scss";

export default function MoreButton(props) {
  const {
    showSpinner,
    onClick,
    pages,
    page,
    wide,
    customButton,
    classes,
  } = props;

  return (
    <Fragment>
      {page < pages || showSpinner === true ? (
        <div
          className={`${classes ? classes : styles.loadMore} ${
            wide ? "" : styles.isSmall
          }`}
        >
          {customButton ? (
            customButton
          ) : (
            <Button
              type="button"
              kind="tertiary"
              label={"Load more"}
              showSpinner={showSpinner}
              onClick={onClick}
              classes={styles.loadMoreButton}
            />
          )}
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
}
