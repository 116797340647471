import React, { Fragment, useEffect, useReducer } from "react";
import { fetchUserCollections } from "../../users/UserAPI";
import { collect } from "../../collections/CollectionAPI";
import PropTypes from "prop-types";

import stylesheet from "../Artwork.module.scss";
import Loading from "../../pages/Loading";
import TextInput from "../../common/TextInput";
import CollectionButton from "../../common/collections/CollectionButton";
import BaseModal from "../../common/modal/BaseModal";
import ModalBody from "../../common/modal/ModalBody";
import ButtonsBar from "../../common/modal/ButtonsBar";
import PrimaryButton from "../../common/buttons/PrimaryButton";

const initialState = {
  isLoading: true,
  showSpinner: false,
  collections: [],
  filterText: "",
  collectionQueue: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_LOADING":
      return { ...state, isLoading: !state.isLoading };
    case "LOAD_STATE":
      return { ...state, ...action.data };
    case "TOGGLE_SPINNER":
      return { ...state, showSpinner: !state.showSpinner };
    case "QUEUE":
      let index = state.collectionQueue.indexOf(parseInt(action.value));
      return {
        ...state,
        collectionQueue:
          index === -1
            ? [...state.collectionQueue, ...action.value]
            : state.collectionQueue.filter((val) => {
                return parseInt(val) !== parseInt(action.value);
              }),
      };
    default:
      return state;
  }
};

const Collect = (props) => {
  const {
    showModal,
    artwork,
    currentUser,
    showToast,
    onRequestClose,
    location,
    history,
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);
  const { isLoading, showSpinner, collections, filterText } = state;

  /* Fetch the current user's collections. */
  useEffect(() => {
    if (!currentUser.id) return;
    if (!showModal) {
      dispatch({
        type: "LOAD_STATE",
        data: {
          ...initialState,
        },
      });
      return;
    }

    /* Passing the artwork's ID to the call returns a property that shows if the art is already in that collection. */
    let artworkToBeCollected = {
      params: {
        artwork_id: artwork.id,
      },
    };
    fetchUserCollections(currentUser.username, artworkToBeCollected)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "LOAD_STATE",
            data: {
              collections: response.data,
              isLoading: false,
            },
          });
        }
      })
      .catch((error) => {
        showToast("Something went wrong.");
      });
  }, [showModal, artwork]);

  /* Reset the state and close the modal. */
  const closeAndCleanup = () => {
    dispatch({
      type: "LOAD_STATE",
      data: {
        ...initialState,
      },
    });
    onRequestClose();
  };

  /* Update the state to include the filter text to search collections. */
  const handleFilterTextChange = (filterText) => {
    dispatch({
      type: "LOAD_STATE",
      data: {
        filterText: filterText,
      },
    });
  };

  /* Accepts a collectionID to be added to the queue if it doesn't exists and removed from the queue if it already exists. */
  const addCollectionToQueue = (collectionId) => {
    dispatch({
      type: "QUEUE",
      value: [collectionId],
    });
  };

  /* On submit, loop through the collection queue and save the art to each collection. */
  const submitHandler = () => {
    dispatch({
      type: "TOGGLE_SPINNER",
    });
    const { collectionQueue } = state;

    setTimeout(
      function () {
        for (let collectionId of collectionQueue) {
          collect(collectionId, artwork.id).catch((error) =>
            showToast("Something went wrong.")
          );
        }
        closeAndCleanup();
        showToast("Collections updated");
      }.bind(this),
      300
    );
  };

  /* Close this modal and redirect to the create collection modal with the artwork-to-be-collected. */
  const createNewCollection = () => {
    closeAndCleanup();

    history.push({
      pathname: `/new/collection`,
      state: { background: location, artworkId: artwork.id },
    });
  };

  /* Create an array of the user's collections, pre-filtered by the filterText if present. */
  const currentUsersCollections = [];
  collections.forEach((collection) => {
    if (collection.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1) {
      currentUsersCollections.push(
        <CollectionButton
          key={collection.id}
          collection={collection}
          onClick={addCollectionToQueue}
        />
      );
    }
  });

  const collectionPlaceholder = (
    <div className={stylesheet.collectionPlaceholder}>
      You don't have any collections yet, but once you do, they'll appear here.
    </div>
  );

  const artworkThumbnail = (
    <figure className="image collectable-preview">
      <img src={artwork.large_thumbnail.url} />
    </figure>
  );

  return (
    <Fragment>
      {showModal && (
        <BaseModal
          showModal={showModal}
          onRequestClose={closeAndCleanup}
          modalSize="card"
        >
          <ModalBody modalTitle={"Save to collections"}>
            <div className="columns is-gapless">
              <div className="column is-one-third">{artworkThumbnail}</div>
              <div className="column">
                {collections.length > 0 ? (
                  <Fragment>
                    <TextInput
                      style="collection-search"
                      placeholder="Search collections"
                      name="search"
                      onChange={(e) => handleFilterTextChange(e.target.value)}
                      icon={["fa", "search"]}
                    />
                    <div className="collection-buttons">
                      {isLoading ? <Loading /> : currentUsersCollections}
                    </div>
                  </Fragment>
                ) : (
                  collectionPlaceholder
                )}
              </div>
            </div>
          </ModalBody>
          <ButtonsBar
            leftButtons={
              <PrimaryButton
                label={"New Collection"}
                style={"outlined"}
                onClick={createNewCollection}
                type="button"
              />
            }
          >
            <PrimaryButton
              label={"Apply changes"}
              isLoading={showSpinner}
              type="button"
              onClick={submitHandler}
            />
          </ButtonsBar>
        </BaseModal>
      )}
    </Fragment>
  );
};
Collect.propTypes = {
  showModal: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  showToast: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default Collect;
