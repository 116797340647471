import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Section from "../common/layout/Section";
import BoundingBox from "../common/layout/BoundingBox";
import Header from "../common/typography/Header";
import Paragraph from "../common/typography/Paragraph";
import ColumnLayout from "../common/layout/ColumnLayout";
import Column from "../common/layout/Column";
import Menu from "../common/menu/Menu";
import InviteRow from "./InviteRow";

import stylesheet from "./Admin.module.scss";
import { fetchAllInvites } from "./AdminAPI.js";
import { globalContext } from "../../util/globalContext.js";

const Invites = () => {
  const [invites, setInvites] = useState([]);
  const [total, setTotal] = useState(0);
  const [claimed, setClaimed] = useState(0);

  const globalState = useContext(globalContext);
  const currentUser = globalState.currentUser;

  useEffect(() => {
    document.title = "Invite management / Admin";
    fetchInvites();
  }, []);

  const fetchInvites = () => {
    fetchAllInvites()
      .then((response) => {
        if (response.data.invites) {
          setInvites(response.data.invites);
          setTotal(response.data.meta.total);
          setClaimed(response.data.meta.claimed);
        }
      })
      .catch((error) => console.log("api errors:", error));
  };

  const inviteRows =
    invites &&
    invites.map((invite) => (
      <InviteRow
        key={invite.id}
        invite={invite}
        showToast={globalState.showToast}
      />
    ));

  return (
    <Section showBackground={true}>
      <ColumnLayout>
        <Column>
          <Menu menuName={"Admin"}>
            <li>
              <Link
                id={"profile"}
                className={"is-active"}
                to={`/admin/invites`}
              >
                Invite Management
              </Link>
            </li>
          </Menu>
        </Column>

        <Column width={9}>
          <BoundingBox>
            <Header
              level={2}
              size={"medium"}
              message={"Invite management"}
              styles={{ marginBottom: "0.25rem" }}
            />
            <Paragraph>
              View and manage all invites for Animatik. {claimed} of {total}{" "}
              invite links are claimed.
            </Paragraph>
            <table className="table is-hoverable">
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Invite</th>
                  <th></th>
                  <th>Date claimed</th>
                </tr>
              </thead>
              <tbody>{inviteRows}</tbody>
            </table>
          </BoundingBox>
        </Column>
      </ColumnLayout>
    </Section>
  );
};
export default Invites;
