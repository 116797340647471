import React, {
  Fragment,
  useState,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import Gallery from "./Gallery";
import MoreButton from "../../common/MoreButton";

const initialState = {
  artwork: [],
  page: 1,
  pagesTotal: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_STATE":
      return { ...state, ...action.data };
    case "LOAD_ARTWORK":
      return { ...state, artwork: [...state.artwork, ...action.value] };
    case "INCREMENT_PAGE":
      return {
        ...state,
        page: state.page + 1,
      };
    default:
      return state;
  }
};

const PaginatedGallery = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showSpinner, setShowSpinner] = useState(false);

  const { galleryTitle, fetchMethod, artworkActions } = props;
  const { artwork, page, pagesTotal } = state;

  /* When the artwork prop changes, map props to state so that pagination is handled locally */
  useEffect(() => {
    dispatch({
      type: "LOAD_STATE",
      data: {
        artwork: props.artwork,
        page: props.page,
        pagesTotal: props.pages,
      },
    });
  }, [props.artwork]);

  /* Load more button handler. Update the page count which will trigger an API call */
  const loadMore = () => {
    if (page < pagesTotal) {
      setShowSpinner(true);
      dispatch({
        type: "INCREMENT_PAGE",
      });
    }
  };

  /* On page change, load more artwork */
  useEffect(() => {
    if (page > 1) {
      setTimeout(
        function () {
          loadMoreArtwork();
        }.bind(this),
        400
      );
    }
  }, [page]);

  /* Fetches the next set of artwork via callback */
  const loadMoreArtwork = useCallback(() => {
    fetchMethod(page)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "LOAD_ARTWORK",
            value: response.data.art,
          });
          setShowSpinner(false);
        } else {
          console.log("ERROR");
        }
      })
      .catch((error) => console.log("api errors:", error));
  });

  return (
    <div>
      {artwork && (
        <Fragment>
          <Gallery
            title={galleryTitle}
            works={artwork}
            mosaic={true}
            actions={artworkActions}
          />
          <MoreButton
            showSpinner={showSpinner}
            onClick={loadMore}
            pages={pagesTotal}
            page={page}
          />
        </Fragment>
      )}
    </div>
  );
};

PaginatedGallery.propTypes = {
  galleryTitle: PropTypes.string,
  artwork: PropTypes.array,
  page: PropTypes.number,
  pages: PropTypes.number,
  fetchMethod: PropTypes.func,
  artworkActions: PropTypes.array,
};
export default PaginatedGallery;
