import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class InputSuccess extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { message } = this.props;
    const messageSubtext = (
      <p className="help is-success">
        <span className="icon is-small">
          <FontAwesomeIcon icon={['fa', 'check-circle']} />
        </span>
        <span className="is-error-subtext">
          {message}
        </span>
      </p>
    );

    return (
      <div>
        {message ? messageSubtext : ''}
      </div>
    );
  }
}
export default InputSuccess;