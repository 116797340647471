import React from "react";

export default function Heading(props) {
  const { children, level, levelStyle, message } = props;

  let headingStyle = levelStyle ? levelStyle : level ? level : "1";
  if (headingStyle === 4 || headingStyle === 5) {
    headingStyle += " has-text-weight-bold";
  }
  let style = "title is-" + headingStyle;

  if (level === 1) {
    return <h1 className={style}>{children ? children : message}</h1>;
  } else if (level === 2) {
    return <h2 className={style}>{children ? children : message}</h2>;
  } else if (level === 3) {
    return <h3 className={style}>{children ? children : message}</h3>;
  } else if (level === 4) {
    return <h4 className={style}>{children ? children : message}</h4>;
  } else if (level === 5) {
    return <h5 className={style}>{children ? children : message}</h5>;
  } else if (level === 6) {
    return <h6 className={style}>{children ? children : message}</h6>;
  } else {
    return <h1 className={style}>{children ? children : message}</h1>;
  }
}
