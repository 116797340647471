import React, { useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "../../common/typography/Header";
import Paragraph from "../../common/typography/Paragraph";

const AboutUs = () => {
  useEffect(() => {
    document.title = "About / Animatik";
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Header
        level={2}
        size={"medium"}
        message={"About"}
        styles={{ marginBottom: "0.75rem" }}
      />
      <Paragraph>
        Animatik is a new social network for artists{" "}
        <a
          href="https://www.investopedia.com/terms/b/bootstrap.asp"
          target="_blank"
          className="link"
        >
          bootstrapped
        </a>{" "}
        out of Western Canada. We’re on a mission to build the world’s best
        community for artists and art lovers and committed to creating an
        inclusive and friendly home for our members.
      </Paragraph>
      <Paragraph>
        If you have comments or questions about our roadmap or policies, or just
        want to say hi, don't hesitate to{" "}
        <Link className="link" to={`/about/contact`}>
          reach out
        </Link>
        —we'd love to hear from you!
      </Paragraph>
    </Fragment>
  );
};
export default AboutUs;
