import React, { useReducer, useContext, useState, useEffect } from "react";
import { isEqual } from "lodash";

import { globalContext } from "../../../util/globalContext.js";
import {
  updateUserData,
  findUsernameAvailability,
  deleteUser,
} from "../UserAPI";
import { validate, isValid, mapUserModelErrors } from "../UserValidator";

import stylesheet from "./Settings.module.scss";
import Menu from "./Menu";
import ChangePassword from "./ChangePassword";
import Section from "../../common/layout/Section";
import ColumnLayout from "../../common/layout/ColumnLayout";
import Column from "../../common/layout/Column";
import BoundingBox from "../../common/layout/BoundingBox";
import Header from "../../common/typography/Header";
import Paragraph from "../../common/typography/Paragraph";
import TextInput from "../../common/TextInput";
import ButtonsBar from "../../common/modal/ButtonsBar";
import ConfirmModal from "../../common/modal/ConfirmModal";
import Button from "../../common/buttons/Button";

const initialState = {
  id: "",
  username: "",
  email: "",
  username_error: "",
  username_success: "",
  email_error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_STATE":
      return { ...state, ...action.data };
    case "UPDATE":
      if (action.field === "username") {
        return {
          ...state,
          [action.field]: action.value,
          [action.field + "_error"]: "",
          [action.field + "_success"]: "",
        };
      }
      return {
        ...state,
        [action.field]: action.value,
        [action.field + "_error"]: "",
      };
    default:
      return state;
  }
};

const EditAccount = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPasswordModal, toggleShowPasswordModal] = useState(false);
  const [showConfirmModal, toggleConfirmModal] = useState(false);

  const globalState = useContext(globalContext);
  const currentUser = globalState.currentUser;

  const {
    id,
    username,
    email,
    username_error,
    username_success,
    email_error,
  } = state;

  useEffect(() => {
    if (!currentUser) return;
    document.title = "Settings / Account";
    updateStateWithCurrentUser();
  }, []);

  const updateStateWithCurrentUser = () => {
    dispatch({
      type: "LOAD_STATE",
      data: {
        id: currentUser.id,
        username: currentUser.username,
        email: currentUser.email,
        username_error: "",
        username_success: "",
        email_error: "",
      },
    });
  };

  const validateUsername = (e) => {
    var validatedInput = validate({ [e.target.name]: e.target.value });

    /* Check to see if the username is available if it passes validation and doesn't match the current username. */
    if (
      validatedInput.username_error === "" &&
      e.target.value !== currentUser.username
    ) {
      findUsernameAvailability(username).then((response) => {
        if (response.data.available === false) {
          dispatch({
            type: "LOAD_STATE",
            data: {
              username_error:
                "This username is already taken. Try another one.",
            },
          });
        } else {
          dispatch({
            type: "LOAD_STATE",
            data: {
              username_success: "This username is available. It's all yours!",
            },
          });
        }
      });
    } else {
      dispatch({
        type: "LOAD_STATE",
        data: validatedInput,
      });
    }
  };

  const cancelChanges = () => {
    updateStateWithCurrentUser();
  };

  const validateField = (e) => {
    var validatedInput = validate({ [e.target.name]: e.target.value });

    dispatch({
      type: "LOAD_STATE",
      data: validatedInput,
    });
  };

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE",
      field: e.target.name,
      value: e.target.value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const updatedProperties = {
      email,
      username,
    };

    const currentProperties = {
      username: currentUser.username,
      email: currentUser.email,
    };

    if (isEqual(updatedProperties, currentProperties)) {
      globalState.showToast("Everything is already up to date.");
      setShowSpinner(false);
      return;
    }
    var validatedInput = validate(updatedProperties);
    if (!isValid(validatedInput)) {
      dispatch({
        type: "LOAD_STATE",
        data: validatedInput,
      });
      setShowSpinner(false);
      return;
    }
    /* Check specifically if there is a username error, in this case it might be a username that's already taken */
    if (username_error !== "") {
      setShowSpinner(false);
      return;
    }
    setTimeout(
      function () {
        updateUserData(id, updatedProperties)
          .then((response) => {
            if (response.data.id) {
              setShowSpinner(false);
              globalState.showToast("You edited your account.");
              props.handleSettingsChange(response.data);
            } else {
              dispatch({
                type: "LOAD_STATE",
                data: mapUserModelErrors(response.data.errors),
              });
              setShowSpinner(false);
            }
          })
          .catch(() => {
            globalState.showToast(
              "Something went wrong. Refresh and try again."
            );
            setShowSpinner(false);
          });
      }.bind(this),
      300
    );
  };

  const deleteAccount = () => {
    deleteUser(id)
      .then((response) => {
        if (response.data.deleted) {
          props.handleLogout();
          globalState.showToast("You deleted your account. You'll be missed!");
        } else {
          globalState.showToast("Something went wrong. Refresh and try again.");
        }
      })
      .catch(() =>
        globalState.showToast("Something went wrong. Refresh and try again.")
      );
  };

  return (
    <Section showBackground={true}>
      <ChangePassword
        showModal={showPasswordModal}
        onClose={() => toggleShowPasswordModal(!showPasswordModal)}
      />
      <ConfirmModal
        showConfirmModal={showConfirmModal}
        toggleModal={() => toggleConfirmModal(!showConfirmModal)}
        modalTitle={"Delete account?"}
        modalText="You're about to permanently delete your account and all data. Your art, username, messages, and comments will be lost. Are you sure you want to delete your account?"
        confirmButtonLabel="Yes, delete account"
        backButtonLabel="No"
        onConfirm={deleteAccount}
      />

      <ColumnLayout>
        <Column>
          <Menu activeItem={"account"} />
        </Column>

        <Column width={8}>
          <BoundingBox classes={stylesheet.settingsContent}>
            <Header
              level={2}
              size={"medium"}
              styles={{ marginBottom: "0.5rem" }}
              message={"Account"}
            />
            <Paragraph
              message={
                "Here you can adjust your sign in preferences and account details."
              }
            />
            <form onSubmit={submitHandler}>
              <TextInput
                label="Username"
                name="username"
                placeholder={"Create a unique username"}
                value={username}
                error={username_error}
                success={username_success}
                onChange={handleChange}
                onBlur={validateUsername}
                helper_text="This is your unique identifier used to sign in and as your profile url."
              />
              <TextInput
                label="Email address"
                name="email"
                value={email}
                error={email_error}
                onChange={handleChange}
                onBlur={validateField}
              />
              <Button
                type="button"
                kind="secondary"
                label={"Change password"}
                onClick={() => toggleShowPasswordModal(!showPasswordModal)}
              />

              <div className={stylesheet.closeAccount}>
                <Header level={3} size={"small"} message={"Close account"} />
                <Paragraph
                  message={"Delete your account and all account data."}
                />
                <Button
                  type="button"
                  kind="secondary"
                  isDanger={true}
                  label={"Close account"}
                  onClick={() => toggleConfirmModal(!showConfirmModal)}
                />
              </div>

              <ButtonsBar>
                <Button
                  type="button"
                  kind="ternary"
                  label={"Cancel"}
                  onClick={cancelChanges}
                />
                <Button
                  type="submit"
                  kind="primary"
                  label={"Apply changes"}
                  showSpinner={showSpinner}
                />
              </ButtonsBar>
            </form>
          </BoundingBox>
        </Column>
      </ColumnLayout>
    </Section>
  );
};
export default EditAccount;
