import React from "react";
import PropTypes from "prop-types";

import stylesheet from "./Menu.module.scss";
import BoundingBox from "../../common/layout/BoundingBox";
import Header from "../../common/typography/Header";

const Menu = (props) => {
  const { children, menuName, classes } = props;

  return (
    <BoundingBox classes={`${stylesheet.sideBarMenu} ${classes}`}>
      <Header level={1} size={"medium"} message={menuName} />
      <ul className="menu-list">{children}</ul>
    </BoundingBox>
  );
};
Menu.propTypes = {
  menuName: PropTypes.string.isRequired,
  classes: PropTypes.string,
};
export default Menu;
