import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import stylesheet from "./Posts.module.scss";
import UserTag from "../users/UserTag";

const Post = (props) => {
  const { post } = props;

  return (
    <Link to={`/posts/${post.id}`} className={stylesheet.postBox}>
      <div className={stylesheet.userTag}>
        <UserTag size={"small"} user={post.user} link={false} />
      </div>

      {post.title && <h4 className={stylesheet.postTitle}>{post.title}</h4>}
      <p
        className={
          post.title ? stylesheet.postBody : stylesheet.postBodyWithoutTitle
        }
      >
        {post.body}
      </p>
    </Link>
  );
};
Post.propTypes = {
  post: PropTypes.object.isRequired,
};
export default Post;
