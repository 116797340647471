// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "@media only screen and (min-width: 992px){.Menu-module__sideBarMenu___nG8gi{max-width:248px;float:right}}\n", "",{"version":3,"sources":["/tmp/build_951a6463/app/javascript/components/common/menu/Menu.module.scss"],"names":[],"mappings":"AAME,0CADF,kCAEI,eAAgB,CAChB,WAAY,CAEf","file":"Menu.module.scss","sourcesContent":["@import \"bulma/sass/utilities/initial-variables\";\n@import \"bulma/sass/utilities/derived-variables\";\n@import \"stylesheets/design-tokens.scss\";\n\n/* Layout styling */\n.sideBarMenu {\n  @media only screen and (min-width: 992px) {\n    max-width: 248px;\n    float: right;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"sideBarMenu": "Menu-module__sideBarMenu___nG8gi"
};
module.exports = exports;
