import React, { useLayoutEffect } from "react";
import Modal from "react-modal";
var noScroll = require("no-scroll");

import ButtonsBar from "./ButtonsBar";
import Button from "./../buttons/Button";
import IconButton from "../buttons/IconButton";

import { useHistory } from "react-router-dom";

export default function BaseModal(props) {
  let history = useHistory();

  const {
    children,
    modalSize,
    showModal,
    shouldShowCloseAction,
    onRequestClose,
    shouldShowCancelActions,
    cancelButtonLabel,
    backButtonLabel,
    onConfirm,
    onBack,
    isSecondModal,
    hasSmallMargins,
  } = props;

  let modalClose = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  let customClose = () => {
    onRequestClose();
  };

  useLayoutEffect(() => {
    return () => {
      afterModalClose();
    };
  }, []);

  let afterModalClose = () => {
    noScroll.off();
  };

  let afterOpenModal = () => {
    noScroll.on();
  };

  const sizeClasses = modalSize ? " is-" + modalSize : "";
  const modalClasses = "modal-box" + sizeClasses;

  return (
    <div>
      <Modal
        isOpen={showModal}
        onAfterOpen={afterOpenModal}
        contentLabel="Modal"
        className={modalClasses}
        overlayClassName="modal-overlay"
        shouldCloseOnOverlayClick={true}
        appElement={document.getElementById("body")}
        onRequestClose={onRequestClose ? customClose : modalClose}
      >
        <div className="close-button is-pulled-right">
          <IconButton
            ariaLabel={"Close"}
            icon={["fa", "times"]}
            onClick={onRequestClose ? customClose : modalClose}
          />
        </div>

        {children}

        {shouldShowCloseAction && (
          <ButtonsBar hasSmallMargins={hasSmallMargins}>
            <Button
              kind={"ternary"}
              label={"Close"}
              onClick={onRequestClose ? customClose : modalClose}
              type={"button"}
            />
          </ButtonsBar>
        )}

        {shouldShowCancelActions && (
          <ButtonsBar hasSmallMargins={hasSmallMargins}>
            <Button
              kind={"ternary"}
              label={backButtonLabel ? backButtonLabel : "Cancel"}
              onClick={onBack ? onBack : modalClose}
              type={"button"}
            />
            <Button
              kind={"primary"}
              label={cancelButtonLabel ? cancelButtonLabel : "Delete"}
              onClick={onConfirm ? onConfirm : modalClose}
              type={"button"}
              isDanger={true}
            />
          </ButtonsBar>
        )}
      </Modal>
    </div>
  );
}
