import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styles from "./Dropdown.module.scss";
import PropTypes from "prop-types";

const DropdownItem = (props) => {
  const {
    label,
    type,
    linkTo,
    onClick,
    iconLeft,
    children,
    isPrimaryAction,
  } = props;

  const itemLabel = (
    <Fragment>
      {iconLeft && (
        <span className={`icon ${styles.icon}`}>
          <FontAwesomeIcon icon={iconLeft} />
        </span>
      )}
      <span className={styles.label}>{label}</span>
    </Fragment>
  );

  return (
    <Fragment>
      {type === "link" && (
        <Link
          to={linkTo ? linkTo : "/"}
          onClick={onClick}
          className={`dropdown-item ${
            isPrimaryAction ? styles.primary : styles.item
          }`}
        >
          {itemLabel}
        </Link>
      )}
      {type === "button" && (
        <button
          className={`dropdown-item ${
            isPrimaryAction ? styles.primary : styles.item
          }`}
          onClick={onClick}
        >
          {itemLabel}
        </button>
      )}
      {type === "custom" && (
        <div className={`dropdown-item ${styles.item}`}>{children}</div>
      )}
      {type === "notification" && (
        <Link
          to={linkTo ? linkTo : "/"}
          onClick={onClick}
          className={`dropdown-item ${styles.notification}`}
        >
          {children}
        </Link>
      )}
    </Fragment>
  );
};

DropdownItem.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconLeft: PropTypes.array,
  isPrimaryAction: PropTypes.bool,
};

export default DropdownItem;
