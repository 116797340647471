import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Button from "../common/buttons/Button";
import stylesheet from "./Admin.module.scss";

const InviteRow = (props) => {
  const { invite, showToast } = props;
  const inviteLinkRef = useRef(null);

  const copyToClipboard = (e) => {
    inviteLinkRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    showToast("Copied to clipboard");
  };

  return (
    <tr>
      <td>{invite.code}</td>
      <td>
        {!invite.claimed && (
          <input
            ref={inviteLinkRef}
            type="text"
            name="invite"
            className={stylesheet.inviteLink}
            defaultValue={`https://www.animatik.co/join?code=` + invite.code}
          />
        )}
        {invite.user ? (
          <Link
            className="link"
            to={{
              pathname: `/` + invite.user.username,
            }}
            target="_blank"
          >
            {invite.user.username}
          </Link>
        ) : (
          ""
        )}
      </td>
      <td>
        {!invite.claimed && (
          <Button
            kind="secondary"
            label="Copy link"
            type="button"
            classes="is-small"
            onClick={copyToClipboard}
          />
        )}
      </td>
      <td>{invite.claimed && invite.updated_at}</td>
    </tr>
  );
};
export default InviteRow;
