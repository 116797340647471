import React from "react";

export default function ButtonsBar(props) {
  const { children, leftButtons, hasSmallMargins } = props;
  return (
    <div className={hasSmallMargins ? "modal-footer-small" : "modal-footer"}>
      <div className="left-aligned-buttons">{leftButtons}</div>
      <div className="buttons is-right">{children}</div>
    </div>
  );
}
