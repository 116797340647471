import axios from 'axios';
const api = axios.create({
  withCredentials: true,
});
const API_URL = '/api/v1';

export function fetchLoginStatus(user) {
  return axios.get(`${API_URL}/logged_in`, {
    withCredentials: true
  });
}

export function destroySession(user) {
  return axios.delete(`${API_URL}/logout`, {
    withCredentials: true
  });
}