import React, { Component, useEffect } from 'react';
import BaseModal from '../common/modal/BaseModal';
import ModalBody from '../common/modal/ModalBody';
import ButtonsBar from '../common/modal/ButtonsBar';
import ConfirmModal from '../common/modal/ConfirmModal'
import Wizard from '../common/Wizard'

function StepOne(props) {

  return (
    <div>
      <ModalBody modalTitle={"Step One"}>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam, sem auctor molestie sodales, mi risus consequat eros, eu euismod arcu ipsum in massa. Quisque suscipit, magna eget cursus posuere, libero nibh commodo magna, ac varius sem lacus et tortor. Integer posuere commodo tellus et maximus. Pellentesque metus tortor, blandit vitae tempor eget, accumsan varius tellus. Morbi consequat quam eu odio suscipit pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pretium tristique consectetur. Nullam sed orci ornare, tempor nisi a, sodales nulla. In non mollis est. Cras sed elementum justo. In interdum vitae velit eget rhoncus. Proin tempus id lectus quis efficitur. Phasellus vel aliquam nisl, non tempus arcu. Morbi ultrices facilisis orci, in rutrum tortor auctor eget.</p>
      </ModalBody>
      <ButtonsBar>
        <button className="button is-primary" onClick={props.nextStep} type="button">Next</button>
      </ButtonsBar>
    </div>
  );
}


function StepTwo(props) {
  return (
    <div>
      <ModalBody modalTitle={"Step Two"} modalHelperText={"Now on step two, you can click back!"}>
        <p>Donec a congue arcu. Ut vitae justo sed felis hendrerit pharetra. Suspendisse finibus elit ut arcu ultricies, vel commodo urna faucibus. Maecenas eu neque vitae ligula dictum ultricies. Nam efficitur massa metus, eget semper turpis sodales fermentum. Ut tortor leo, sodales vitae auctor in, sagittis at leo. Morbi non ultrices mi. Morbi ultricies, lacus ut volutpat lacinia, leo tortor tincidunt orci, nec elementum mi dui sit amet est.</p>
      </ModalBody>
      <ButtonsBar>
        <button className="button is-white" onClick={props.previousStep} type="button">Back</button>
        <button className="button is-primary" onClick={props.nextStep} type="button">Submit</button>
      </ButtonsBar>
    </div>
  );
}


class StepThree extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isActive !== prevProps.isActive) {
      this.props.updateShowCloseButton();
    }
  }

  render() {
    return (
      <div>
        <ModalBody modalTitle={"Step Three"}>
          <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec vestibulum volutpat enim, ut lacinia sem fermentum vitae. Cras eleifend sagittis eros blandit mollis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aenean non risus rutrum, fermentum risus et, ultrices lorem. Vestibulum vitae lacinia nunc. Proin id hendrerit urna. Proin id viverra mauris. Nunc viverra scelerisque aliquet. Aliquam leo quam, tempus ut purus eget, consectetur blandit urna. Aliquam dapibus tellus in elit semper, quis sagittis metus molestie. Proin aliquam dui enim, sed bibendum tortor lacinia in. Suspendisse massa odio, faucibus vitae pharetra non, pellentesque euismod felis. Integer hendrerit leo non nisl fermentum fringilla. Praesent vel arcu imperdiet, varius est lobortis, consequat est.</p>
        </ModalBody>
      </div>
    );
  }

}


class ModalSample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: 'Modal Title',
      currentStep: 1,
      showConfirmModal: false,
      shouldShowCloseButton: false,
    };
  }

  updateShowCloseButton = () => {
    this.setState({
      shouldShowCloseButton: true
    });
  }

  updateCurrentStep = (newStep) => {
    this.setState({
      currentStep: this.state.currentStep + 1
    });
  }

  toggleConfirmModal = () => {
    this.setState(prevState => ({
      showConfirmModal: !prevState.showConfirmModal
    }));
  }

  render() {
    return (
      <div>
        <BaseModal
          shouldShowCloseAction={this.state.shouldShowCloseButton}
          showModal={true}
          modalSize="medium"
          onRequestClose={this.state.currentStep === 3 ? this.toggleConfirmModal : null}>

          <Wizard onStepChange={this.updateCurrentStep}>
            <StepOne />
            <StepTwo />
            <StepThree updateShowCloseButton={this.updateShowCloseButton} />
          </Wizard>

          <ConfirmModal
            showConfirmModal={this.state.showConfirmModal}
            toggleModal={this.toggleConfirmModal}
            modalText="You're about to quit this process. Are you sure you want to quit now?"
            confirmButtonLabel="Quit flow" />
        </BaseModal>
      </div>
    );
  }
}
export default ModalSample;