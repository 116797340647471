import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useReducer,
  useCallback,
  useMemo,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { globalContext } from "../../util/globalContext.js";
import { fetchCollection, collect } from "./CollectionAPI";

import Loading from "../pages/Loading";
import Gallery from "../common/gallery/Gallery";
import Heading from "../common/typography/Heading";
import Paragraph from "../common/typography/Paragraph";
import Section from "../common/layout/Section";
import ButtonsBar from "../common/layout/ButtonsBar";
import DisplayImage from "../common/users/DisplayImage";
import Grouping from "../common/layout/Grouping";
import MoreButton from "../common/MoreButton";

const initialState = {
  isLoading: true,
  showSpinner: false,
  id: "",
  name: "",
  desc: "",
  secret: false,
  user: {},
  art: [],
  page: "",
  pageCount: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_LOADING":
      return { ...state, isLoading: !state.isLoading };
    case "LOAD_STATE":
      return { ...state, ...action.data };
    case "REMOVE_ARTWORK":
      return { ...state, art: state.art.filter((art) => art.id !== action.id) };
    case "UPDATE":
      return { ...state, [action.field]: action.value };
    case "LOAD_MORE":
      return {
        ...state,
        showSpinner: false,
        art: [...state.art, ...action.data],
      };
    case "INCREMENT_PAGE":
      return {
        ...state,
        showSpinner: true,
        page: state.page + 1,
      };
    default:
      return state;
  }
};

const ViewCollection = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  /* Get current user */
  const globalState = useContext(globalContext);
  const currentUser = globalState.currentUser;

  /* Get location for edit collection modal background */
  const location = useLocation();

  /* Set the state constants and document title */
  const {
    id,
    name,
    user,
    desc,
    secret,
    art,
    isLoading,
    showSpinner,
    pageCount,
    page,
  } = state;
  document.title = state.name + " curated by " + state.user.display_name;

  /* Fetch the collection on page load */
  useEffect(() => {
    document.title = "Collection";

    /* Fetch the collection */
    fetchCollection(props.match.params.id)
      .then((response) => {
        if (response.data.errors) {
          props.history.push("/not-found");
          return;
        }
        if (response.data) {
          dispatch({
            type: "LOAD_STATE",
            data: {
              id: response.data.collection.id,
              name: response.data.collection.name,
              desc: response.data.collection.desc,
              secret: response.data.collection.secret,
              user: response.data.collection.user,
              art: response.data.meta.art,
              page: response.data.meta.page,
              pageCount: response.data.meta.pageCount,
              isLoading: false,
            },
          });
        } else {
          console.log("ERROR");
        }
      })
      .catch((error) => {
        props.history.push("/not-found");
      });
  }, [props.match.params.id]);

  /* On page change, load more collections */
  useEffect(() => {
    if (page > 1) {
      setTimeout(
        function () {
          _loadMoreArt();
        }.bind(this),
        400
      );
    }
  }, [page]);

  /* Fetches the next set of collected art via callback */
  const _loadMoreArt = useCallback(() => {
    let query = {
      params: {
        page: page,
      },
    };
    fetchCollection(props.match.params.id, query)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "LOAD_MORE",
            data: response.data.meta.art,
          });
        } else {
          console.log("ERROR");
        }
      })
      .catch((error) => console.log("api errors:", error));
  });

  /* Remove this artwork from collection - only the current user will see this action from the OptionsDropdown component */
  const removeFromCollection = useCallback((artworkId) => {
    /* The collection action also undoes collecting artwork */
    collect(props.match.params.id, artworkId)
      .then((response) => {
        if (!response.data.collected) {
          dispatch({
            type: "REMOVE_ARTWORK",
            id: artworkId,
          });
          globalState.showToast("Removed from collection");
        } else {
          globalState.showToast("Something went wrong");
        }
      })
      .catch((error) => {
        globalState.showToast("Something went wrong");
      });
  });

  /* Memoize the actions array so that it doesn't trigger re-renders when more art is loaded */
  const actions = useMemo(() => {
    return [
      {
        name: "Remove from collection",
        action: removeFromCollection,
      },
    ];
  }, []);

  /* Load more button handler. Update the page count which will trigger an API call */
  const loadMore = () => {
    if (page < pageCount) {
      dispatch({
        type: "INCREMENT_PAGE",
      });
    }
  };

  const curator = (
    <span className="curator">
      Curated by{" "}
      <Link to={`/${state.user.username}`}>{state.user.display_name}</Link>
    </span>
  );

  const editCollection = (
    <Link
      className={"button is-rounded is-primary is-outlined"}
      to={{
        pathname: `/collections/edit/${id}`,
        state: { background: location, id: id },
      }}
    >
      <span className="icon">
        <FontAwesomeIcon icon={["fa", "edit"]} />
      </span>
      <span>Edit</span>
    </Link>
  );

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <Section style={"slim"}>
            <div
              className={`collection-title has-text-centered ${
                currentUser.id === user.id
                  ? "has-half-spacing"
                  : "has-full-spacing"
              }`}
            >
              <h1 className="title">
                {name}
                {secret && (
                  <span className="icon secret-collection">
                    <FontAwesomeIcon icon={["fa", "lock"]} />
                  </span>
                )}
              </h1>
              <DisplayImage user={user} size={48} />
              <Paragraph kind={"helper"} message={curator} />
              {desc && (
                <Paragraph
                  styles={{ marginTop: "1rem" }}
                  applyFormatting={true}
                  message={desc}
                />
              )}
            </div>
          </Section>
          <Section style={"slim"}>
            {currentUser.id === user.id && (
              <ButtonsBar spacing={"small"} alignment={"right"}>
                {editCollection}
              </ButtonsBar>
            )}
            <Grouping extraSpacing={"bottom"}>
              <Gallery
                works={art}
                mosaic={true}
                collectionId={id}
                actions={currentUser.id === user.id ? actions : null}
              />
              <MoreButton
                showSpinner={showSpinner}
                onClick={loadMore}
                pages={pageCount}
                page={page}
              />
            </Grouping>
          </Section>
        </Fragment>
      )}
    </Fragment>
  );
};
export default ViewCollection;
