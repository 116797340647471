import React, { useState } from "react";
import PropTypes from "prop-types";
import stylesheet from "./Navigation.module.scss";
import DisplayImage from "../common/users/DisplayImage";
import DropdownMenu from "../common/dropdown/DropdownMenu";
import DropdownItem from "../common/dropdown/DropdownItem";
import DropdownHeading from "../common/dropdown/DropdownHeading";
import DropdownDivider from "../common/dropdown/DropdownDivider";

const UserMenu = (props) => {
  const { currentUser, handleSignOut } = props;

  /* Handle showing and hiding the dropdown */
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className={stylesheet.userIconDropdown}>
      <DropdownMenu
        id={"usermenu"}
        showDropdown={showDropdown}
        hideDropdown={() => setShowDropdown(false)}
        position={"right"}
        trigger={
          <button
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <DisplayImage link={false} user={currentUser} size={40} />
          </button>
        }
      >
        <DropdownHeading label={currentUser.display_name} />
        <DropdownItem
          type={"link"}
          label={"Profile"}
          linkTo={`/${currentUser.username}`}
          onClick={() => setShowDropdown(false)}
          iconLeft={["fa", "seedling"]}
        />
        <DropdownItem
          type={"link"}
          label={"Settings"}
          linkTo={"/settings/profile"}
          onClick={() => setShowDropdown(false)}
          iconLeft={["fa", "tools"]}
        />
        <DropdownDivider />
        <DropdownItem
          type={"link"}
          label={"Sign out"}
          linkTo={"/logout"}
          onClick={handleSignOut}
          iconLeft={["fa", "sign-out-alt"]}
        />
      </DropdownMenu>
    </div>
  );
};
UserMenu.propTypes = {
  currentUser: PropTypes.object.isRequired,
  handleSignOut: PropTypes.func,
};
export default UserMenu;
