import axios from "axios";
const api = axios.create({
  withCredentials: true,
});
const API_URL = "/api/v1";

// Fetch all invites
export function fetchAllInvites() {
  return api.get(`${API_URL}/invites/`, {
    withCredentials: true,
  });
}
