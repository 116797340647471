import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import ActionButton from "../buttons/ActionButton";
import LikesList from "../../artwork/View/LikesList";
import { likeArtwork } from "../../artwork/ArtworkAPI";
import { likePost } from "../../posts/PostAPI";

const LikeButton = (props) => {
  const {
    objectId,
    objectOwnerId,
    isLiked,
    likeCount,
    currentUser,
    objectType,
  } = props;
  const [liked, setLiked] = useState(false);
  const [count, setCount] = useState(null);

  const [showLikeList, toggleLikeList] = useState(false);

  const history = useHistory();
  const isArtworkType = objectType === "artwork";
  const isTextPostType = objectType === "post";

  /* Set local state for isFollowing so that it can be toggled on/off inline, independent of a parent component. */
  useEffect(() => {
    setLiked(isLiked);
    setCount(likeCount);
  }, [isLiked]);

  const like = () => {
    /* First handle guest and owner behaviour */
    if (!currentUser.id) {
      history.push(`/signin`);
      return;
    } else if (currentUser.id === objectOwnerId) {
      toggleLikeListModal();
      return;
    }
    /* Determine which response to use based on record type */
    if (isTextPostType) {
      likePost(objectId)
        .then((response) => {
          if (response.data) {
            setLiked(response.data.likedByCurrentUser);
            setCount(response.data.newLikeCount);
          }
        })
        .catch((error) => {
          console.log("Errors");
        });
    } else {
      likeArtwork(objectId)
        .then((response) => {
          if (response.data) {
            setLiked(response.data.likedByCurrentUser);
            setCount(response.data.newLikeCount);
          }
        })
        .catch((error) => {
          console.log("Errors");
        });
    }
  };

  const toggleLikeListModal = () => {
    toggleLikeList((state) => !state);
  };

  return (
    <div className="like-button-container">
      {showLikeList && (
        <LikesList
          kind={objectType}
          showModal={showLikeList}
          toggleModal={toggleLikeListModal}
          likesCount={likeCount}
          objectId={objectId}
        />
      )}
      <ActionButton
        label={count === 0 ? null : count}
        kind={liked ? "liked" : count === 0 ? "fresh" : "like"}
        type="button"
        onClick={() => like()}
        icon={["fa", "heart"]}
        style={"outlined"}
      />
    </div>
  );
};
LikeButton.propTypes = {
  objectType: PropTypes.string.isRequired,
  objectId: PropTypes.number.isRequired,
  objectOwnerId: PropTypes.number.isRequired,
  isLiked: PropTypes.bool,
  likeCount: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
};
export default LikeButton;
