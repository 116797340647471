/* Field names to validate */
const NAME_FIELD_NAME = "name";
const DESC_FIELD_NAME = "desc";

/* Name requirements */
const NAME_MIN = 1;
const NAME_MAX = 70;

/* Description requirements */
const DESC_MAX = 180;

function _validateName(name) {
  return name.length > NAME_MAX || name.length < NAME_MIN
    ? "Enter a name between 1 and 70 characters"
    : "";
}

function _validateDesc(desc) {
  return desc.length > DESC_MAX
    ? "Description can't be longer than 180 characters"
    : "";
}

export function isValid(errors) {
  return Object.values(errors).every((error) => error === "");
}

export function validate(userInput) {
  var errors = {};
  for (const field in userInput) {
    if (field === NAME_FIELD_NAME) {
      errors.nameError = _validateName(userInput[field]);
    }
    if (field === DESC_FIELD_NAME) {
      errors.descError = _validateDesc(userInput[field]);
    }
  }
  return errors;
}
