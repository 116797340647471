import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { updateDisplayImage } from "../UserAPI";

import BaseModal from "../../common/modal/BaseModal";
import ImageUploader from "../../common/uploading/ImageUploader";
import ImageCropper from "../../common/uploading/ImageCropper";
import Wizard from "../../common/Wizard";

const DisplayImageWizard = (props) => {
  const [displayImage, setDisplayImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const currentUser = props.location.state.currentUser;
  const history = useHistory();

  const handleSubmit = () => {
    if (croppedImage !== null && currentUser !== undefined) {
      const formData = new FormData();
      formData.append("display_image", croppedImage);
      formData.append("id", currentUser.id);

      updateDisplayImage(formData)
        .then(() => {
          history.push("/settings/profile");
          window.location.reload();
        })
        .catch((error) => console.log("api errors:", error));
    } else {
      console.log("Errors");
    }
  };

  return (
    <BaseModal showModal={true} modalSize={"small"}>
      <Wizard>
        <ImageUploader
          uploaderTitle={"Change display image"}
          selectImage={setDisplayImage}
          unselectImage={() => setDisplayImage(null)}
        />
        <ImageCropper
          cropperModalTitle={"Crop display image"}
          selectedImage={displayImage}
          setCroppedThumbnail={setCroppedImage}
          handleSubmit={handleSubmit}
          cropShape={"round"}
          showBackButton={true}
          form={true}
        />
      </Wizard>
    </BaseModal>
  );
};
export default withRouter(DisplayImageWizard);
