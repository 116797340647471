import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import stylesheet from "./Posts.module.scss";
import Header from "../typography/Header";
import ColumnLayout from "../layout/ColumnLayout";
import Column from "../layout/Column";
import Post from "./Post";

const PostPreview = (props) => {
  const { posts } = props;

  return (
    <div className={stylesheet.communityPosts}>
      <div className={stylesheet.header}>
        <div className={stylesheet.title}>
          <Header level={2} size={"small"} message={"Community"} />
        </div>
        <div className={stylesheet.controls}>
          <Link to={`/posts`} className={stylesheet.commentLink}>
            <span className={stylesheet.label}>See more</span>
            <span className={`icon is-small ${stylesheet.icon}`}>
              <FontAwesomeIcon icon={["fa", "angle-right"]} />
            </span>
          </Link>
        </div>
      </div>

      <ColumnLayout>
        {posts.map((post) => (
          <Column key={post.id}>
            <Post post={post} />
          </Column>
        ))}
      </ColumnLayout>
    </div>
  );
};
PostPreview.propTypes = {
  posts: PropTypes.array,
};
export default PostPreview;
