import axios from "axios";
const api = axios.create({
  withCredentials: true,
});
const API_URL = "/api/v1";

// Fetch the logged in user's feed.
export function fetchFeed(params) {
  return api.get(`${API_URL}/feed_items/following_feed`, params, {
    withCredentials: true,
  });
}
