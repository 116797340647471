import React, { memo } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import stylesheet from "./Pages.module.scss";
import Hero from "../common/layout/Hero";
import Header from "../common/typography/Header";
import SocialLinks from "./SocialLinks";

const WelcomeHero = memo((props) => {
  const { feature, currentUser } = props;

  const headlineInfo = (
    <div className={stylesheet.welcomeHero}>
      <Header
        level={2}
        size={"small"}
        message={"Welcome to Animatik"}
        styles={{ marginBottom: "0.25rem", color: "white" }}
      />
      {currentUser.id ? (
        <div>
          We'd love to hear from you! Share feedback and bugs on our{" "}
          <a href="https://discord.gg/TfS83e7" target="_blank">
            Discord
          </a>
          .
        </div>
      ) : (
        <div>
          <div>
            We're on a mission to build the best community for artists to share,
            grow, and inspire.
          </div>
          <Link to={`/signin`}>Sign in</Link> or{" "}
          <Link to={`/join`}>Join now</Link>!
        </div>
      )}
      <div className={stylesheet.welcomeHeroSocials}>
        <SocialLinks />
      </div>
    </div>
  );

  return <Hero feature={feature} bottomLeftContent={headlineInfo} />;
});
WelcomeHero.propTypes = {
  feature: PropTypes.object,
  currentUser: PropTypes.object,
};
export default WelcomeHero;
