import React, { useContext, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import stylesheet from "./Navigation.module.scss";
import { destroySession } from "../../util/SessionsAPI.js";
import { globalContext } from "../../util/globalContext.js";

import SearchBar from "./SearchBar.jsx";
import UserMenu from "./UserMenu";
import HamburgerMenu from "./HamburgerMenu";
import CreateDropdown from "./CreateDropdown";
import OnboardingDropdown from "./OnboardingDropdown";
import MoreLinks from "./MoreLinks";
import NotificationList from "../notifications/NotificationList";

const Navbar = (props) => {
  const { handleLogout } = props;

  const globalState = useContext(globalContext);
  const currentUser = globalState.currentUser;
  const isAdmin = globalState.isLoggedIn && currentUser.is_admin;

  const history = useHistory();

  const handleSignOut = () => {
    destroySession()
      .then((response) => {
        if (response.data.logged_out) {
          handleLogout();
          globalState.showToast("You signed out—see you later!");
          history.push("/");
        }
      })
      .catch((error) => console.log("api errors:", error));
  };

  const userControls = (
    <div className="buttons">
      {!currentUser.onboarded_at && (
        <OnboardingDropdown
          onboarding={globalState.onboarding}
          currentUser={currentUser}
          history={history}
        />
      )}
      <NotificationList />
      <CreateDropdown />
      <UserMenu
        currentUser={globalState.currentUser}
        handleSignOut={handleSignOut}
      />
    </div>
  );

  const guestControls = (
    <div className="buttons">
      <Link to="/signin" className="button is-light is-rounded">
        Sign in
      </Link>
      <Link to="/join" className="button is-primary is-rounded">
        Join now
      </Link>
    </div>
  );

  const loadingState = (
    <div>
      <button className="button is-rounded is-loading">Loading</button>
    </div>
  );

  return (
    <Fragment>
      <div id="navbar" className={stylesheet.stickyWrapper}>
        <nav
          className={stylesheet.navBar}
          role="navigation"
          aria-label="main navigation"
        >
          <div className={stylesheet.widthWrapper}>
            <div className={stylesheet.flexWrapper}>
              <div className={stylesheet.logo}>
                <Link to="/">
                  <span className={stylesheet.brandName}>Animatik</span>
                  <span className={stylesheet.mobileBrandName}>A</span>
                </Link>
              </div>
              <div className={stylesheet.searchBar}>
                <SearchBar />
              </div>
              <div className={stylesheet.mobileMenu}>
                <HamburgerMenu
                  currentUser={globalState.currentUser}
                  handleSignOut={handleSignOut}
                />
              </div>
              <div className={stylesheet.navItem}>
                <Link className={stylesheet.siteLink} to="/">
                  <span className={stylesheet.linkIcon}>
                    <FontAwesomeIcon icon={["fa", "compass"]} />
                  </span>
                  <span>Explore</span>
                </Link>
              </div>
              {globalState.isLoggedIn && (
                <div className={stylesheet.navItem}>
                  <Link className={stylesheet.siteLink} to="/following">
                    <span className={stylesheet.linkIcon}>
                      <FontAwesomeIcon icon={["fa", "bolt"]} />
                    </span>
                    <span>Following</span>
                  </Link>
                </div>
              )}
              {isAdmin && (
                <div className={stylesheet.navItem}>
                  <Link className={stylesheet.siteLink} to="/admin/invites">
                    <span className={stylesheet.linkIcon}>
                      <FontAwesomeIcon icon={["fa", "toolbox"]} />
                    </span>
                    <span>Admin</span>
                  </Link>
                </div>
              )}
              <div className={stylesheet.navItem}>
                <MoreLinks />
              </div>
              <div className={stylesheet.controls}>
                {globalState.isLoading
                  ? loadingState
                  : globalState.isLoggedIn
                  ? userControls
                  : guestControls}
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className={stylesheet.shadow}></div>
    </Fragment>
  );
};
export default Navbar;
