import React from "react";
import ReactSlider from "react-slider";
import PropTypes from "prop-types";
import stylesheet from "./Controls.module.scss";

const Slider = (props) => {
  const { value, min, max, step, onChange } = props;

  return (
    <ReactSlider
      className={stylesheet.sliderContainer}
      thumbClassName={stylesheet.sliderThumb}
      trackClassName={"track"}
      renderThumb={(props, state) => <div {...props}></div>}
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={onChange}
    />
  );
};
Slider.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
export default Slider;
