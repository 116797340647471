import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Loading from "../../pages/Loading";
import PaginatedGallery from "../../common/gallery/PaginatedGallery";
import { loadArtwork } from "../SearchAPI";

const ArtworkResults = (props) => {
  const { artwork, pagination, query, isLoading } = props;

  /* Fetches the next set of artwork via callback */
  const loadMoreArtwork = useCallback((page) => {
    const requestParams = {
      params: {
        query: query,
        page: page,
      },
    };
    return loadArtwork(requestParams);
  });

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        artwork && (
          <PaginatedGallery
            artwork={artwork}
            page={pagination.page}
            pages={pagination.pageCount}
            fetchMethod={loadMoreArtwork}
          />
        )
      )}
    </div>
  );
};

ArtworkResults.propTypes = {
  artwork: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired,
};
export default ArtworkResults;
