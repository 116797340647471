import React, { useState, useEffect, Fragment, useRef } from "react";
import { fetchUnreadNotifications, markAsRead } from "./NotificationsAPI";
import { Link } from "react-router-dom";

import stylesheet from "./Notifications.module.scss";
import DropdownMenu from "../common/dropdown/DropdownMenu";
import DropdownHeading from "../common/dropdown/DropdownHeading";
import DropdownDivider from "../common/dropdown/DropdownDivider";
import DropdownItem from "../common/dropdown/DropdownItem";
import Button from "../common/buttons/Button";
import Notification from "./Notification";

const NotificationList = (props) => {
  /* Handle showing and hiding the dropdown */
  const [isLoading, setLoading] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [unreadNotifications, setNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);

  const hasAnyNotifications =
    unreadNotifications.length + readNotifications.length > 0;
  const hasOldAndNewNotifications =
    unreadNotifications.length > 0 && readNotifications.length > 0;
  const refreshNotifications = useRef(null);

  useEffect(() => {
    fetchNewNotifications();

    return () => clearTimeout(refreshNotifications.current);
  }, []);

  useEffect(() => {
    if (unreadNotifications.length > 0) {
      if (document.title.includes("(")) {
        document.title = document.title.replace(
          / *\([^)]*\) */g,
          "(" + unreadNotifications.length + ") "
        );
      } else {
        document.title =
          "(" + unreadNotifications.length + ") " + document.title;
      }
    } else {
      document.title = document.title.replace(/ *\([^)]*\) */g, "");
    }
  }, [unreadNotifications]);

  const fetchNewNotifications = () => {
    fetchUnreadNotifications()
      .then((response) => {
        if (response.data) {
          setNotifications(response.data.unread);
          setReadNotifications(response.data.read);

          setLoading(false);

          refreshNotifications.current = setTimeout(
            fetchNewNotifications,
            30000
          );
        }
      })
      .catch((error) => {});
  };

  const closeHandler = () => {
    setShowDropdown(false);

    if (unreadNotifications.length > 0) {
      markAsRead()
        .then((response) => {
          if (response.data.success) {
            setReadNotifications([
              ...unreadNotifications,
              ...readNotifications,
            ]);
            setNotifications([]);
          }
        })
        .catch((error) => {});
    }
  };

  const newNotifications = unreadNotifications.map((notification) => (
    <Notification
      key={notification.id}
      notification={notification}
      onClick={closeHandler}
    />
  ));

  const previousNotifications = readNotifications.map((notification) => (
    <Notification
      key={notification.id}
      notification={notification}
      onClick={closeHandler}
    />
  ));

  const allNotifications = (
    <Fragment>
      {newNotifications}
      {hasOldAndNewNotifications && (
        <Fragment>
          <DropdownDivider />
          <DropdownHeading
            label={"Earlier"}
            classes={stylesheet.activityHeader}
          />
        </Fragment>
      )}
      {previousNotifications}
    </Fragment>
  );

  const emptyState = (
    <DropdownItem type={"custom"}>
      <div className={stylesheet.emptyState}>
        Engagement with your account will appear here.
      </div>
    </DropdownItem>
  );

  return (
    <DropdownMenu
      id={"notification-list"}
      showDropdown={showDropdown}
      hideDropdown={closeHandler}
      position={"right"}
      classes={stylesheet.notificationDropdown}
      trigger={
        <div className={stylesheet.notificationButton}>
          {unreadNotifications.length > 0 && (
            <div className={stylesheet.badge}>{unreadNotifications.length}</div>
          )}
          <Button
            type={"button"}
            kind={"tertiary"}
            isCircle={true}
            icon={"bell"}
            onClick={() => setShowDropdown(!showDropdown)}
          />
        </div>
      }
      footer={
        <Link className="link" to={`/notifications`} onClick={closeHandler}>
          View all notifications
        </Link>
      }
    >
      {!isLoading && (
        <Fragment>
          <DropdownHeading
            label={newNotifications.length > 0 ? "New" : "Notifications"}
            classes={stylesheet.activityHeader}
          />
          {hasAnyNotifications ? allNotifications : emptyState}
        </Fragment>
      )}
    </DropdownMenu>
  );
};
export default NotificationList;
