import React from "react";
import ColumnLayout from "../common/layout/ColumnLayout";
import Column from "../common/layout/Column";
import FeedPost from "../feed/FeedPost";

const PostMasonry = (props) => {
  const { posts, currentUser, showToast } = props;

  const col1 = [],
    col2 = [],
    col3 = [];

  posts.forEach((item, i) => {
    var index = i + 1;
    if (index % 3 === 0) {
      col3.push(item);
    } else if (index % 2 === 0) {
      col2.push(item);
    } else {
      col1.push(item);
    }
  });

  const firstColumn = col1.map((post) => (
    <FeedPost
      key={post.id}
      kind={"post"}
      objectData={post}
      currentUser={currentUser}
      showToast={showToast}
      showCommentLink={true}
    />
  ));

  const secondColumn = col2.map((post) => (
    <FeedPost
      key={post.id}
      kind={"post"}
      objectData={post}
      currentUser={currentUser}
      showToast={showToast}
      showCommentLink={true}
    />
  ));

  const thirdColumn = col3.map((post) => (
    <FeedPost
      key={post.id}
      kind={"post"}
      objectData={post}
      currentUser={currentUser}
      showToast={showToast}
      showCommentLink={true}
    />
  ));

  return (
    <ColumnLayout>
      <Column>{firstColumn}</Column>
      <Column>{secondColumn}</Column>
      <Column>{thirdColumn}</Column>
    </ColumnLayout>
  );
};
export default PostMasonry;
