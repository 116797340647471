import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function InputError(props) {
  const { error } = props;

  const errorMessage = (
    <p className="help is-danger">
      <span className="icon is-small">
        <FontAwesomeIcon icon={["fa", "times-circle"]} />
      </span>
      <span className="is-error-subtext">{error}</span>
    </p>
  );

  return <Fragment>{error ? errorMessage : ""}</Fragment>;
}
