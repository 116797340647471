import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import stylesheet from "./Notifications.module.scss";
import DisplayImage from "../common/users/DisplayImage";
import DropdownItem from "../common/dropdown/DropdownItem";

const Notification = (props) => {
  const { notification, onClick } = props;
  const notificationProperties = {};
  const action = notification.action;

  if (!notification.notifiable || !notification.actor) {
    return null;
  }

  const LIKE = "liked";
  const POST_LIKE = "post_like";
  const COMMENT = "commented";
  const PROFILE_COMMENT = "profile_commented";
  const POST_COMMENT = "post_commented";
  const REPLY = "replied";
  const FOLLOW = "followed";
  const COLLECT = "collected";
  const BADGE = "badge_earned";

  const truncate = (input, length) => {
    if (input.length > length) {
      return input.substring(0, length) + "...";
    }
    return input;
  };

  if (action === FOLLOW) {
    notificationProperties.classes = stylesheet.follow;
    notificationProperties.icon = "plus";
    notificationProperties.link = "/" + notification.actor.username;
    notificationProperties.description = "followed you.";
  } else if (action === LIKE) {
    notificationProperties.classes = stylesheet.like;
    notificationProperties.icon = "heart";
    notificationProperties.link = "/art/" + notification.notifiable.id;
    notificationProperties.description = (
      <Fragment>
        liked your work <em>{notification.notifiable.title}</em>.
      </Fragment>
    );
  } else if (action === POST_LIKE) {
    notificationProperties.classes = stylesheet.like;
    notificationProperties.icon = "heart";
    notificationProperties.link = "/posts/" + notification.notifiable.id;
    notificationProperties.description = (
      <Fragment>
        liked your post{" "}
        <em>
          {notification.notifiable.title
            ? truncate(notification.notifiable.title, 75)
            : truncate(notification.notifiable.body, 75)}
        </em>
      </Fragment>
    );
  } else if (action === COMMENT) {
    notificationProperties.classes = stylesheet.comment;
    notificationProperties.icon = "comment";
    notificationProperties.link =
      "/art/" + notification.notifiable.id + "#comments";
    notificationProperties.description = (
      <Fragment>
        commented on your work <em>{notification.notifiable.title}</em>.
      </Fragment>
    );
  } else if (action === POST_COMMENT) {
    notificationProperties.classes = stylesheet.comment;
    notificationProperties.icon = "comment";
    notificationProperties.link =
      "/posts/" + notification.notifiable.id + "#comments";
    notificationProperties.description = (
      <Fragment>
        commented on your post{" "}
        <em>
          {notification.notifiable.title
            ? truncate(notification.notifiable.title, 75)
            : truncate(notification.notifiable.body, 75)}
        </em>
        .
      </Fragment>
    );
  } else if (action === PROFILE_COMMENT) {
    notificationProperties.classes = stylesheet.comment;
    notificationProperties.icon = "comment";
    notificationProperties.link =
      "/" + notification.notifiable.username + "#comments";
    notificationProperties.description = "commented on your profile.";
  } else if (action === REPLY) {
    notificationProperties.classes = stylesheet.comment;
    notificationProperties.icon = "comment";

    if (notification.notifiable) {
      if (notification.notifiable.commentable_type === "User") {
        notificationProperties.link = "/" + notification.notes + "#comments";
      } else if (notification.notifiable.commentable_type === "Post") {
        notificationProperties.link =
          "/posts/" + notification.notifiable.commentable_id + "#comments";
      } else {
        notificationProperties.link =
          "/art/" + notification.notifiable.commentable_id + "#comments";
      }
    } else {
      notificationProperties.link = "/not-found";
    }

    notificationProperties.description = "replied to a thread you're in.";
  } else if (action === COLLECT) {
    notificationProperties.classes = stylesheet.collect;
    notificationProperties.icon = "folder-open";
    notificationProperties.link = "/collections/" + notification.notifiable.id;
    notificationProperties.description = (
      <Fragment>
        added <em>{notification.notes}</em> to the collection{" "}
        <em>{notification.notifiable.name}</em>
      </Fragment>
    );
  } else if (action === BADGE) {
    notificationProperties.classes = stylesheet.follow;
    notificationProperties.customIcon = (
      <Fragment>
        <div className={stylesheet.badgeIcon}>
          <span className={`icon ${stylesheet.icon}`}>
            <FontAwesomeIcon icon={["fa", "award"]} />
          </span>
        </div>
      </Fragment>
    );
    notificationProperties.link = "/" + notification.actor.username + "/about";
    notificationProperties.fullDescription =
      "You earned a new badge, congrats!";
  }

  /* Perform some date calculation to determine which timestamp to show */
  let notificationDate = new Date(notification.created_at);
  let threeDaysAgo = new Date();
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
  notificationProperties.timestamp =
    notificationDate < threeDaysAgo
      ? notification.created_at
      : notification.created_at_descriptive;

  const notificationIcon = (
    <div
      className={`${stylesheet.notifTypeIcon} ${notificationProperties.classes}`}
    >
      <span className={`icon ${stylesheet.icon}`}>
        <FontAwesomeIcon icon={["fa", notificationProperties.icon]} />
      </span>
    </div>
  );

  return (
    <DropdownItem
      type={"notification"}
      linkTo={notificationProperties.link}
      onClick={onClick}
    >
      <div className={stylesheet.notificationItem}>
        <div className={stylesheet.actor}>
          {notificationProperties.customIcon ? (
            notificationProperties.customIcon
          ) : (
            <Fragment>
              {notificationIcon}
              <DisplayImage user={notification.actor} link={false} size={35} />
            </Fragment>
          )}
        </div>
        <div className={stylesheet.description}>
          {notificationProperties.fullDescription ? (
            notificationProperties.fullDescription
          ) : (
            <Fragment>
              <strong>{notification.actor.display_name}</strong>{" "}
              {notificationProperties.description}
            </Fragment>
          )}
          <p className={`${stylesheet.timestamp}`}>
            {notificationProperties.timestamp}
          </p>
        </div>
      </div>
    </DropdownItem>
  );
};
export default Notification;
