import React from 'react';

export default function ButtonsBar(props) {
  const { children, alignment, spacing } = props;

  let styles = "buttons";

  if (alignment) {
    styles += " is-" + alignment;
  }
  if (spacing === "small") {
    styles += " has-small-spacing";
  }

  return (
    <div className={styles}>
      {children}
    </div>
  );
}