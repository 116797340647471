import React from "react";

const globalContext = React.createContext({
  isLoggedIn: false,
  isLoading: true,
  currentUser: {},
  onboarding: {},
});

export {
  globalContext, // Export it so it can be used by other Components
};
