import React from "react";
import PropTypes from "prop-types";
import stylesheet from "./Tabs.module.scss";

const TabBar = (props) => {
  const { classes, children } = props;

  return (
    <div className={`tabs ${stylesheet.tabs} ${classes ? classes : ""}`}>
      <ul>{children}</ul>
    </div>
  );
};
TabBar.propTypes = {
  classes: PropTypes.string,
};
export default TabBar;
