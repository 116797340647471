import React, { useState, useEffect, Fragment, useCallback } from "react";
import { useLocation } from "react-router";
import { fetchAllArtwork } from "../artwork/ArtworkAPI";

import stylesheet from "./Pages.module.scss";
import Loading from "./Loading";
import WelcomeHero from "./WelcomeHero";
import WelcomeModal from "./WelcomeModal";
import Gallery from "../common/gallery/Gallery";
import SortDropdown from "../common/gallery/SortDropdown";
import Section from "../common/layout/Section";
import MoreButton from "../common/MoreButton";
import Button from "../common/buttons/Button";
import PostPreview from "../common/posts/PostPreview";
import FadeInSection from "../hooks/FadeInSection";

const Explore = (props) => {
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("Newest");
  const [pages, setPages] = useState();
  const [isFirstPageLoad, toggleFirstPageLoad] = useState(true);
  const [isFeaturedLoaded, toggleFeatureLoaded] = useState(false);

  const [feature, setFeature] = useState(null);
  const [artwork, setArtwork] = useState([]);
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);

  const { currentUser } = props;

  const location = useLocation();
  const showWelcomeModal = location.state
    ? location.state.showWelcomeModal
    : false;

  useEffect(() => {
    document.title = "Explore / Animatik";
    window.scroll(0, 0);
  }, []);

  /* This effect is triggered on page load or when the order is changed. */
  useEffect(() => {
    const startingPage = {
      params: {
        page: 1,
        order: order,
      },
    };
    buildExplorePage(startingPage);
  }, [order]);

  /* This effect is only triggered when the user triggers "Load more". It will not be called on page 1/initial page load. */
  useEffect(() => {
    if (page === 1) return;
    const startingPage = {
      params: {
        page: page,
        order: order,
      },
    };
    setTimeout(
      function () {
        loadMoreArt(startingPage);
      }.bind(this),
      300
    );
  }, [page]);

  const buildExplorePage = useCallback((page) => {
    fetchAllArtwork(page)
      .then((response) => {
        if (response.data.artwork) {
          setArtwork(response.data.artwork);

          if (!isFeaturedLoaded) {
            setFeature(response.data.feature);
            setPosts(response.data.posts);
          }
          setPage(parseInt(response.data.page));
          setPages(parseInt(response.data.pages));
        }
        setIsLoading(false);
        setShowSpinner(false);
        toggleFeatureLoaded(true);
      })
      .catch((error) => console.log("api errors:", error));
  });

  const loadMoreArt = useCallback((page) => {
    fetchAllArtwork(page)
      .then((response) => {
        if (response.data) {
          const newArray = [...artwork, ...response.data.artwork]; // This is how you get quasi-infinite scroll
          setArtwork(newArray);

          setPage(parseInt(response.data.page));
          setPages(parseInt(response.data.pages));
          setIsLoading(false);
          setShowSpinner(false);
          toggleFirstPageLoad(false);
        } else {
          console.log("ERROR");
        }
      })
      .catch((error) => console.log("api errors:", error));
  });

  const handlePageChange = () => {
    setShowSpinner(true);
    if (page < pages) {
      setPage((page) => page + 1);
    }
  };

  return (
    <Fragment>
      {showWelcomeModal && <WelcomeModal />}
      <WelcomeHero feature={feature} currentUser={currentUser} />
      <Section>
        {isLoading ? (
          <Loading />
        ) : (
          artwork && (
            <Fragment>
              <Gallery
                title={"Explore"}
                works={artwork}
                mosaic={true}
                controls={
                  <SortDropdown title={order} setSortOption={setOrder} />
                }
              />
              <FadeInSection>
                <MoreButton
                  pages={pages}
                  page={page}
                  showSpinner={showSpinner}
                  onClick={handlePageChange}
                  classes={isFirstPageLoad ? stylesheet.hoveringMoreButton : ""}
                  customButton={
                    isFirstPageLoad && (
                      <Button
                        type="button"
                        kind="primary"
                        label={"Explore more art"}
                        icon={"rocket"}
                        showSpinner={showSpinner}
                        onClick={handlePageChange}
                        classes={`${stylesheet.exporeMoreArt}`}
                      />
                    )
                  }
                />
              </FadeInSection>
              {posts && <PostPreview posts={posts} />}
            </Fragment>
          )
        )}
      </Section>
    </Fragment>
  );
};
export default Explore;
