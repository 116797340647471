import React, {
  useReducer,
  useContext,
  useState,
  useEffect,
  useCallback,
  Fragment,
} from "react";
import { globalContext } from "../../util/globalContext.js";
import { fetchFeed } from "./FeedAPI.js";
import { Link } from "react-router-dom";

import stylesheet from "./Feed.module.scss";
import Loading from "../pages/Loading";
import Section from "../common/layout/Section";
import ColumnLayout from "../common/layout/ColumnLayout";
import Column from "../common/layout/Column";
import MoreButton from "../common/MoreButton";
import FeedPost from "./FeedPost";
import Explore from "./Explore";
import Recommended from "./Recommended";

const initialState = {
  feed: [],
  page: 1,
  pages: null,
  art: [],
  recommended: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_FEED":
      return { ...state, ...action.data };
    case "LOAD_MORE":
      return {
        ...state,
        feed: [...state.feed, ...action.data],
      };
    case "INCREMENT_PAGE":
      return {
        ...state,
        page: state.page + 1,
      };
    default:
      return state;
  }
};

const Feed = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const globalState = useContext(globalContext);
  const currentUser = globalState.currentUser;

  const { feed, page, pages, art, recommended } = state;

  useEffect(() => {
    document.title = "Following / Animatik";

    const params = {
      params: {
        page: 1,
      },
    };

    buildFeed(params, false);
  }, []);

  /* This effect is only triggered when the user triggers "Load more". It will not be called on page 1/initial page load. */
  useEffect(() => {
    if (page === 1) return;
    const params = {
      params: {
        page: page,
      },
    };

    setTimeout(
      function () {
        buildFeed(params, true);
      }.bind(this),
      300
    );
  }, [page]);

  const buildFeed = useCallback((params, isLoadMore) => {
    fetchFeed(params)
      .then((response) => {
        if (response.data) {
          if (isLoadMore) {
            dispatch({
              type: "LOAD_MORE",
              data: response.data.feed,
            });
          } else {
            dispatch({
              type: "LOAD_FEED",
              data: {
                feed: response.data.feed,
                page: parseInt(response.data.page),
                pages: parseInt(response.data.pages),
                art: response.data.art,
                recommended: response.data.recommended,
              },
            });
          }
          setLoading(false);
          setShowSpinner(false);
        } else {
          globalState.showToast("Something went wrong. Refresh and try again");
        }
      })
      .catch(() =>
        globalState.showToast("Something went wrong. Refresh and try again")
      );
  });

  const handlePageChange = () => {
    setShowSpinner(true);
    if (page < pages) {
      dispatch({
        type: "INCREMENT_PAGE",
      });
    }
  };

  const feedList = feed.map((feedItem, index) => (
    <FeedPost
      kind={feedItem.item_type.toLowerCase()}
      key={feedItem.parent_id + "-" + index}
      objectData={feedItem}
      showCommentLink={true}
      currentUser={currentUser}
      showToast={globalState.showToast}
    />
  ));

  const placeholder = (
    <Fragment>
      <div className={stylesheet.feedPlaceholder}>
        <div className={stylesheet.placeholderText}>
          <p>
            A feed of artists you follow will appear here, but it's looking a
            bit lonely at the moment. Check out these members to follow:
          </p>
        </div>
        <Recommended isLoading={isLoading} recommended={recommended} />
      </div>
    </Fragment>
  );

  const feedLayout = (
    <ColumnLayout>
      <Column width={7}>
        {isLoading ? (
          <Loading message={"Building your feed..."} />
        ) : (
          <Fragment>
            {feedList}

            <div
              style={{
                maxWidth: "512px",
                marginLeft: "auto",
              }}
            >
              <MoreButton
                showSpinner={showSpinner}
                onClick={handlePageChange}
                pages={pages}
                page={page}
              />
            </div>
          </Fragment>
        )}
      </Column>
      <Column>
        <Recommended
          isLoading={isLoading}
          recommended={recommended}
          showHeader={true}
        />
        <Explore isLoading={isLoading} art={art} />
      </Column>
    </ColumnLayout>
  );

  return (
    <Section showBackground={true}>
      {feed.length > 0 ? feedLayout : placeholder}
    </Section>
  );
};
export default Feed;
