import React, { memo, Fragment } from "react";
import { useHistory } from "react-router";
import stylesheet from "./Feed.module.scss";
import Header from "../common/typography/Header";
import Thumbnail from "../common/gallery/Thumbnail";
import Button from "../common/buttons/Button";
import Loading from "../pages/Loading";

const Explore = memo((props) => {
  const { art, isLoading } = props;
  const history = useHistory();

  const exploreButton = () => {
    history.push({
      pathname: `/`,
    });
  };

  const artGrid = art.map((artwork) => (
    <Thumbnail key={"explore-" + artwork.id} artwork={artwork} />
  ));

  return (
    <div className={`${stylesheet.exploreSection} get-sticky`}>
      <Header
        level={2}
        size={"small"}
        message={"Explore more art"}
        classes={stylesheet.exploreHeader}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={stylesheet.exploreGrid}>{artGrid}</div>
          <div className={stylesheet.exploreButton}>
            <Button
              type={"button"}
              kind={"secondary"}
              label={"Explore"}
              onClick={exploreButton}
              iconRight={"angle-right"}
            />
          </div>
          <div className={stylesheet.fadeGradient}></div>
        </Fragment>
      )}
    </div>
  );
});
export default Explore;
