import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { fetchCollectionsByUsername } from "../UserAPI";
import stylesheet from "./Profile.module.scss";
import Button from "../../common/buttons/Button";
import PaginatedCollections from "../../common/collections/PaginatedCollections";

const ProfileCollections = (props) => {
  const {
    collections,
    collectionsPagination,
    collectionsOwner,
    currentUser,
    location,
    history,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      collectionsOwner.display_name + "'s Collections / Animatik";
  }, []);

  /* Fetches the next set of artwork via callback */
  const loadMoreCollections = useCallback((page) => {
    const pageParam = {
      params: {
        page: page,
      },
    };
    return fetchCollectionsByUsername(collectionsOwner.username, pageParam);
  });

  const createNewCollection = () => {
    history.push({
      pathname: `/new/collection`,
      state: { background: location },
    });
  };

  return (
    <PaginatedCollections
      title={collectionsOwner.display_name + "'s Collections"}
      collections={collections}
      page={collectionsPagination.page}
      pages={collectionsPagination.pages}
      fetchMethod={loadMoreCollections}
      controls={
        currentUser.id === collectionsOwner.id && (
          <Button
            kind={"secondary"}
            label={"New collection"}
            type={"button"}
            icon={"pencil-alt"}
            onClick={createNewCollection}
            classes={stylesheet.newCollection}
          />
        )
      }
    />
  );
};

ProfileCollections.propTypes = {
  collections: PropTypes.array,
  collectionsPagination: PropTypes.object,
  collectionsOwner: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  currentUser: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};
export default ProfileCollections;
