import React, {
  useReducer,
  useContext,
  useState,
  useEffect,
  Fragment,
} from "react";
import PropTypes from "prop-types";
import stylesheet from "./Profile.module.scss";
import { useLocation } from "react-router";
import { withRouter, Route } from "react-router-dom";

import { fetchUser } from "../UserAPI";

import Section from "../../common/layout/Section";
import DisplayImage from "../../common/users/DisplayImage";
import TabBar from "../../common/tabs/TabBar";
import Tab from "../../common/tabs/Tab";
import About from "./About";
import ProfileCover from "./ProfileCover";
import ProfileMain from "./ProfileMain";
import ProfileGallery from "./ProfileGallery";
import ProfileCollections from "./ProfileCollections";
import FollowButton from "../../common/relationships/FollowButton";
import Loading from "../../pages/Loading";

const initialState = {
  user: [],
  profile_cover: null,
  artwork: [],
  artwork_pagination: {},
  collections: [],
  collections_pagination: {},
  comments: {},
  posts: {},
  current_user_follows: false,
  follow_count: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_STATE":
      return { ...state, ...action.data };
    case "UPDATE_FOLLOWER_DETAILS":
      return {
        ...state,
        current_user_follows: !state.current_user_follows,
        follow_count: action.data,
      };
    default:
      return state;
  }
};

const Profile = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [activeStep, setActiveStep] = useState("main");
  const [isLoading, setLoading] = useState(false);

  const location = useLocation();
  const currentPathName = location.pathname;

  /* Tab name constants to determine active step and links */
  const mainTabName = "main";
  const galleryTabName = "gallery";
  const collectionsTabName = "collections";
  const aboutTabName = "about";

  const { path, currentUser, showToast } = props;

  const {
    user,
    profile_cover,
    artwork,
    artwork_pagination,
    collections,
    collections_pagination,
    comments,
    posts,
    current_user_follows,
    follow_count,
  } = state;

  useEffect(() => {
    document.title = "Animatik";
    dispatch({
      type: "LOAD_STATE",
      data: initialState,
    });

    fetchUserDetails();

    /* On load, use the path to determine which step should be set to active. */
    if (currentPathName.includes(galleryTabName)) {
      setActiveStep(galleryTabName);
    } else if (currentPathName.includes(collectionsTabName)) {
      setActiveStep(collectionsTabName);
    } else if (currentPathName.includes(aboutTabName)) {
      setActiveStep(aboutTabName);
    } else {
      setActiveStep(mainTabName);
    }
  }, [props.match.params.username]);

  const updateFollowDetails = (newFollowerCount) => {
    dispatch({
      type: "UPDATE_FOLLOWER_DETAILS",
      data: newFollowerCount,
    });
  };

  const fetchUserDetails = () => {
    setLoading(true);
    fetchUser(props.match.params.username)
      .then((response) => {
        if (response.data.user.id) {
          dispatch({
            type: "LOAD_STATE",
            data: {
              user: response.data.user,
              profile_cover: response.data.user.profile_cover,
              artwork: response.data.artwork.art,
              artwork_pagination: {
                page: response.data.artwork.page,
                pages: response.data.artwork.pages,
              },
              collections: response.data.collections.collections,
              collections_pagination: {
                page: response.data.collections.page,
                pages: response.data.collections.pages,
              },
              comments: response.data.comments,
              posts: response.data.posts,
              current_user_follows:
                response.data.user.is_followed_by_current_user,
              follow_count: response.data.user.followers_count,
            },
          });
          document.title = response.data.user.display_name + " / Animatik";
          setLoading(false);
        } else {
          props.history.push("/not-found");
        }
      })
      .catch((error) => {
        props.history.push("/not-found");
      });
  };

  return (
    <Fragment>
      <div className={stylesheet.userNavBar}>
        <div className={stylesheet.navContainer}>
          <div className={stylesheet.tabs}>
            <TabBar>
              <Tab
                userIcon={<DisplayImage user={user} size={35} link={false} />}
                label={user.display_name}
                isActive={activeStep === mainTabName}
                linkTo={"/" + props.match.params.username}
                onClick={() => setActiveStep(mainTabName)}
              />
              <Tab
                label={"Gallery"}
                isActive={activeStep === galleryTabName}
                linkTo={
                  "/" + props.match.params.username + "/" + galleryTabName
                }
                onClick={() => setActiveStep(galleryTabName)}
              />
              <Tab
                label={"Collections"}
                isActive={activeStep === collectionsTabName}
                linkTo={
                  "/" + props.match.params.username + "/" + collectionsTabName
                }
                onClick={() => setActiveStep(collectionsTabName)}
              />
              <Tab
                label={"About"}
                isActive={activeStep === aboutTabName}
                linkTo={"/" + props.match.params.username + "/" + aboutTabName}
                onClick={() => setActiveStep(aboutTabName)}
              />
            </TabBar>
          </div>
          <div className={stylesheet.moreOptions}>
            <FollowButton
              kind={"link"}
              isFollowing={current_user_follows}
              userId={user.id}
              updateUser={updateFollowDetails}
            />
          </div>
        </div>
      </div>
      <ProfileCover cover={profile_cover} />
      <Section>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <Route
              exact
              path={path + "/"}
              render={(props) => (
                <ProfileMain
                  user={user}
                  artwork={artwork}
                  comments={comments}
                  posts={posts}
                  currentUser={currentUser}
                  follow_count={follow_count}
                  current_user_follows={current_user_follows}
                  updateFollowDetails={updateFollowDetails}
                  profileLink={"/" + props.match.params.username}
                  aboutTabName={aboutTabName}
                  galleryTabName={galleryTabName}
                  setActiveStep={setActiveStep}
                  history={props.history}
                  showToast={showToast}
                  {...props}
                />
              )}
            />
            <Route
              path={`${path}/gallery`}
              render={(props) => (
                <ProfileGallery
                  artwork={artwork}
                  artworkPagination={artwork_pagination}
                  galleryOwner={user}
                  currentUser={currentUser}
                  location={location}
                  history={props.history}
                  {...props}
                />
              )}
            />
            <Route
              path={`${path}/collections`}
              render={(props) => (
                <ProfileCollections
                  collections={collections}
                  collectionsPagination={collections_pagination}
                  collectionsOwner={user}
                  currentUser={currentUser}
                  location={location}
                  history={props.history}
                  {...props}
                />
              )}
            />
            <Route
              path={`${path}/about`}
              render={(props) => <About user={user} {...props} />}
            />
          </Fragment>
        )}
      </Section>
    </Fragment>
  );
};
Profile.propTypes = {};
export default withRouter(Profile);
