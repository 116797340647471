import React from "react";

export default function Column(props) {
  const { id, children, width } = props;

  return (
    <div className={`column ${width ? "is-" + width : ""}`} id={id}>
      {children}
    </div>
  );
}
