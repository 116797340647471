import React, { useReducer, useContext, useState, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import {
  createCollection,
  fetchCollection,
  editCollection,
  deleteCollection,
  collect,
} from "./CollectionAPI";
import { validate, isValid } from "./CollectionValidator";
import { globalContext } from "../../util/globalContext.js";

import TextInput from "../common/TextInput";
import TextArea from "../common/TextArea";
import Switch from "../common/controls/Switch";
import BaseModal from "../common/modal/BaseModal";
import ModalBody from "../common/modal/ModalBody";
import ButtonsBar from "../common/modal/ButtonsBar";
import ConfirmModal from "../common/modal/ConfirmModal";
import PrimaryButton from "../common/buttons/PrimaryButton";

const initialState = {
  id: "",
  name: "",
  desc: "",
  secret: false,
  showConfirmModal: false,
};

const errorState = {
  nameError: "",
  descError: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_CONFIRM_MODAL":
      return { ...state, showConfirmModal: !state.showConfirmModal };
    case "LOAD_STATE":
      return { ...state, ...action.data };
    case "UPDATE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};

function errorReducer(state, { field, value }) {
  return {
    ...state,
    [field]: value,
  };
}

function NewCollection(props) {
  /* State management for collection input and spinner */
  const [state, dispatch] = useReducer(reducer, initialState);
  const [errors, dispatchErrors] = useReducer(errorReducer, errorState);
  const [showSpinner, setShowSpinner] = useState(false);

  /* Global state to access toasts and history to push the user back after submitting */
  const history = useHistory();
  const globalState = useContext(globalContext);

  /* State variables to set the individual input values */
  const { id, name, desc, secret, showConfirmModal } = state;
  const { nameError, descError } = errors;

  /* Conditional parameters */
  const paramId = props.match.params.id;
  const artworkToCollect = props.location.state
    ? props.location.state.artworkId
    : null;

  useEffect(() => {
    if (!paramId) return;
    document.title = "Edit collection";

    /* Fetch and preload the collection data */
    fetchCollection(paramId)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "LOAD_STATE",
            data: {
              id: response.data.collection.id,
              name: response.data.collection.name,
              desc: response.data.collection.desc
                ? response.data.collection.desc
                : "",
              secret: response.data.collection.secret,
            },
          });
        } else {
          console.log("ERROR");
        }
      })
      .catch((error) => {
        props.history.push("/not-found");
      });
  }, [paramId]);

  /* Update the reducer state when input fields are changed */
  const handleChange = (e) => {
    dispatch({
      type: "UPDATE",
      field: e.target.name,
      value: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
    /* Clear out any errors once the user starts typing again */
    dispatchErrors({
      field: e.target.name + "Error",
      value: "",
    });
  };

  /* Show and hide the confirm deletion modal*/
  const toggleConfirmModal = () => {
    dispatch({
      type: "TOGGLE_CONFIRM_MODAL",
    });
  };

  /* Delete the collection */
  const deleteHandler = () => {
    deleteCollection(id)
      .then((response) => {
        globalState.showToast("You deleted this collection.");
        history.push("/" + globalState.currentUser.username);
      })
      .catch((error) => {
        globalState.showToast("Something went wrong");
        history.goBack();
      });
  };

  /* Submit the new collection */
  const submitHandler = (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const validatedInput = validate(state);
    if (!isValid(validatedInput)) {
      dispatchErrors({
        field: "nameError",
        value: validatedInput.nameError,
      });
      dispatchErrors({
        field: "descError",
        value: validatedInput.descError,
      });
      setShowSpinner(false);
      return;
    }
    paramId ? _editCollection() : _createCollection();
  };

  const _createCollection = () => {
    createCollection(state)
      .then((response) => {
        if (response.data) {
          setTimeout(() => {
            if (artworkToCollect) {
              collect(response.data.id, artworkToCollect)
                .then((response) => {
                  globalState.showToast("Created and collected");
                  history.goBack();
                })
                .catch((error) => {
                  globalState.showToast("Something went wrong");
                  history.goBack();
                });
            } else {
              globalState.showToast("You created a new collection");
              history.push("/collections/" + response.data.id);
            }
          }, 400);
        } else {
          setShowSpinner(false);
          globalState.showToast("Something went wrong");
        }
      })
      .catch(() => {
        setShowSpinner(false);
        globalState.showToast("Something went wrong");
      });
  };

  const _editCollection = () => {
    editCollection(paramId, state)
      .then((response) => {
        if (response.data) {
          setTimeout(() => {
            globalState.showToast("You edited your collection");
            history.push(`/collections/${paramId}`);
            window.location.reload();
          }, 400);
        } else {
          setShowSpinner(false);
          globalState.showToast("Something went wrong");
        }
      })
      .catch(() => {
        setShowSpinner(false);
        globalState.showToast("Something went wrong");
      });
  };

  return (
    <div>
      <BaseModal showModal={true} modalSize="small">
        <ModalBody modalTitle={paramId ? "Edit collection" : "New collection"}>
          <div className="editor-inputs">
            <TextInput
              label="Name"
              name="name"
              value={name}
              error={nameError}
              onChange={handleChange}
              placeholder="Give your collection a name"
            />
            <TextArea
              label="Description (optional)"
              name="desc"
              value={desc}
              error={descError}
              onChange={handleChange}
              placeholder="Describe your collection"
              rows={4}
            />
            <Switch
              label="Keep this collection secret"
              name="secret"
              checked={secret}
              onChange={handleChange}
            />
          </div>
        </ModalBody>
        <ButtonsBar
          leftButtons={
            paramId && (
              <PrimaryButton
                label="Delete"
                type="button"
                style={["danger", "outlined"]}
                onClick={toggleConfirmModal}
              />
            )
          }
        >
          <PrimaryButton
            type="submit"
            label={
              paramId
                ? "Apply changes"
                : artworkToCollect
                ? "Save and collect"
                : "Save"
            }
            onClick={submitHandler}
            isLoading={showSpinner}
            type="button"
          />
        </ButtonsBar>
      </BaseModal>

      <ConfirmModal
        showConfirmModal={showConfirmModal}
        toggleModal={toggleConfirmModal}
        modalText={"Are you sure you want to delete this collection?"}
        confirmButtonLabel={"Yes, delete"}
        backButtonLabel={"No"}
        onConfirm={deleteHandler}
      />
    </div>
  );
}
export default withRouter(NewCollection);
