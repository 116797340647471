import React, { useState, useEffect, useCallback } from "react";

import { fetchUsers } from "./UserAPI";
import UserList from "../common/users/UserList";

function AllUsers(props) {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    document.title = "All users";
    const startingPage = {
      params: {
        page: page,
      },
    };
    _fetchUsers(startingPage);
  }, [page]);

  const _fetchUsers = useCallback((page) => {
    setShowSpinner(true);

    setTimeout(
      function () {
        fetchUsers(page)
          .then((response) => {
            if (response.data) {
              setUsers((users) => [...users, ...response.data.users]);
              setPage(parseInt(response.data.page));
              setPages(parseInt(response.data.pages));
              setIsLoading(false);
            } else {
              console.log("ERROR");
            }
            setShowSpinner(false);
          })
          .catch((error) => console.log("api errors:", error));
      }.bind(this),
      500
    );
  }, []);

  const handleLoadMore = () => {
    if (page < pages) {
      setPage(page + 1);
    }
  };

  return (
    <UserList
      title={"Members"}
      showModal={true}
      isLoading={isLoading}
      showSpinner={showSpinner}
      users={users}
      pages={pages}
      page={page}
      onLoadMore={handleLoadMore}
    />
  );
}
export default AllUsers;
