import axios from "axios";
const api = axios.create({
  withCredentials: true,
});
const API_URL = "/api/v1";

// Create a new comment for artwork
export function submitArtworkComment(resourceId, body) {
  return api.post(`${API_URL}/artworks/${resourceId}/comments`, body, {
    withCredentials: true,
  });
}

// Create a new comment on a profile
export function submitProfileComment(resourceId, body) {
  return api.post(`${API_URL}/users/${resourceId}/comments`, body, {
    withCredentials: true,
  });
}

// Create a new comment on a post
export function submitPostComment(resourceId, body) {
  return api.post(`${API_URL}/posts/${resourceId}/comments`, body, {
    withCredentials: true,
  });
}

// Create a reply
export function submitReply(resourceId, body) {
  return api.post(`${API_URL}/comments/${resourceId}/comments`, body, {
    withCredentials: true,
  });
}

// Like comment
export function likeResponse(id) {
  return api.post(`${API_URL}/comments/${id}/like`, {
    withCredentials: true,
  });
}

// Delete comment
export function deleteComment(id) {
  return api.delete(`${API_URL}/comments/${id}`, {
    withCredentials: true,
  });
}

// Load more comments
export function loadComments(id, body) {
  return api.get(`${API_URL}/artworks/${id}/load_comments`, body, {
    withCredentials: true,
  });
}

// Load more profile comments
export function loadProfileComments(id, body) {
  return api.get(`${API_URL}/users/${id}/load_comments`, body, {
    withCredentials: true,
  });
}

// Load more post comments
export function loadPostComments(id, body) {
  return api.get(`${API_URL}/posts/${id}/load_comments`, body, {
    withCredentials: true,
  });
}
