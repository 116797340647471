import React, { useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import stylesheet from "./Dropdown.module.scss";
import PropTypes from "prop-types";

const DropdownMenu = (props) => {
  const {
    id,
    trigger,
    footer,
    showDropdown,
    hideDropdown,
    children,
    position,
    styles,
    classes,
  } = props;

  /* Handle keyboard and click events dependent on dropdown state */
  useEffect(() => {
    if (showDropdown) {
      document.onkeydown = OnEscapeHandler;
      document.addEventListener("click", onClickOutHandler);
    } else {
      document.onkeydown = null;
    }
    return () => document.removeEventListener("click", onClickOutHandler);
  }, [showDropdown]);

  /* Hide the dropdown on click outside of the div */
  const onClickOutHandler = () => {
    if (!document.getElementById(id).contains(document.activeElement)) {
      hideDropdown();
    }
  };

  /* Hide the dropdown on escape key press */
  const OnEscapeHandler = (e) => {
    e = e || window.event;
    if (e.keyCode == "27") {
      hideDropdown();
    }
  };

  return (
    <div
      id={id}
      className={`dropdown is-dark-scheme is-active ${
        position === "right" ? "is-right" : ""
      } `}
    >
      {trigger}
      <CSSTransition
        in={showDropdown}
        timeout={350}
        classNames="alert"
        unmountOnExit
      >
        <div
          className={`dropdown-menu ${stylesheet.dropdownStyle} ${
            classes ? classes : ""
          }`}
          style={styles}
          role="menu"
        >
          <div className={`dropdown-content ${stylesheet.content}`}>
            {children}
          </div>
          {footer && <div className={stylesheet.footer}>{footer}</div>}
        </div>
      </CSSTransition>
    </div>
  );
};

DropdownMenu.propTypes = {
  id: PropTypes.string,
  trigger: PropTypes.object.isRequired,
  footer: PropTypes.object,
  showDropdown: PropTypes.bool.isRequired,
  hideDropdown: PropTypes.func.isRequired,
  position: PropTypes.string,
  styles: PropTypes.object,
  classes: PropTypes.string,
};
export default DropdownMenu;
