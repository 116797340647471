import axios from "axios";
const api = axios.create({
  withCredentials: true,
});
const API_URL = "/api/v1";

// Create a new collection
export function createCollection(body) {
  return api.post(`${API_URL}/collections`, body, {
    withCredentials: true,
  });
}

// View collection
export function fetchCollection(id, optionalPageParam) {
  return api.get(`${API_URL}/collections/${id}`, optionalPageParam, {
    withCredentials: true,
  });
}

// Edit collection
export function editCollection(id, body) {
  return api.patch(`${API_URL}/collections/${id}`, body, {
    withCredentials: true,
  });
}

// Delete collection
export function deleteCollection(id) {
  return api.delete(`${API_URL}/collections/${id}`, {
    withCredentials: true,
  });
}

// Adds or removes artwork from a collection based on its current state
export function collect(collectionId, artworkId) {
  return api.post(
    `${API_URL}/collections/${collectionId}/artworks/${artworkId}/collect`,
    {
      withCredentials: true,
    }
  );
}
