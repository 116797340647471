import React, { useReducer, useContext, useState, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { createPost, fetchPost, editPost, deletePost } from "./PostAPI";
import { validate, isValid } from "./PostValidator";

import TextInput from "../common/TextInput";
import TextArea from "../common/TextArea";
import Switch from "../common/controls/Switch";
import BaseModal from "../common/modal/BaseModal";
import ModalBody from "../common/modal/ModalBody";
import ButtonsBar from "../common/modal/ButtonsBar";
import ConfirmModal from "../common/modal/ConfirmModal";
import PrimaryButton from "../common/buttons/PrimaryButton";

const initialState = {
  id: "",
  title: "",
  body: "",
  showConfirmModal: false,
  title_error: "",
  body_error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_CONFIRM_MODAL":
      return { ...state, showConfirmModal: !state.showConfirmModal };
    case "LOAD_STATE":
      return { ...state, ...action.data };
    case "UPDATE":
      return {
        ...state,
        [action.field]: action.value,
        [action.field + "_error"]: "",
      };
    default:
      return state;
  }
};

function NewPost(props) {
  /* State management for collection input and spinner */
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showSpinner, setShowSpinner] = useState(false);

  /* State variables to set the individual input values */
  const { id, title, body, showConfirmModal, title_error, body_error } = state;

  /* Conditional parameters */
  const isEditingPost = typeof props.match.params.id !== "undefined";

  /* Props */
  const { showToast, currentUser, refreshOnboarding } = props;
  const history = useHistory();

  useEffect(() => {
    if (isEditingPost) {
      document.title = "Edit post";

      /* Fetch and preload the post data */
      fetchPost(props.match.params.id)
        .then((response) => {
          if (response.data) {
            dispatch({
              type: "LOAD_STATE",
              data: {
                id: response.data.post.id,
                title: response.data.post.title,
                body: response.data.post.body,
              },
            });
          }
        })
        .catch((error) => {
          props.history.push("/not-found");
        });
    }
  }, []);

  /* Update the reducer state when input fields are changed */
  const handleChange = (e) => {
    dispatch({
      type: "UPDATE",
      field: e.target.name,
      value: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  /* Use the validator to set errors when the user tabs or clicks out of the field. */
  const validateField = (e) => {
    var validatedInput = validate({ [e.target.name]: e.target.value });

    dispatch({
      type: "LOAD_STATE",
      data: validatedInput,
    });
  };

  /* Show and hide the confirm deletion modal*/
  const toggleConfirmModal = () => {
    dispatch({
      type: "TOGGLE_CONFIRM_MODAL",
    });
  };

  /* Delete the collection */
  const deleteHandler = () => {
    deletePost(id)
      .then((response) => {
        showToast("You deleted this post.");
        history.push("/" + currentUser.username);
      })
      .catch((error) => {
        showToast("Something went wrong");
        history.goBack();
      });
  };

  /* Submit the new collection */
  const submitHandler = (e) => {
    e.preventDefault();
    setShowSpinner(true);

    /* First run front-end validation before submitting the data to update. If there are errors, return. */
    const updatedProperties = {
      title: title,
      body: body,
    };

    var validatedInput = validate(updatedProperties);
    if (!isValid(validatedInput)) {
      dispatch({
        type: "LOAD_STATE",
        data: validatedInput,
      });
      setShowSpinner(false);
      return;
    }

    isEditingPost ? editThisPost() : createNewPost();
  };

  const createNewPost = () => {
    const postToSubmit = new FormData();
    postToSubmit.append("title", title);
    postToSubmit.append("body", body);

    createPost(postToSubmit)
      .then((response) => {
        if (response.data) {
          setTimeout(() => {
            history.push("/posts/" + response.data.id);

            /* If the current user hasn't been onboarded yet, fetch the latest onboarding status to update the task list. */
            if (!currentUser.onboarded_at) {
              refreshOnboarding();
            }
          }, 400);
        } else {
          setShowSpinner(false);
          showToast("Something went wrong");
        }
      })
      .catch(() => {
        setShowSpinner(false);
        showToast("Something went wrong");
      });
  };

  const editThisPost = () => {
    editPost(props.match.params.id, state)
      .then((response) => {
        if (response.data) {
          history.push(`/posts/${props.match.params.id}`);
          window.location.reload();
        } else {
          setShowSpinner(false);
          showToast("Something went wrong");
        }
      })
      .catch(() => {
        setShowSpinner(false);
        showToast("Something went wrong");
      });
  };

  return (
    <div>
      <BaseModal showModal={true} modalSize="medium">
        <ModalBody modalTitle={isEditingPost ? "Edit post" : "New post"}>
          <div className="posts-editor-inputs">
            <TextInput
              label="Title (optional)"
              name="title"
              value={title}
              error={title_error}
              onChange={handleChange}
              onBlur={validateField}
              placeholder="Give your post a title"
            />
            <TextArea
              label="Body"
              name="body"
              value={body}
              error={body_error}
              onChange={handleChange}
              onBlur={validateField}
              placeholder={`What's new, ${currentUser.display_name}?`}
              rows={5}
            />
          </div>
        </ModalBody>
        <ButtonsBar
          leftButtons={
            props.match.params.id && (
              <PrimaryButton
                label="Delete"
                type="button"
                style={["danger", "outlined"]}
                onClick={toggleConfirmModal}
              />
            )
          }
        >
          <PrimaryButton
            type="submit"
            label={isEditingPost ? "Apply changes" : "Post"}
            onClick={submitHandler}
            isLoading={showSpinner}
            type="button"
          />
        </ButtonsBar>
      </BaseModal>

      <ConfirmModal
        showConfirmModal={showConfirmModal}
        toggleModal={toggleConfirmModal}
        modalText={"Are you sure you want to delete this post?"}
        confirmButtonLabel={"Yes, delete"}
        backButtonLabel={"No"}
        onConfirm={deleteHandler}
      />
    </div>
  );
}
export default withRouter(NewPost);
