import axios from "axios";
const api = axios.create({
  withCredentials: true,
});
const API_URL = "/api/v1";

export function search(params) {
  return api.get(`${API_URL}/search/`, params, {
    withCredentials: true,
  });
}

export function loadArtwork(params) {
  return api.get(`${API_URL}/load_artwork/`, params, {
    withCredentials: true,
  });
}

export function loadCollections(params) {
  return api.get(`${API_URL}/load_collections/`, params, {
    withCredentials: true,
  });
}

export function fetchSearchHints(query) {
  return api.get(`${API_URL}/hints/`, query, {
    withCredentials: true,
  });
}

export function fetchTagHints(query) {
  return api.get(`${API_URL}/tag_hints/`, query, {
    withCredentials: true,
  });
}
