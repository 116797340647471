import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { withRouter, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import stylesheet from "../Pages.module.scss";

import Menu from "../../common/menu/Menu";
import Section from "../../common/layout/Section";
import BoundingBox from "../../common/layout/BoundingBox";
import ColumnLayout from "../../common/layout/ColumnLayout";
import Column from "../../common/layout/Column";
import AboutUs from "./AboutUs";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Contact from "./Contact";

const About = (props) => {
  const { path, isLoggedIn } = props;

  const aboutTabName = "about";
  const termsTabName = "terms";
  const privacyTabName = "privacy";
  const contactTabName = "contact";

  const [activeStep, setActiveStep] = useState();

  const location = useLocation();
  const currentPathName = location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (currentPathName.includes(termsTabName)) {
      setActiveStep(termsTabName);
    } else if (currentPathName.includes(privacyTabName)) {
      setActiveStep(privacyTabName);
    } else if (currentPathName.includes(contactTabName)) {
      setActiveStep(contactTabName);
    } else {
      setActiveStep(aboutTabName);
    }
  }, [currentPathName]);

  const guestLinks = (
    <div className={`content ${stylesheet.termsGuestLinks}`}>
      <p>
        <Link className="link" to={`/signin`}>
          Sign in
        </Link>{" "}
        or{" "}
        <Link className="link" to={`/coming-soon`}>
          join the waitlist
        </Link>
        . Or, if you already have an invite link,{" "}
        <Link className="link" to={`/join`}>
          create your account
        </Link>
        .
      </p>
    </div>
  );

  return (
    <Section showBackground={true}>
      <ColumnLayout>
        <Column>
          <Menu menuName={"Animatik"}>
            <li>
              <Link
                to={`/about/`}
                className={activeStep === aboutTabName ? "is-active" : ""}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to={`/about/terms`}
                className={activeStep === termsTabName ? "is-active" : ""}
              >
                Terms of service
              </Link>
            </li>
            <li>
              <Link
                to={`/about/privacy`}
                className={activeStep === privacyTabName ? "is-active" : ""}
              >
                Privacy policy
              </Link>
            </li>
            <li>
              <Link
                to={`/about/contact`}
                className={activeStep === contactTabName ? "is-active" : ""}
              >
                Contact
              </Link>
            </li>
          </Menu>
        </Column>
        <Column width={9}>
          <BoundingBox>
            <Route
              exact
              path={path + "/"}
              render={(props) => <AboutUs {...props} />}
            />
            <Route
              path={`${path}/terms`}
              render={(props) => <Terms {...props} />}
            />
            <Route
              path={`${path}/privacy`}
              render={(props) => <Privacy {...props} />}
            />
            <Route
              path={`${path}/contact`}
              render={(props) => <Contact {...props} />}
            />
          </BoundingBox>
        </Column>
      </ColumnLayout>
    </Section>
  );
};
export default withRouter(About);
