import React, { Component } from "react";
import InputError from "./InputError";
import InputSuccess from "./InputSuccess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class TextInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      label,
      name,
      id,
      value,
      defaultValue,
      placeholder,
      autocomplete,
      onChange,
      onBlur,
      onFocus,
      type,
      error,
      success,
      helper_text,
      icon,
      style,
    } = this.props;

    return (
      <div className="field">
        {label && (
          <label className="label" htmlFor={name}>
            {label}
          </label>
        )}
        <div className={icon ? "control has-icons-left" : "control"}>
          <input
            className={`input ${error ? "is-danger" : ""} ${
              success ? "is-success" : ""
            } ${style ? "is-" + style : ""}`}
            placeholder={placeholder}
            type={type ? type : "text"}
            name={name}
            id={id ? id : name}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            autoComplete={autocomplete ? autocomplete : "off"}
          />
          {icon && (
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={icon} />
            </span>
          )}
        </div>
        <InputError error={error} />
        <InputSuccess message={success} />
        <p className="help">{helper_text}</p>
      </div>
    );
  }
}
export default TextInput;
