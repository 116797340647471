// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Search-module__spyglassIcon___1qqM4{display:inline-block;padding-right:1rem;font-size:1.7rem}.Search-module__queryText___3A8w3{display:inline-block}\n", "",{"version":3,"sources":["/tmp/build_951a6463/app/javascript/components/search/Search.module.scss"],"names":[],"mappings":"AAKA,qCACE,oBAAqB,CACrB,kBAAmB,CACnB,gBAAiB,CAClB,kCAGC,oBAAqB","file":"Search.module.scss","sourcesContent":["@import \"bulma/sass/utilities/initial-variables\";\n@import \"bulma/sass/utilities/derived-variables\";\n\n/* Styles for the search results hero banner */\n\n.spyglassIcon {\n  display: inline-block;\n  padding-right: 1rem;\n  font-size: 1.7rem;\n}\n\n.queryText {\n  display: inline-block;\n}"]}]);
// Exports
exports.locals = {
	"spyglassIcon": "Search-module__spyglassIcon___1qqM4",
	"queryText": "Search-module__queryText___3A8w3"
};
module.exports = exports;
