/* This takes a rails string and returns an array that can be used for year, month, and day picker states. */
export function splitRailsDate(date) {
  if (!date) {
    return ["", "", ""];
  }
  let splitDate = date.split("-");
  /* returns an array in format of YYYY, MM, DD. Arr[1] is Month -1 because January is represented as 0 in the select field */
  return [
    parseInt(splitDate[0]),
    parseInt(splitDate[1] - 1),
    parseInt(splitDate[2]),
  ];
}

/* Convert the Rails string date into a JavaScript date */
export function convertDateStringToDateObject(str) {
  if (!str) {
    return new Date();
  }
  let birthdate = str.split("-");
  /* Month is -1 because January starts as 0. */
  return new Date(
    birthdate[0],
    parseInt(birthdate[1]) - 1,
    birthdate[2],
    0,
    0,
    0,
    0
  );
}
