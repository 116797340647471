import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { globalContext } from "../../util/globalContext.js";

import BaseModal from "../common/modal/BaseModal";
import ConfirmModal from "../common/modal/ConfirmModal";
import Wizard from "../common/Wizard";
import ArtworkEditor from "./Wizard/ArtworkEditor";
import Summary from "./Wizard/Summary";
import ImageUploader from "../common/uploading/ImageUploader";

class ArtworkWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      showCropperModal: false,
      modalSize: "card",
      summary: null,
      uploadQueue: [],
      submitQueue: [],
      summary: [],
    };
  }

  componentDidMount() {
    document.title = "New artwork";
  }

  toggleConfirmModal = () => {
    this.setState((prevState) => ({
      showConfirmModal: !prevState.showConfirmModal,
    }));
  };

  setUploadQueue = (array) => {
    this.setState({ uploadQueue: array, submitQueue: [] });
  };

  pushToSubmitQueue = (obj, shouldSubmit) => {
    let uploadsArray = [...this.state.submitQueue];
    let index = uploadsArray.findIndex(
      (el) => el.get("image").name === obj.get("image").name
    );
    if (index !== -1) {
      uploadsArray[index] = obj;
    } else {
      uploadsArray.push(obj);
    }

    this.setState({ submitQueue: uploadsArray });
  };

  setModalSize = (size) => this.setState({ modalSize: size });

  render() {
    const { uploadQueue, isLoadingQueue } = this.state;
    const { currentUser } = this.props;

    const confirmQuitModal = (
      <ConfirmModal
        showConfirmModal={this.state.showConfirmModal}
        toggleModal={this.toggleConfirmModal}
        modalTitle="Cancel uploading?"
        modalText="In progress uploads will be discarded. Are you sure you want to cancel uploading?"
        confirmButtonLabel="Yes, cancel"
        backButtonLabel="No"
      />
    );

    const newArtworkSteps = uploadQueue.map((pendingUpload, index) => (
      <ArtworkEditor
        key={"upload-" + index}
        modalTitle={"New artwork"}
        stepCount={
          uploadQueue.length > 1 ? index + 1 + " of " + uploadQueue.length : ""
        }
        queuedImage={pendingUpload}
        setModalSize={this.setModalSize}
        pushToQueue={this.pushToSubmitQueue}
        isFinalStep={index + 1 === uploadQueue.length ? true : false}
        submitQueue={this.state.submitQueue}
        setSummary={(update) =>
          this.setState({ summary: this.state.summary.concat([update]) })
        }
      />
    ));

    const renderSteps = () => {
      return [
        <ImageUploader
          key={1}
          uploaderTitle={"New artwork"}
          allowMultiple={true}
          setUploadQueue={this.setUploadQueue}
          setModalSize={this.setModalSize}
          showIntro={false}
        />,
        ...newArtworkSteps,
        <Summary
          key={2}
          summary={this.state.summary ? this.state.summary : null}
        />,
      ];
    };

    return (
      <div>
        <BaseModal
          showModal={true}
          modalSize={this.state.modalSize}
          onRequestClose={
            this.state.uploadQueue.length > 0 && this.state.summary.length === 0
              ? this.toggleConfirmModal
              : null
          }
        >
          <Wizard>{renderSteps()}</Wizard>
        </BaseModal>

        {confirmQuitModal}
      </div>
    );
  }
}
ArtworkWizard.contextType = globalContext;
export default withRouter(ArtworkWizard);
