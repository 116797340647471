import React, { useEffect, useState } from "react";

import Section from "../common/layout/Section";
import BoundingBox from "../common/layout/BoundingBox";
import Header from "../common/typography/Header";
import Paragraph from "../common/typography/Paragraph";
import Notification from "./Notification";
import Loading from "../pages/Loading";

import { fetchRecentNotifications } from "./NotificationsAPI";
import stylesheet from "./Notifications.module.scss";

const NotificationDashboard = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    document.title = "Notifications / Animatik";

    fetchRecentNotifications()
      .then((response) => {
        if (response.data) {
          setNotifications(response.data.notifications);
          setLoading(false);
        }
      })
      .catch((error) => {});
  }, []);

  const allNotifications = notifications.map((notification) => (
    <Notification key={notification.id} notification={notification} />
  ));

  const emptyState = (
    <div className={stylesheet.emptyStateDarker}>
      Engagement with your account will appear here.
    </div>
  );

  return (
    <Section showBackground={true}>
      <BoundingBox classes={stylesheet.notificationDashboard}>
        <Header
          level={1}
          size={"medium"}
          message={"Notifications"}
          styles={{ marginBottom: "0.5rem" }}
        />
        <Paragraph message={"Showing all engagement from the last 7 days."} />
        {isLoading ? (
          <Loading />
        ) : notifications.length > 0 ? (
          allNotifications
        ) : (
          emptyState
        )}
      </BoundingBox>
    </Section>
  );
};
export default NotificationDashboard;
