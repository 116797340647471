import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function ErrorWell(props) {
  const { label } = props;

  return (
    <article className="message is-danger">
      <div className="message-body">
        <span className="icon is-small">
          <FontAwesomeIcon icon={['fa', 'exclamation-circle']} />
        </span>
        <span className="well-message">
          {label}
        </span>
      </div>
    </article>
  );
}