import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import analytics from "../../util/analytics.js";

export function useGoogleAnalytics(user, isLoading) {
  const location = useLocation();
  const history = createBrowserHistory();
  const [isInit, setInit] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      analytics.init(user);
      setInit(true);
      analytics.sendPageview(
        history.location.pathname + history.location.search
      );
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isInit) return;
    analytics.setUser(user);
  }, [user]);

  useEffect(() => {
    if (!isInit) return;
    const currentPath = location.pathname + location.search;
    analytics.sendPageview(currentPath);
  }, [location]);
}
