import React, { Fragment } from "react";

import ModalBody from "../../common/modal/ModalBody";
import ButtonsBar from "../../common/modal/ButtonsBar";
import Loading from "../.././pages/Loading";
import Gallery from "../.././common/gallery/Gallery";
import Button from "../.././common/buttons/Button";

import { useHistory } from "react-router-dom";

export default function Summary(props) {
  let history = useHistory();

  let modalClose = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  const { summary } = props;

  const justPublished = <Gallery works={summary} />;

  return (
    <Fragment>
      <ModalBody
        modalTitle={"Just published"}
        modalHelperText={"View your work that just went live!"}
      >
        <div className="summary-container">
          {summary ? justPublished : <Loading />}
        </div>
      </ModalBody>
      <ButtonsBar>
        <Button
          kind={"ternary"}
          label={"Close"}
          onClick={modalClose}
          type={"button"}
        />
      </ButtonsBar>
    </Fragment>
  );
}
