import axios from "axios";
const api = axios.create({
  withCredentials: true,
});
const API_URL = "/api/v1";

// Create a new post
export function createPost(body) {
  return api.post(`${API_URL}/posts`, body, {
    withCredentials: true,
  });
}

// View post
export function fetchPost(id) {
  return api.get(`${API_URL}/posts/${id}`, {
    withCredentials: true,
  });
}

// Fetch all posts
export function fetchPosts(page) {
  return api.get(`${API_URL}/posts/`, page, {
    withCredentials: true,
  });
}

// Edit post
export function editPost(id, body) {
  return api.patch(`${API_URL}/posts/${id}`, body, {
    withCredentials: true,
  });
}

// Delete post
export function deletePost(id) {
  return api.delete(`${API_URL}/posts/${id}`, {
    withCredentials: true,
  });
}

// Like post
export function likePost(id) {
  return api.post(`${API_URL}/posts/${id}/like`, {
    withCredentials: true,
  });
}

// View likers of a post
export function fetchPostLikedByList(id, page) {
  return api.get(`${API_URL}/posts/${id}/liked_by_users/`, page, {
    withCredentials: true,
  });
}
