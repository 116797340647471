import React, { useState, useEffect, Fragment, useCallback } from "react";
import { fetchPosts } from "./PostAPI";

import Loading from "../pages/Loading";
import Section from "../common/layout/Section";
import MoreButton from "../common/MoreButton";
import PostMasonry from "./PostMasonry";

const Posts = (props) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState();
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isFirstPageLoad, toggleFirstPageLoad] = useState(true);

  const { currentUser, showToast } = props;

  useEffect(() => {
    document.title = "Posts / Animatik";
    window.scroll(0, 0);
  }, []);

  /* This effect is triggered on page load or when the order is changed. */
  useEffect(() => {
    const startingPage = {
      params: {
        page: page,
      },
    };
    buildPosts(startingPage);
  }, [page]);

  const buildPosts = useCallback((page) => {
    fetchPosts(page)
      .then((response) => {
        if (response.data.posts) {
          if (isFirstPageLoad) {
            setPosts(response.data.posts);
            setPage(parseInt(response.data.page));
            setPages(parseInt(response.data.pages));
            toggleFirstPageLoad(false);
          } else {
            const newPosts = [...posts, ...response.data.posts]; // This is how you get quasi-infinite scroll
            setPosts(newPosts);
          }

          setIsLoading(false);
          setShowSpinner(false);
        }
      })
      .catch((error) => console.log("api errors:", error));
  });

  const handlePageChange = () => {
    setShowSpinner(true);
    if (page < pages) {
      setPage((page) => page + 1);
    }
  };

  return (
    <Section showBackground={true}>
      {isLoading ? (
        <Loading />
      ) : (
        posts && (
          <Fragment>
            <PostMasonry
              posts={posts}
              currentUser={currentUser}
              showToast={showToast}
            />
            <MoreButton
              pages={pages}
              page={page}
              showSpinner={showSpinner}
              onClick={handlePageChange}
            />
          </Fragment>
        )
      )}
    </Section>
  );
};
export default Posts;
