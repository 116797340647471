import React, { useRef } from "react";
import InputError from "./InputError";

const TextArea = React.forwardRef((props, ref) => {
  const {
    label,
    placeholder,
    value,
    rows,
    onChange,
    onFocus,
    onBlur,
    name,
    id,
    error,
    style,
  } = props;

  const localRef = useRef(null);
  const inputRef = ref || localRef;

  return (
    <div className="field">
      {label && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}
      <div className="control">
        <textarea
          ref={inputRef}
          className={`textarea ${error ? "is-danger" : ""} ${
            style ? "is-" + style : ""
          }`}
          name={name}
          id={id ? id : name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          rows={rows ? rows : "7"}
        ></textarea>
      </div>
      <InputError error={error} />
    </div>
  );
});
export default TextArea;
