import React, { useState } from "react";
import Button from "./Button";
import stylesheet from "./Buttons.module.scss";

const ToTopButton = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <Button
      type="button"
      kind="overlay"
      isCircle={true}
      icon={"arrow-up"}
      onClick={scrollTop}
      classes={`${stylesheet.scrollToTop} ${
        showScroll ? stylesheet.showScroll : ""
      }`}
    />
  );
};
export default ToTopButton;
