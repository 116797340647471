import axios from "axios";
const api = axios.create({
  withCredentials: true,
});
const API_URL = "/api/v1";

export function follow(params) {
  return api.post(`${API_URL}/relationships`, params, {
    withCredentials: true,
  });
}

export function unfollow(id) {
  return api.delete(`${API_URL}/relationships/${id}`, {
    withCredentials: true,
  });
}
