import React, { memo } from "react";
import PropTypes from "prop-types";
import stylesheet from "./Type.module.scss";

const Header = memo((props) => {
  const { message, level, size, styles, classes } = props;

  /* Accepts a size prop to override the size styling of the header tag */
  const getStyleBySize = (size) => {
    if (size === "big") {
      return stylesheet.big;
    } else if (size === "medium") {
      return stylesheet.medium;
    } else {
      return stylesheet.small;
    }
  };

  /* Mix and match heading levels with sizes so that the visual and accessibility requirements are met */
  if (level === 3) {
    return (
      <h3
        className={`${size ? getStyleBySize(size) : stylesheet.small} ${
          classes ? classes : ""
        }`}
        style={styles}
      >
        {message}
      </h3>
    );
  } else if (level === 2) {
    return (
      <h2
        className={`${size ? getStyleBySize(size) : stylesheet.medium} ${
          classes ? classes : ""
        }`}
        style={styles}
      >
        {message}
      </h2>
    );
  } else {
    return (
      <h1
        className={`${size ? getStyleBySize(size) : stylesheet.big} ${
          classes ? classes : ""
        }`}
        style={styles}
      >
        {message}
      </h1>
    );
  }
});
Header.propTypes = {
  level: PropTypes.number,
  size: PropTypes.string,
  styles: PropTypes.object,
  message: PropTypes.string.isRequired,
};
export default Header;
