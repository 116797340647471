import React from "react";
import { Link } from "react-router-dom";
import stylesheet from "./Navigation.module.scss";

const Footer = () => {
  return (
    <footer className={stylesheet.footer}>
      <div className="container">
        <div className={stylesheet.footerBrand}>Animatik</div>
        <ul className={stylesheet.footerMenu}>
          <li>
            <Link to={`/about`}>About</Link>
          </li>
          <li>
            <Link to={`/about/terms`}>Terms</Link>
          </li>
          <li>
            <Link to={`/about/privacy`}>Privacy</Link>
          </li>
          <li>
            <Link to={`/about/contact`}>Contact</Link>
          </li>
          <li>
            <Link to={`/about/contact`}>Help</Link>
          </li>
          <li>&copy; {new Date().getFullYear()} Animatik</li>
        </ul>
        <div className={stylesheet.disclaimer}>
          All artwork belongs to stated owners
        </div>
      </div>
    </footer>
  );
};
export default Footer;
