import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { updateProfileCover } from "../UserAPI";

import BaseModal from "../../common/modal/BaseModal";
import ImageUploader from "../../common/uploading/ImageUploader";
import ImageCropper from "../../common/uploading/ImageCropper";
import Wizard from "../../common/Wizard";
import Loading from "../../pages/Loading";

const ProfileCoverWizard = (props) => {
  const [profileCover, setProfileCover] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [modalSize, setModalSize] = useState("small");

  const currentUser = props.location.state.currentUser;
  const history = useHistory();

  const handleSubmit = () => {
    if (croppedImage !== null && currentUser !== undefined) {
      const formData = new FormData();
      formData.append("profile_cover", croppedImage);
      formData.append("id", currentUser.id);

      updateProfileCover(formData)
        .then(() => {
          history.push("/settings/profile");
          window.location.reload();
        })
        .catch((error) => console.log("api errors:", error));
    } else {
      console.log("Errors");
    }
  };

  return (
    <BaseModal showModal={true} modalSize={modalSize}>
      <Wizard>
        <ImageUploader
          uploaderTitle={"Change profile cover"}
          selectImage={setProfileCover}
          unselectImage={() => setProfileCover(null)}
          setModalSize={setModalSize}
          setsProfileCover={true}
        />
        {modalSize === "editor" ? (
          <ImageCropper
            cropperModalTitle={"Crop profile cover"}
            selectedImage={profileCover}
            setCroppedThumbnail={setCroppedImage}
            handleSubmit={handleSubmit}
            cropShape={"rect"}
            aspectRatio={40 / 9}
            showBackButton={true}
            form={true}
          />
        ) : (
          <Loading />
        )}
      </Wizard>
    </BaseModal>
  );
};
export default withRouter(ProfileCoverWizard);
