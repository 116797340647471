import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "../buttons/Button";
import { follow, unfollow } from "./RelationshipsAPI";
import { globalContext } from "../../../util/globalContext.js";

const FollowButton = (props) => {
  const { isFollowing, userId, updateUser, classes, kind } = props;
  const [following, setFollowing] = useState(false);

  const globalState = useContext(globalContext);
  const currentUser = globalState.currentUser;
  const showToast = globalState.showToast;
  const refreshOnboarding = globalState.refreshOnboarding;

  /* Set local state for isFollowing so that it can be toggled on/off inline, independent of a parent component. */
  useEffect(() => {
    setFollowing(isFollowing);
  }, [isFollowing]);

  const followUser = () => {
    follow({ followed_id: userId })
      .then((response) => {
        if (response.data.success) {
          showToast("You followed this member.");
          setFollowing(true);
          updateUser ? updateUser(response.data.new_follow_count) : null;

          /* If the current user hasn't been onboarded yet, fetch the latest onboarding status to update the task list. */
          if (!currentUser.onboarded_at) {
            refreshOnboarding();
          }
        } else {
          showToast("Something went wrong. Refresh and try again");
        }
      })
      .catch(() => showToast("Something went wrong. Refresh and try again"));
  };

  const unfollowUser = () => {
    unfollow(userId)
      .then((response) => {
        if (response.data.success) {
          showToast("You unfollowed this member.");
          setFollowing(false);
          updateUser ? updateUser(response.data.new_follow_count) : null;
        } else {
          showToast("Something went wrong. Refresh and try again");
        }
      })
      .catch(() => showToast("Something went wrong. Refresh and try again"));
  };

  return currentUser.id && currentUser.id !== userId ? (
    <Button
      type={"button"}
      kind={kind === "link" ? "link" : following ? "secondary" : "primary"}
      label={following ? "Unfollow" : "Follow"}
      onClick={following ? unfollowUser : followUser}
      classes={classes}
    />
  ) : null;
};
FollowButton.propTypes = {
  isFollowing: PropTypes.bool,
  userId: PropTypes.number,
  updateUser: PropTypes.func,
  classes: PropTypes.string,
  kind: PropTypes.string,
};
export default FollowButton;
