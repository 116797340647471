import React, { useReducer, useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";

import {
  splitRailsDate,
  convertDateStringToDateObject,
} from "../../../util/dateHelpers.js";
import { globalContext } from "../../../util/globalContext.js";
import { updateUserData, updateProfileCover } from "../UserAPI";
import { validate, isValid } from "../UserValidator";

import stylesheet from "./Settings.module.scss";
import Menu from "./Menu";
import DatePicker from "./DatePicker";
import Section from "../../common/layout/Section";
import ColumnLayout from "../../common/layout/ColumnLayout";
import Column from "../../common/layout/Column";
import BoundingBox from "../../common/layout/BoundingBox";
import Header from "../../common/typography/Header";
import Paragraph from "../../common/typography/Paragraph";
import TextInput from "../../common/TextInput";
import TextArea from "../../common/TextArea";
import ButtonsBar from "../../common/modal/ButtonsBar";
import Button from "../../common/buttons/Button";
import DisplayImage from "../../common/users/DisplayImage";

const initialState = {
  id: "",
  display_name: "",
  bio: "",
  website: "",
  pronouns: "",
  year: "",
  month: "",
  day: "",
  birthdate_error: "",
  display_name_error: "",
  bio_error: "",
  website_error: "",
  pronouns_error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_STATE":
      return { ...state, ...action.data };
    case "UPDATE":
      return {
        ...state,
        [action.field]: action.value,
        [action.field + "_error"]: "",
      };
    case "SET_DATE_PROPERTY":
      return {
        ...state,
        [action.field]: action.value,
        birthdate_error: "",
      };
    default:
      return state;
  }
};

const EditProfile = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showSpinner, setShowSpinner] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const globalState = useContext(globalContext);
  const currentUser = globalState.currentUser;

  const {
    id,
    display_name,
    bio,
    website,
    pronouns,
    year,
    month,
    day,
    display_name_error,
    bio_error,
    website_error,
    pronouns_error,
    birthdate_error,
  } = state;

  useEffect(() => {
    if (!currentUser) return;
    document.title = "Settings / Profile";
    updateStateWithCurrentUser();
  }, []);

  const updateStateWithCurrentUser = () => {
    /* The rails serializer returns a string date, which needs to be split for the year month and day selector component. */
    let birthDate = splitRailsDate(currentUser.birthdate);
    dispatch({
      type: "LOAD_STATE",
      data: {
        id: currentUser.id,
        display_name: currentUser.display_name,
        bio: currentUser.bio ? currentUser.bio : "",
        website: currentUser.website ? currentUser.website : "",
        pronouns: currentUser.pronouns ? currentUser.pronouns : "",
        year: birthDate[0],
        month: birthDate[1],
        day: birthDate[2],
        display_name_error: "",
        bio_error: "",
        website_error: "",
        pronouns_error: "",
        birthdate_error: "",
      },
    });
  };

  /* Override any local state changes with what is saved in the current user variable. */
  const cancelChanges = () => {
    updateStateWithCurrentUser();
  };

  const validateField = (e) => {
    var validatedInput = validate({ [e.target.name]: e.target.value });

    dispatch({
      type: "LOAD_STATE",
      data: validatedInput,
    });
  };

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE",
      field: e.target.name,
      value: e.target.value,
    });
  };

  const setDateProperty = (field, value) => {
    dispatch({
      type: "SET_DATE_PROPERTY",
      field: field,
      value: value,
    });
  };

  const submitHandler = (e) => {
    setShowSpinner(true);
    e.preventDefault();

    if (year === "" || month === "" || day === "") {
      dispatch({
        type: "UPDATE",
        field: "birthdate_error",
        value: "Enter your date of birth, including the year, month, and day.",
      });
      setShowSpinner(false);
      return;
    }

    const updatedProperties = {
      display_name: display_name,
      bio: bio,
      website: website,
      pronouns: pronouns,
      birthdate: new Date(year, month, day, 0, 0, 0, 0),
    };

    const currentProperties = {
      display_name: currentUser.display_name,
      bio: currentUser.bio,
      website: currentUser.website,
      pronouns: currentUser.pronouns,
      birthdate: convertDateStringToDateObject(currentUser.birthdate),
    };

    /* First, check if there are any updates. If the object to update is the same, then return. */
    if (isEqual(updatedProperties, currentProperties)) {
      globalState.showToast("Everything is already up to date.");
      setShowSpinner(false);
      return;
    }
    /* Run front-end validation before submitting the data to update. If there are errors, return. */
    var validatedInput = validate(updatedProperties);
    if (!isValid(validatedInput)) {
      dispatch({
        type: "LOAD_STATE",
        data: validatedInput,
      });
      setShowSpinner(false);
      return;
    }
    setTimeout(
      function () {
        updateUserData(id, updatedProperties)
          .then((response) => {
            if (response.data) {
              setShowSpinner(false);
              globalState.showToast("You edited your profile.");
              props.handleSettingsChange(response.data);
            }
          })
          .catch(() => {
            globalState.showToast(
              "Something went wrong. Refresh and try again."
            );
            setShowSpinner(false);
          });
      }.bind(this),
      300
    );
  };

  /* Redirect the user to the edit profile cover route, which also launches a modal. */
  const editProfileCover = () => {
    history.push({
      pathname: `/edit-profile-cover`,
      state: { background: location, currentUser: currentUser },
    });
  };

  /* Sending no profile_cover param to the updateProfileCover call will remove the user's cover.  */
  const removeProfileCover = () => {
    const formData = new FormData();
    formData.append("id", currentUser.id);

    updateProfileCover(formData)
      .then(() => {
        history.push("/settings/profile");
        window.location.reload();
      })
      .catch(() =>
        globalState.showToast("Something went wrong. Refresh and try again.")
      );
  };

  return (
    <Section showBackground={true}>
      <ColumnLayout>
        <Column>
          <Menu activeItem={"profile"} />
        </Column>

        <Column width={8}>
          <BoundingBox classes={stylesheet.settingsContent}>
            <form onSubmit={submitHandler}>
              <Header
                level={2}
                size={"medium"}
                styles={{ marginBottom: "0.5rem" }}
                message={"Profile"}
              />
              <Paragraph
                message={
                  "Edit your public details here. This is how the community will get to know you."
                }
              />
              <figure className={stylesheet.profileCoverPreview}>
                <div className={stylesheet.editCoverButtons}>
                  {currentUser.profile_cover && (
                    <Button
                      kind={"overlay"}
                      label={"Remove"}
                      type={"button"}
                      classes={`is-small ${stylesheet.removeButton}`}
                      onClick={removeProfileCover}
                    />
                  )}
                  <Button
                    kind={"overlay"}
                    label={
                      currentUser.profile_cover ? "Change cover" : "Add a cover"
                    }
                    type={"button"}
                    icon={"edit"}
                    classes={"is-small"}
                    onClick={editProfileCover}
                  />
                </div>
                <img
                  src={
                    currentUser.profile_cover_small
                      ? currentUser.profile_cover_small.url
                      : ""
                  }
                  className={stylesheet.cover}
                />
              </figure>
              <div className={stylesheet.displayImage}>
                <DisplayImage
                  user={currentUser}
                  link={false}
                  showBorder={true}
                  size={104}
                />
                <div className={stylesheet.changeDisplayImage}>
                  <Link
                    className="button is-rounded is-outlined is-primary"
                    to={{
                      pathname: `/edit-display-image`,
                      state: { background: location, currentUser: currentUser },
                    }}
                  >
                    Change display image
                  </Link>
                </div>
              </div>
              <TextInput
                label="Display name"
                name="display_name"
                placeholder={`Add a display name, for example, "Alex"`}
                value={display_name}
                error={display_name_error}
                onChange={handleChange}
                onBlur={validateField}
                helper_text={`Your display name can include special characters, spaces, and even emojis ✨.`}
              />
              <TextArea
                label="Bio"
                name="bio"
                rows={3}
                placeholder={`Tell the community about yourself`}
                value={bio}
                error={bio_error}
                onChange={handleChange}
                onBlur={validateField}
              />
              <TextInput
                label="Pronouns"
                name="pronouns"
                placeholder="For example, they/them, she/her, he/him, etc"
                value={pronouns}
                error={pronouns_error}
                onChange={handleChange}
                onBlur={validateField}
              />
              <DatePicker
                year={year}
                month={month}
                day={day}
                error={birthdate_error}
                setDateProperty={setDateProperty}
                helperCopy={`Your date of birth lets us know you're old enough to use our services.
                Only you can see this information, but in the future, we'll give you
                control to share with certain audiences.`}
              />
              <TextInput
                label="Website"
                name="website"
                placeholder={"Add your website url"}
                value={website}
                error={website_error}
                onChange={handleChange}
                onBlur={validateField}
              />
              <ButtonsBar>
                <Button
                  type="button"
                  kind="ternary"
                  label={"Cancel"}
                  onClick={cancelChanges}
                />
                <Button
                  type="submit"
                  kind="primary"
                  label={"Apply changes"}
                  showSpinner={showSpinner}
                />
              </ButtonsBar>
            </form>
          </BoundingBox>
        </Column>
      </ColumnLayout>
    </Section>
  );
};
export default EditProfile;
