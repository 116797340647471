import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import Button from "../buttons/Button";
import DropdownMenu from "../dropdown/DropdownMenu";
import DropdownItem from "../dropdown/DropdownItem";
import stylesheet from "./Gallery.module.scss";

const SortDropdown = memo((props) => {
  const { title, setSortOption } = props;

  /* Handle showing and hiding the dropdown */
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <DropdownMenu
      id={"sort"}
      showDropdown={showDropdown}
      hideDropdown={() => setShowDropdown(false)}
      position={"right"}
      styles={{ right: "-5px", marginTop: "-0.25rem" }}
      trigger={
        <Button
          type={"button"}
          kind={"link"}
          classes={stylesheet.optionsDropDown}
          iconRight={"angle-down"}
          label={title}
          styles={showDropdown ? { color: "#00D1B2" } : {}}
          onClick={() => setShowDropdown(!showDropdown)}
        />
      }
    >
      <DropdownItem
        type={"button"}
        label={"Popular"}
        onClick={() => {
          setSortOption("Popular");
          setShowDropdown(false);
        }}
        iconLeft={["fa", "star"]}
      />
      {/* <DropdownItem
        type={"button"}
        label={"Most viewed"}
        onClick={() => {
          setSortOption("Most viewed");
          setShowDropdown(false);
        }}
        iconLeft={["fa", "eye"]}
      /> */}
      <DropdownItem
        type={"button"}
        label={"Newest"}
        onClick={() => {
          setSortOption("Newest");
          setShowDropdown(false);
        }}
        iconLeft={["fa", "clock"]}
      />
    </DropdownMenu>
  );
});
SortDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  setSortOption: PropTypes.func,
};
export default SortDropdown;
