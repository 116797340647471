import React, { useCallback } from "react";
import { loadCollections } from "../SearchAPI";
import PropTypes from "prop-types";
import PaginatedCollections from "../../common/collections/PaginatedCollections";
import Loading from "../../pages/Loading";

const CollectionResults = (props) => {
  const { collections, pagination, query, isLoading } = props;

  /* Fetches the next set of collections via callback */
  const loadMoreCollections = useCallback((page) => {
    const queryParam = {
      params: {
        query: query,
        page: page,
      },
    };
    return loadCollections(queryParam);
  });

  const queriedCollections = (
    <div style={{ paddingTop: "1.5rem" }}>
      {isLoading ? (
        <Loading />
      ) : (
        <PaginatedCollections
          title={"Collections"}
          collections={collections}
          page={pagination.page}
          pages={pagination.pageCount}
          fetchMethod={loadMoreCollections}
        />
      )}
    </div>
  );

  return collections.length > 0 ? queriedCollections : null;
};

CollectionResults.propTypes = {
  collections: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired,
};
export default CollectionResults;
