import React from "react";

export default () => {
  document.title = "Page not found"

  return (
    <div>
      <section className="section">
        <div className="container">
          <h1 className="title">404</h1>
          <h2 className="subtitle">Page not found</h2>
        </div>
      </section>
    </div>
  );
}