import React, { Component } from 'react';
import StepWizard from 'react-step-wizard';

export default function Wizard(props) {
  let noTransitions = {
    enterRight: '',
    enterLeft: '',
    exitRight: '',
    exitLeft: ''
  };

  return (
    <StepWizard transitions={noTransitions} onStepChange={props.onStepChange} isHashEnabled={props.isHashEnabled}>
      {props.children}
    </StepWizard>
  );
}