import React from 'react';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function EditButton(props) {
  const { id, kind, label } = props;
  let location = useLocation();

  let styles = "button is-rounded is-primary";

  if (kind === "outlined") {
    styles += " is-outlined";
  }

  return (
    <Link
      className={styles}
      to={{
        pathname: `/art/edit/${id}`,
        state: { background: location }
      }}>
      <span className="icon">
        <FontAwesomeIcon icon={['fa', 'edit']} />
      </span>
      {label && <span>{label}</span>}
    </Link>
  );
}