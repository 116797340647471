import React, { Fragment } from "react";
import { Link } from "react-router-dom";

export default function DisplayImage(props) {
  const { user, size, link, showBorder, classes } = props;

  let iconSize = size ? size : "";
  let styles = "display-image image is-" + iconSize + "x" + iconSize;
  if (showBorder) {
    styles += " display-image-border";
  }
  if (classes) {
    styles += " " + classes;
  }

  const displayImage = (
    <div className={styles}>
      <img
        alt=""
        src={
          user.display_image
            ? user.display_image.url
            : "https://medgoldresources.com/wp-content/uploads/2018/02/avatar-placeholder.gif"
        }
      />
    </div>
  );

  const linkWrapper = (
    <Link className="wrapped-link" to={`/${user.username}`}>
      {displayImage}
    </Link>
  );

  return <Fragment>{link === false ? displayImage : linkWrapper}</Fragment>;
}
