import React from "react";
import stylesheet from "./Modal.module.scss";

export default function ModalBody(props) {
  const {
    children,
    modalTitle,
    titleSubtext,
    modalHelperText,
    hasSmallMargins,
  } = props;
  return (
    <div>
      <div className={hasSmallMargins ? "modal-header-small" : "modal-header"}>
        {modalTitle && (
          <h1 className="title is-5 modal-head-text">{modalTitle}</h1>
        )}
        {titleSubtext && (
          <h1 className="title is-5 modal-head-subtext">{titleSubtext}</h1>
        )}
        {modalHelperText && (
          <p className={stylesheet.modalHelperText}>{modalHelperText}</p>
        )}
      </div>
      <div className="modal-body">{children}</div>
    </div>
  );
}
