import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import stylesheet from "./Settings.module.scss";
import BoundingBox from "../../common/layout/BoundingBox";
import Header from "../../common/typography/Header";

const Menu = (props) => {
  const { activeItem } = props;

  return (
    <BoundingBox classes={stylesheet.settingsSidebar}>
      <Header level={1} size={"medium"} message={"Settings"} />
      <ul className="menu-list">
        <li>
          <Link
            id={"profile"}
            className={activeItem === "profile" ? "is-active" : ""}
            to={`/settings/profile`}
          >
            Profile
          </Link>
        </li>
        <li>
          <Link
            id={"account"}
            className={activeItem === "account" ? "is-active" : ""}
            to={`/settings/account`}
          >
            Account
          </Link>
        </li>
      </ul>
    </BoundingBox>
  );
};
Menu.propTypes = {
  activeItem: PropTypes.string.isRequired,
};
export default Menu;
