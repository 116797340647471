import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import stylesheet from "./Navigation.module.scss";

import DropdownMenu from "../common/dropdown/DropdownMenu";
import DropdownItem from "../common/dropdown/DropdownItem";
import DropdownDivider from "../common/dropdown/DropdownDivider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function HamburgerMenu(props) {
  const { currentUser, handleSignOut } = props;

  /* Handle showing and hiding the dropdown */
  const [showDropdown, setShowDropdown] = useState(false);

  /* Used for setting bg for modal links  */
  let location = useLocation();

  /* Signed in actions */
  const signedInActions = (
    <Fragment>
      <DropdownItem
        type={"link"}
        label={"Profile"}
        linkTo={`/${currentUser.username}`}
        onClick={() => setShowDropdown(false)}
        iconLeft={["fa", "seedling"]}
      />
      <DropdownItem
        type={"link"}
        label={"Settings"}
        linkTo={"/settings/profile"}
        onClick={() => setShowDropdown(false)}
        iconLeft={["fa", "tools"]}
      />
      <DropdownItem
        type={"link"}
        label={"Sign out"}
        linkTo={"/logout"}
        onClick={() => {
          setShowDropdown(false);
          handleSignOut();
        }}
        iconLeft={["fa", "sign-out-alt"]}
      />
      <DropdownItem
        type={"link"}
        isPrimaryAction={true}
        linkTo={{
          pathname: `/new/art`,
          state: { background: location },
        }}
        label={"New"}
        onClick={() => setShowDropdown(false)}
        iconLeft={["fa", "pencil-alt"]}
      />
    </Fragment>
  );

  const guestActions = (
    <Fragment>
      <DropdownItem
        type={"link"}
        linkTo={`/signin`}
        label={"Sign in"}
        onClick={() => setShowDropdown(false)}
        iconLeft={["fa", "sign-in-alt"]}
      />
      <DropdownItem
        type={"link"}
        isPrimaryAction={true}
        linkTo={`/join`}
        label={"Join"}
        onClick={() => setShowDropdown(false)}
        iconLeft={["fa", "plus"]}
      />
    </Fragment>
  );

  return (
    <div>
      <DropdownMenu
        id={"hamburger"}
        showDropdown={showDropdown}
        hideDropdown={() => setShowDropdown(false)}
        position={"right"}
        trigger={
          <button
            className={stylesheet.hamburgerMenu}
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <span className="icon">
              <FontAwesomeIcon icon={["fa", "bars"]} />
            </span>
          </button>
        }
      >
        <DropdownItem
          type={"link"}
          label={"Explore"}
          linkTo={`/`}
          onClick={() => setShowDropdown(false)}
          iconLeft={["fa", "compass"]}
        />
        <DropdownItem
          type={"link"}
          label={"Following"}
          linkTo={`/following`}
          onClick={() => setShowDropdown(false)}
          iconLeft={["fa", "bolt"]}
        />
        <DropdownDivider />
        {currentUser.id ? signedInActions : guestActions}
      </DropdownMenu>
    </div>
  );
}
HamburgerMenu.propTypes = {
  currentUser: PropTypes.object.isRequired,
  handleSignOut: PropTypes.func,
};
export default HamburgerMenu;
