import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import stylesheet from "./Tabs.module.scss";

const Tab = (props) => {
  const { label, isActive, linkTo, onClick, userIcon } = props;

  const linkRoute = linkTo ? linkTo : "/";

  const link = (
    <Link
      to={linkRoute}
      className={`${stylesheet.tabLink} ${
        isActive ? stylesheet.tabAdjustment : ""
      }`}
      onClick={onClick}
    >
      {label}
    </Link>
  );

  /* Show a custom display for when a user icon is included in a tab. */
  const iconDisplay = (
    <Link to={linkRoute} className={`${stylesheet.iconTab}`} onClick={onClick}>
      <div className={stylesheet.iconTabContainer}>
        <div
          className={`${stylesheet.icon} ${
            isActive ? stylesheet.tabAdjustment : ""
          }`}
        >
          {userIcon}
        </div>
        <div
          className={`${stylesheet.name} ${
            !isActive ? stylesheet.iconTabAdjustment : ""
          }`}
        >
          {label}
        </div>
      </div>
    </Link>
  );

  return (
    <li className={isActive ? stylesheet.isActive : ""}>
      {userIcon ? iconDisplay : link}
    </li>
  );
};

Tab.propTypes = {
  label: PropTypes.string,
  isActive: PropTypes.bool,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  userIcon: PropTypes.object,
};
export default Tab;
