// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Common-module__paginationControls___3rdvt{margin:auto;margin-top:2rem;width:100%}.Common-module__loadMore___3S3go{margin:auto;margin-bottom:0.2rem;margin-top:1.5rem;text-align:center}.Common-module__isSmall___EI6X2{width:160px}.Common-module__loadMoreButton___2_9fR{overflow-anchor:none}\n", "",{"version":3,"sources":["/tmp/build_951a6463/app/javascript/components/common/Common.module.scss"],"names":[],"mappings":"AAKA,2CACE,WAAY,CACZ,eAAgB,CAChB,UAAW,CACZ,iCAGC,WAAY,CACZ,oBAAqB,CACrB,iBAAkB,CAClB,iBAAkB,CACnB,gCAGC,WAAY,CACb,uCAGC,oBAAqB","file":"Common.module.scss","sourcesContent":["@import \"bulma/sass/utilities/initial-variables\";\n@import \"bulma/sass/utilities/derived-variables\";\n\n/* Pagination styles */\n\n.paginationControls {\n  margin: auto;\n  margin-top: 2rem;\n  width: 100%;\n}\n\n.loadMore {\n  margin: auto;\n  margin-bottom: 0.2rem;\n  margin-top: 1.5rem;\n  text-align: center;\n}\n\n.isSmall {\n  width: 160px;\n}\n\n.loadMoreButton {\n  overflow-anchor: none;\n}\n"]}]);
// Exports
exports.locals = {
	"paginationControls": "Common-module__paginationControls___3rdvt",
	"loadMore": "Common-module__loadMore___3S3go",
	"isSmall": "Common-module__isSmall___EI6X2",
	"loadMoreButton": "Common-module__loadMoreButton___2_9fR"
};
module.exports = exports;
