import axios from "axios";
const api = axios.create({
  withCredentials: true,
});
const API_URL = "/api/v1";

export function fetchUnreadNotifications() {
  return api.get(`${API_URL}/notifications/`, {
    withCredentials: true,
  });
}

export function fetchRecentNotifications() {
  return api.get(`${API_URL}/notifications/activity`, {
    withCredentials: true,
  });
}

export function markAsRead() {
  return api.post(`${API_URL}/notifications/mark_as_read`, {
    withCredentials: true,
  });
}
