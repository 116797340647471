import React, { Fragment } from "react";
import styles from "./Dropdown.module.scss";
import PropTypes from "prop-types";

const DropdownHeading = (props) => {
  const { label, classes } = props;

  return (
    <Fragment>
      <div className={`dropdown-item ${styles.item} ${classes ? classes : ""}`}>
        <h3 className={`title is-6 ${styles.heading}`}>{label}</h3>
      </div>
    </Fragment>
  );
};

DropdownHeading.propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.string,
};

export default DropdownHeading;
