import React, { memo } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import DisplayImage from "./DisplayImage";
import FollowButton from "../relationships/FollowButton";
import stylesheet from "./Users.module.scss";

const UserBar = memo((props) => {
  const { user, showFollowButton, isFollowing } = props;

  const shortenedDisplayName =
    user.display_name.length > 20
      ? `${user.display_name.substring(0, 20)}...`
      : user.display_name;

  const displayName = (
    <span className={stylesheet.name}>{shortenedDisplayName}</span>
  );

  const username = (
    <span className={stylesheet.username}>@{user.username}</span>
  );

  return (
    <div className={stylesheet.userBar}>
      <Link className={stylesheet.linkWrapper} to={`/${user.username}`}>
        <div className={stylesheet.userDisplayImage}>
          <DisplayImage user={user} size={48} link={false} />
        </div>
        <div className={stylesheet.userDetails}>
          {displayName}
          {username}
        </div>
      </Link>
      {showFollowButton && (
        <div className={stylesheet.followUserButton}>
          <FollowButton isFollowing={isFollowing} userId={user.id} />
        </div>
      )}
    </div>
  );
});
UserBar.propTypes = {
  user: PropTypes.object.isRequired,
  showFollowButton: PropTypes.bool,
  isFollowing: PropTypes.bool,
};
export default UserBar;
