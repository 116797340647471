import React from "react";

export default function ColumnLayout(props) {
  const { id, children, verticalAlignment } = props;

  let styles = "columns";

  if (verticalAlignment) {
    styles += " is-vcentered";
  }

  return (
    <div className={styles} id={id}>
      {children}
    </div>
  );
}
