import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "./Gallery.module.scss";
import DropdownMenu from "../../common/dropdown/DropdownMenu";
import DropdownItem from "../../common/dropdown/DropdownItem";
import Button from "../../common/buttons/Button";

const OptionsDropdown = (props) => {
  const { toggleOpenState, forceClose, isOpen, actions, artworkId } = props;

  /* Handle showing and hiding the dropdown */
  const [showDropdown, setShowDropdown] = useState(false);

  /* On trigger click, either open or close the dropdown list */
  const toggleDropdown = () => {
    toggleOpenState();
    setShowDropdown(!showDropdown);
  };

  /* The button that toggles the dropdown open and closed */
  const dropdownTrigger = (
    <Button
      id="dropdown"
      type="button"
      kind="overlay"
      icon={"ellipsis-v"}
      isCircle={true}
      onClick={() => toggleDropdown()}
    />
  );

  /* For each item in the actions array, add an item to the dropdown list */
  const actionItems = actions.map((action, index) => (
    <DropdownItem
      key={index}
      type={"button"}
      label={action.name}
      onClick={() => {
        action.action(artworkId);
        forceClose();
      }}
    />
  ));

  return (
    <div
      className={styles.dropdown}
      onBlur={() => (isOpen ? null : forceClose())}
    >
      <DropdownMenu
        id={"thumbnail-options-" + artworkId}
        showDropdown={showDropdown}
        hideDropdown={() => forceClose()}
        position={"right"}
        trigger={dropdownTrigger}
      >
        {actions && actionItems}
      </DropdownMenu>
    </div>
  );
};

OptionsDropdown.propTypes = {
  toggleOpenState: PropTypes.func,
  forceClose: PropTypes.func,
  isOpen: PropTypes.bool,
  actions: PropTypes.array,
  artworkId: PropTypes.number,
};

export default OptionsDropdown;
