import React, { useState, useEffect, useReducer, useCallback } from "react";
import PropTypes from "prop-types";
import CollectionList from "./CollectionList";
import MoreButton from "../../common/MoreButton";
import Header from "../../common/typography/Header";
import stylesheet from "./Collections.module.scss";

const initialState = {
  collections: [],
  page: "",
  pagesTotal: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_STATE":
      return { ...state, ...action.data };
    case "UPDATE_COLLECTIONS":
      return { ...state, collections: [...state.collections, ...action.value] };
    case "INCREMENT_PAGE":
      return {
        ...state,
        page: state.page + 1,
      };
    default:
      return state;
  }
};

const PaginatedCollections = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showSpinner, setShowSpinner] = useState(false);

  const { fetchMethod } = props;
  const { collections, page, pagesTotal } = state;

  /* When the collections prop changes, map props to state so that pagination is handled locally */
  useEffect(() => {
    dispatch({
      type: "LOAD_STATE",
      data: {
        collections: props.collections,
        page: props.page,
        pagesTotal: props.pages,
      },
    });
  }, [props.collections]);

  /* Load more button handler. Update the page count which will trigger an API call */
  const loadMore = () => {
    if (page < pagesTotal) {
      setShowSpinner(true);
      dispatch({
        type: "INCREMENT_PAGE",
      });
    }
  };

  /* On page change, load more collections */
  useEffect(() => {
    if (page > 1) {
      setTimeout(
        function () {
          loadMoreCollections();
        }.bind(this),
        400
      );
    }
  }, [page]);

  /* Fetches the next set of collections via callback */
  const loadMoreCollections = useCallback(() => {
    fetchMethod(page)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "UPDATE_COLLECTIONS",
            value: response.data.collections,
          });
          setShowSpinner(false);
        } else {
          console.log("ERROR");
        }
      })
      .catch((error) => console.log("api errors:", error));
  });

  const collectionResults = (
    <div>
      <div className={stylesheet.header}>
        <div className={stylesheet.title}>
          {props.title && (
            <Header level={2} size={"small"} message={props.title} />
          )}
        </div>
        <div className={stylesheet.controls}>
          {props.controls && props.controls}
        </div>
      </div>
      <CollectionList collections={collections} />
      <MoreButton
        showSpinner={showSpinner}
        onClick={loadMore}
        pages={pagesTotal}
        page={page}
      />
    </div>
  );

  return collectionResults;
};

PaginatedCollections.propTypes = {
  title: PropTypes.string,
  collections: PropTypes.array,
  page: PropTypes.number,
  pages: PropTypes.number,
  fetchMethod: PropTypes.func,
  controls: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};
export default PaginatedCollections;
