import React, { Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
// import {
//   submitProfileComment,
//   loadProfileComments,
// } from "../../comments/CommentsAPI";
import { fetchFeedItemsByUsername } from "../UserAPI";
import PropTypes from "prop-types";
import stylesheet from "./Profile.module.scss";

import ColumnLayout from "../../common/layout/ColumnLayout";
import Column from "../../common/layout/Column";
import Header from "../../common/typography/Header";
import DisplayImage from "../../common/users/DisplayImage";
import AboutItem from "../../common/users/AboutItem";
import FollowButton from "../../common/relationships/FollowButton";
import Gallery from "../../common/gallery/Gallery";
import Button from "../../common/buttons/Button";
// import CommentContainer from "../../comments/CommentContainer";
import PaginatedFeed from "../../feed/PaginatedFeed";

const ProfileMain = (props) => {
  const {
    user,
    artwork,
    // comments,
    currentUser,
    posts,
    follow_count,
    current_user_follows,
    updateFollowDetails,
    profileLink,
    aboutTabName,
    galleryTabName,
    setActiveStep,
    history,
    showToast,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = user.display_name + " / Animatik";
  }, []);

  // const scrollToComments = (ref) => {
  //   if (ref && location.hash.includes("#comments")) {
  //     setTimeout(function () {
  //       ref.scrollIntoView({ behavior: "smooth", block: "start" });
  //     }, 750);
  //   }
  // };

  const goToGallery = () => {
    history.push({
      pathname: profileLink + "/" + galleryTabName,
    });
    setActiveStep(galleryTabName);
  };

  // const newCommentHandler = (parentId, body) => {
  //   return submitProfileComment(parentId, body);
  // };

  // const loadCommentHandler = (parentId, query) => {
  //   return loadProfileComments(parentId, query);
  // };

  const loadFeedItemsHandler = (query) => {
    return fetchFeedItemsByUsername(user.username, query);
  };

  const truncatedBio = user.bio ? user.bio.split(" ") : null;

  const followCounts = (
    <span className={stylesheet.followCounts}>
      <span className={`icon is-small ${stylesheet.icon}`}>
        <FontAwesomeIcon icon={["fa", "user-friends"]} />
      </span>
      <span className={stylesheet.followers}>
        <strong>{follow_count}</strong>{" "}
        {follow_count === 1 ? "follower" : "followers"}
      </span>
      <span className={stylesheet.following}>
        <strong>{user.following_count}</strong> following
      </span>
    </span>
  );

  return (
    <ColumnLayout>
      <Column width={3}>
        <div className={stylesheet.profileBar}>
          <div className={stylesheet.userIcon}>
            <DisplayImage user={user} link={false} />
          </div>
          <div className={stylesheet.userNameInfo}>
            <Header
              level={2}
              size={"big"}
              message={user.display_name ? user.display_name : ""}
              classes={stylesheet.displayName}
            />
            <span className={stylesheet.username}>@{user.username}</span>
          </div>
          {user.bio && (
            <p className={stylesheet.userBio}>
              <span className={stylesheet.bio}>
                {truncatedBio.length > 14 ? (
                  <Fragment>
                    {truncatedBio.splice(0, 14).join(" ")}...{" "}
                    <Link
                      className={"link"}
                      to={profileLink + "/" + aboutTabName}
                      onClick={() => setActiveStep(aboutTabName)}
                    >
                      More
                    </Link>
                  </Fragment>
                ) : (
                  user.bio
                )}
              </span>
            </p>
          )}
          <FollowButton
            isFollowing={current_user_follows}
            userId={user.id}
            updateUser={updateFollowDetails}
            classes={stylesheet.followButton}
          />
          {followCounts}
          {user.pronouns && (
            <AboutItem
              message={user.pronouns}
              icon={"seedling"}
              newLine={true}
            />
          )}
          {user.website && (
            <AboutItem
              message={user.website.replace(/^https?\:\/\/(www.)?/, "")}
              linkTo={user.website}
              icon={"link"}
              newLine={true}
            />
          )}
        </div>
      </Column>
      <Column width={5}>
        <div className={stylesheet.activityFeed}>
          <PaginatedFeed
            items={posts.posts}
            page={posts.page}
            pages={posts.pages}
            fetchMethod={loadFeedItemsHandler}
            currentUser={currentUser}
            showToast={showToast}
            classes={stylesheet.profileFeedPost}
          />
        </div>
      </Column>
      <Column width={4}>
        <div className={stylesheet.latestArt}>
          <Header
            level={3}
            size={"small"}
            message={"Latest from " + user.display_name}
            classes={stylesheet.latest}
          />
          <Gallery maximum={8} works={artwork} smallTiles={true} />
          {artwork.length > 4 && (
            <Fragment>
              <div className={stylesheet.moreButton}>
                <Button
                  type={"button"}
                  kind={"secondary"}
                  label={"More"}
                  iconRight={"angle-right"}
                  onClick={goToGallery}
                />
              </div>
              <div className={stylesheet.fadeGradient}></div>
            </Fragment>
          )}
        </div>
        {/* <div ref={scrollToComments} className={stylesheet.profileComments}>
          <Header level={3} size={"small"} message={"Comments"} />
          {comments.comments && (
            <CommentContainer
              parentId={user.id}
              comments={comments}
              newCommentHandler={newCommentHandler}
              loadCommentHandler={loadCommentHandler}
            />
          )}
        </div> */}
      </Column>
    </ColumnLayout>
  );
};

ProfileMain.propTypes = {
  user: PropTypes.PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  artwork: PropTypes.array,
  comments: PropTypes.object,
  posts: PropTypes.object,
  currentUser: PropTypes.object,
  follow_count: PropTypes.number,
  current_user_follows: PropTypes.bool,
  updateFollowDetails: PropTypes.func,
  profileLink: PropTypes.string,
  aboutTabName: PropTypes.string,
  galleryTabName: PropTypes.string,
  setActiveStep: PropTypes.func,
  history: PropTypes.object,
  showToast: PropTypes.func,
};
export default ProfileMain;
