import ReactGA from "react-ga";
const TRACKING_ID = "UA-182977893-1";

function init(currentUser) {
  // Enable debug mode on the local development environment
  var isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  // Ignore on heroku qa environment
  if (window.location.href.includes("qa")) {
    isDev = true;
  }
  ReactGA.initialize(TRACKING_ID, {
    debug: isDev,
    gaOptions: { userId: currentUser ? currentUser.id : 0 },
  });
}

function sendEvent(payload) {
  ReactGA.event(payload);
}

function sendPageview(path) {
  ReactGA.set({
    page: path,
    location: `${window.location.origin}${window.location.pathname}${window.location.search}`,
  });
  ReactGA.pageview(path);
}

function setUser(user) {
  ReactGA.set({ userId: user ? user.id : 0 });
}

export default {
  init,
  sendEvent,
  sendPageview,
  setUser,
};
