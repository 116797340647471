import React, { useEffect, Fragment, useReducer } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { search } from "../search/SearchAPI";

import styles from "./Search.module.scss";
import Section from "../common/layout/Section";
import Hero from "../common/layout/Hero";
import ArtworkResults from "./results/ArtworkResults";
import CollectionResults from "./results/CollectionResults";

const initialState = {
  isLoading: true,
  feature: {},
  artworkResults: [],
  artPagination: {
    page: "",
    pageCount: "",
  },
  collectionResults: [],
  collectionPagination: {
    page: "",
    pageCount: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_STATE":
      return { ...state, ...action.data };
    default:
      return state;
  }
};

const Search = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    isLoading,
    feature,
    artworkResults,
    artPagination,
    collectionResults,
    collectionPagination,
  } = state;

  const queryParam = props.location.state
    ? props.location.state.query
    : props.location.search.replace("?query=", "").replace("%20", " ");

  useEffect(() => {
    let query = {
      params: {
        query: queryParam,
        page: 1,
      },
    };
    document.title = "Search resuls for " + queryParam + " / Animatik";
    _fetchSearchResults(query);
  }, [queryParam]);

  const _fetchSearchResults = (query) => {
    search(query)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "LOAD_STATE",
            data: {
              isLoading: false,
              feature: response.data.feature,
              artworkResults: response.data.artwork,
              artPagination: {
                page: 1,
                pageCount: parseInt(response.data.artworkPageCount),
              },
              collectionResults: response.data.collections,
              collectionPagination: {
                page: 1,
                pageCount: parseInt(response.data.collectionPageCount),
              },
            },
          });
        } else {
          console.log("ERROR");
        }
      })
      .catch((error) => console.log("api errors:", error));
  };

  return (
    <Fragment>
      <Hero feature={feature}>
        <div className="hero-copy has-text-centered">
          <div className={styles.spyglassIcon}>
            <FontAwesomeIcon icon={["fa", "search"]} />
          </div>
          <div className={styles.queryText}>
            <h1 className="title">{queryParam}</h1>
          </div>
        </div>
      </Hero>
      <Section>
        <ArtworkResults
          artwork={artworkResults}
          isLoading={isLoading}
          pagination={artPagination}
          query={queryParam}
        />
        <CollectionResults
          collections={collectionResults}
          isLoading={isLoading}
          pagination={collectionPagination}
          query={queryParam}
        />
      </Section>
    </Fragment>
  );
};
export default Search;
