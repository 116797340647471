import React, { memo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import stylesheet from "./Tagging.module.scss";

const Tag = memo((props) => {
  const { tag } = props;

  return (
    <Link
      className={stylesheet.tag}
      to={{
        pathname: "/search",
        search: "?query=" + tag,
        state: { query: tag },
      }}
    >
      {tag}
    </Link>
  );
});
Tag.propTypes = {
  tag: PropTypes.string,
};
export default Tag;
