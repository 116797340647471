import React, { useState, useEffect } from "react";
import Button from "../../common/buttons/Button";
import styles from "../Artwork.module.scss";

export default function Frame(props) {
  const { art, next, prev, back } = props;
  const [isZoomed, setZoom] = useState(false);

  useEffect(() => {
    setZoom(false);
    // document.onkeydown = keyboardHandler;
    // return () => {
    //   document.onkeydown = null;
    // };
  }, [next, prev, art]);

  const toggleZoom = () => {
    if (!isZoomed) {
      setZoom(true);
    } else {
      setZoom(false);
    }
  };

  // function keyboardHandler(e) {
  //   e = e || window.event;
  //   if (prev && e.keyCode == "37") {
  //     prev();
  //   } else if (next && e.keyCode == "39") {
  //     next();
  //   }
  // }

  const expandButton = (
    <div className={styles.expand}>
      <Button
        type="button"
        kind="overlay"
        isCircle={true}
        icon={"expand-alt"}
        onClick={toggleZoom}
        classes={styles.frameControlButton}
      />
    </div>
  );

  const compressButton = (
    <div className={styles.expand}>
      <Button
        type="button"
        kind="overlay"
        isCircle={true}
        icon={"compress-alt"}
        onClick={toggleZoom}
        classes={styles.frameControlButton}
      />
    </div>
  );

  const backToCollection = (
    <div className={styles.backButton}>
      <Button
        type="button"
        kind="overlay"
        icon={"chevron-left"}
        iconRight={"folder-open"}
        onClick={back}
        classes={styles.backToCollectionButton}
      />
    </div>
  );

  const prevArtArrow = (
    <div className={styles.leftArrow}>
      <Button
        type="button"
        kind="overlay"
        isCircle={true}
        icon={"arrow-left"}
        onClick={prev}
        classes={styles.frameControlButton}
      />
    </div>
  );

  const nextArtArrow = (
    <div className={styles.rightArrow}>
      <Button
        type="button"
        kind="overlay"
        isCircle={true}
        icon={"arrow-right"}
        onClick={next}
        classes={styles.frameControlButton}
      />
    </div>
  );

  const zoomedIn = (
    <div className={styles.frame_expanded}>
      <figure className={styles.artwork_expanded}>
        {isZoomed && compressButton}
        <img src={art ? art.url : ""} onClick={toggleZoom} />
      </figure>
    </div>
  );

  const defaultView = (
    <div className={styles.frame}>
      <figure className={styles.artwork}>
        <div>
          {back && backToCollection}
          {prev && prevArtArrow}
          {!isZoomed && expandButton}
          {next && nextArtArrow}
        </div>
        <img src={art ? art.url : ""} onClick={toggleZoom} />
      </figure>
    </div>
  );

  return (
    <div className={styles.background}>{isZoomed ? zoomedIn : defaultView}</div>
  );
}
