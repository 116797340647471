import React, { useState, Fragment } from "react";
import { useLocation } from "react-router-dom";

import stylesheet from "./Navigation.module.scss";
import DropdownMenu from "../common/dropdown/DropdownMenu";
import DropdownItem from "../common/dropdown/DropdownItem";
import OnboardingItem from "./OnboardingItem";
import Button from "../common/buttons/Button";

const OnboardingDropdown = (props) => {
  const { onboarding, currentUser, history } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();

  const onboardingContent = (
    <Fragment>
      <DropdownItem type={"custom"}>
        <progress
          className="progress is-primary is-small"
          value={onboarding.percentage}
          max="100"
        >
          {onboarding.percentage}%
        </progress>
      </DropdownItem>
      <DropdownItem type={"custom"}>
        Explore Animatik and earn a badge for your profile!
      </DropdownItem>
      <OnboardingItem
        linkTo={`/settings/profile`}
        onClick={() => setShowDropdown(false)}
        isComplete={onboarding.has_icon}
        label={"Add a display image"}
        helperCopy={"Make yourself a friendly face."}
      />
      <OnboardingItem
        linkTo={{
          pathname: `/new/post`,
          state: { background: location },
        }}
        onClick={() => setShowDropdown(false)}
        isComplete={onboarding.has_post}
        label={"Introduce yourself"}
        helperCopy={"Create a post and say hello!"}
      />
      <OnboardingItem
        linkTo={{
          pathname: `/new/art`,
          state: { background: location },
        }}
        onClick={() => setShowDropdown(false)}
        isComplete={onboarding.has_uploaded}
        label={"Upload five works of art"}
        helperCopy={"Simply drag n' drop multiple files."}
      />
      <OnboardingItem
        linkTo={`/`}
        onClick={() => setShowDropdown(false)}
        isComplete={onboarding.has_commented}
        label={"Add a comment"}
        helperCopy={"Discuss an artwork or a post."}
      />
      <OnboardingItem
        linkTo={`/following`}
        onClick={() => setShowDropdown(false)}
        isComplete={onboarding.has_followed}
        label={"Follow a member"}
        helperCopy={"Jump-start your following feed."}
      />
    </Fragment>
  );

  const collectBadge = (
    <DropdownItem type={"custom"}>
      You did it! Next,{" "}
      <button
        className={stylesheet.collectBadge}
        onClick={() => {
          history.push("/" + currentUser.username + "/about");
          window.location.reload();
        }}
      >
        collect your badge
      </button>
      .
    </DropdownItem>
  );

  return (
    <DropdownMenu
      id={"onboarding-tasks"}
      showDropdown={showDropdown}
      hideDropdown={() => setShowDropdown(false)}
      position={"right"}
      classes={stylesheet.onboardingDropdown}
      trigger={
        <div className={stylesheet.onboardingButton}>
          <div className={stylesheet.bubble}></div>
          <Button
            type={"button"}
            kind={"tertiary"}
            isCircle={true}
            icon={"tasks"}
            onClick={() => setShowDropdown(!showDropdown)}
          />
        </div>
      }
      footer={
        <span>
          See our{" "}
          <a
            className={stylesheet.onboardingLink}
            href="https://twitter.com/joinanimatik"
            target="_blank"
          >
            Twitter
          </a>{" "}
          or{" "}
          <a
            className={stylesheet.onboardingLink}
            href="https://discord.gg/TfS83e7"
            target="_blank"
          >
            Discord
          </a>{" "}
          for help.
        </span>
      }
    >
      {!onboarding.onboarded_at && onboardingContent}
      {onboarding.onboarded_at || onboarding.percentage === 100
        ? collectBadge
        : null}
    </DropdownMenu>
  );
};
export default OnboardingDropdown;
