import React, { useState, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./Gallery.module.scss";
import OptionsDropdown from "./OptionsDropdown";

const Thumbnail = memo((props) => {
  const { artwork, big, collectionId, actions, thumnbnailIcon } = props;

  /* Toggles whether or not the option button should be shown. Only shows on hover */
  const [showOptionsDropdown, setShowOptionsDropdown] = useState(false);

  /* Tracks whether the dropdown is currently open- if it is open, the dropdown shouldn't be hidden on mouse leave */
  const [isOpen, setOpen] = useState(false);

  /* Closes the modal and hides the options button */
  const forceClose = () => {
    setOpen(false);
    setShowOptionsDropdown(false);
  };

  const toggleOpenState = () => {
    setOpen((state) => !state);
  };

  const nsfwCover = artwork.nsfw && (
    <div className={styles.contentCover}>
      <div className={big ? styles.matureWarningBig : styles.matureWarning}>
        <span className="icon is-small">
          <FontAwesomeIcon icon={["fa", "eye-slash"]} />
        </span>
        <span className={styles.label}>Mature content</span>
        {artwork.tags.slice(0, 7).map((tag, index) => (
          <span key={tag + "-" + index} className={styles.tag}>
            {tag}
          </span>
        ))}
        {artwork.tags.length > 7 && (
          <span>... +{artwork.tags.length - 7} more</span>
        )}
      </div>
    </div>
  );

  /* The actual thumbnail as a figure */
  const image = (
    <div className={styles.thumbnail}>
      <figure className="image">
        {nsfwCover}
        <img
          src={
            artwork
              ? big
                ? artwork.large_thumbnail.url
                : artwork.thumbnail.url
              : ""
          }
        />
      </figure>
    </div>
  );

  /* If an actions array is present, then show the options button on hover */
  const thumbnailWithActions = (
    <div
      className={big ? styles.gridItem_large : styles.gridItem}
      onMouseEnter={() => setShowOptionsDropdown(true)}
      onMouseLeave={() => (isOpen ? null : setShowOptionsDropdown(false))}
    >
      <Link
        to={{
          pathname: `/art/${artwork.id}`,
          state: { collectionId: collectionId },
        }}
        onFocus={() => setShowOptionsDropdown(true)}
      >
        {image}
      </Link>

      {showOptionsDropdown && (
        <OptionsDropdown
          toggleOpenState={toggleOpenState}
          forceClose={forceClose}
          isOpen={isOpen}
          actions={actions}
          artworkId={artwork.id}
        />
      )}
    </div>
  );

  /* If no actions are available, show a static thumbnail */
  const thumbnail = (
    <div className={big ? styles.gridItem_large : styles.gridItem}>
      <Link
        to={{
          pathname: `/art/${artwork.id}`,
          state: { collectionId: collectionId },
        }}
      >
        {image}
      </Link>

      {thumnbnailIcon && (
        <div className={styles.topRightIcon}>{thumnbnailIcon}</div>
      )}
    </div>
  );

  return actions ? thumbnailWithActions : thumbnail;
});

Thumbnail.propTypes = {
  artwork: PropTypes.object.isRequired,
  big: PropTypes.bool,
  collectionId: PropTypes.number,
  actions: PropTypes.array,
  thumnbnailIcon: PropTypes.object,
};
export default Thumbnail;
