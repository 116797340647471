import React from "react";
import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";
import PropTypes from "prop-types";
import stylesheet from "./Settings.module.scss";
import InputError from "../../common/InputError";

const DatePicker = (props) => {
  const { year, month, day, error, setDateProperty, helperCopy } = props;

  return (
    <div className={stylesheet.dateOfbirth}>
      <label className="label">Date of birth</label>
      <div className="select-field">
        <YearPicker
          defaultValue={"Year"}
          start={1970}
          reverse
          value={year}
          onChange={(year) => setDateProperty("year", year)}
          id={"year"}
          name={"year"}
          classes={error ? "is-danger" : ""}
        />
      </div>
      <div className="select-field">
        <MonthPicker
          defaultValue={"Month"}
          endYearGiven
          year={year}
          value={month}
          onChange={(month) => setDateProperty("month", month)}
          id={"month"}
          name={"month"}
          classes={error ? "is-danger" : ""}
        />
      </div>
      <div className="select-field">
        <DayPicker
          defaultValue={"Day"}
          year={year}
          month={month}
          endYearGiven
          value={day}
          onChange={(day) => setDateProperty("day", day)}
          id={"day"}
          name={"day"}
          classes={error ? "is-danger" : ""}
        />
      </div>
      <InputError error={error} />
      <p className="help">{helperCopy}</p>
    </div>
  );
};
DatePicker.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  setDateProperty: PropTypes.func.isRequired,
  helperCopy: PropTypes.string,
};
export default DatePicker;
