import React from "react";
import PropTypes from "prop-types";
import stylesheet from "./Users.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AboutItem = (props) => {
  const { message, icon, linkTo, newLine } = props;

  return (
    <span
      className={newLine ? stylesheet.infoItemNewLine : stylesheet.infoItem}
    >
      <span className={`icon is-small ${stylesheet.icon}`}>
        <FontAwesomeIcon icon={["fa", icon]} />
      </span>
      <span className={stylesheet.label}>
        {linkTo ? (
          <a className="link" href={linkTo} target="_blank">
            {message}
          </a>
        ) : (
          message
        )}
      </span>
    </span>
  );
};

AboutItem.propTypes = {
  message: PropTypes.string,
  linkTo: PropTypes.string,
  icon: PropTypes.string,
  newLine: PropTypes.bool,
};
export default AboutItem;
