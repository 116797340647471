import React, { Component } from 'react';
import InputError from './InputError';

class Checkbox extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { label, name, checked, value, onChange, error } = this.props;

    return (
      <div className="field">
        <label className="checkbox">
          <input
            type="checkbox"
            name={name}
            checked={checked}
            value={value}
            onChange={onChange}
          />
          {label}
        </label>
        <InputError error={error} />
      </div>
    );
  }
}
export default Checkbox;