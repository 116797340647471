import React, { Fragment, useState, useReducer, useContext } from "react";
import PropTypes from "prop-types";

import { globalContext } from "../../../util/globalContext.js";
import { updatePassword } from "../UserAPI";
import { validate, isValid } from "../UserValidator";

import TextInput from "../../common/TextInput";
import BaseModal from "../../common/modal/BaseModal";
import ModalBody from "../../common/modal/ModalBody";
import ButtonsBar from "../../common/modal/ButtonsBar";
import Button from "../../common/buttons/Button";

const initialState = {
  current_password: "",
  new_password: "",
  current_password_error: "",
  new_password_error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_STATE":
      return { ...state, ...action.data };
    case "RESET":
      return { ...state, initialState };
    case "UPDATE":
      return {
        ...state,
        [action.field]: action.value,
        [action.field + "_error"]: "",
      };
    default:
      return state;
  }
};

const ChangePassword = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showSpinner, setShowSpinner] = useState(false);
  const globalState = useContext(globalContext);
  const currentUser = globalState.currentUser;

  const {
    current_password,
    new_password,
    current_password_error,
    new_password_error,
  } = state;
  const { showModal, onClose } = props;

  const closeModal = () => {
    dispatch({
      type: "LOAD_STATE",
      data: {
        current_password: "",
        new_password: "",
        current_password_error: "",
        new_password_error: "",
      },
    });
    onClose();
  };

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE",
      field: e.target.name,
      value: e.target.value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const updatedProperties = {
      id: currentUser.id,
      password: current_password,
      new_password: new_password,
    };

    if (current_password === "") {
      dispatch({
        type: "UPDATE",
        field: "current_password_error",
        value: "Enter your current password to create a new one.",
      });
      setShowSpinner(false);
      return;
    }
    var validatedInput = validate(updatedProperties);
    if (!isValid(validatedInput)) {
      dispatch({
        type: "LOAD_STATE",
        data: validatedInput,
      });
      setShowSpinner(false);
      return;
    }

    setTimeout(
      function () {
        updatePassword(updatedProperties)
          .then((response) => {
            if (response.data.user) {
              dispatch({
                type: "RESET",
              });
              setShowSpinner(false);
              globalState.showToast("You updated your password.");
              closeModal();
            } else {
              dispatch({
                type: "LOAD_STATE",
                data: response.data,
              });
              setShowSpinner(false);
            }
          })
          .catch((error) => {
            globalState.showToast(
              "Something went wrong. Refresh and try again."
            );
            setShowSpinner(false);
          });
      }.bind(this),
      300
    );
  };

  return (
    <Fragment>
      {showModal && (
        <BaseModal
          showModal={showModal}
          modalSize={"small"}
          onRequestClose={closeModal}
        >
          <form onSubmit={submitHandler}>
            <ModalBody modalTitle={"Change password"}>
              <div className="editor-inputs">
                <TextInput
                  type={"password"}
                  label={"Current password"}
                  name={"current_password"}
                  placeholder={
                    "Enter your current password to create a new one."
                  }
                  value={current_password}
                  error={current_password_error}
                  onChange={handleChange}
                />
                <TextInput
                  type={"password"}
                  label={"New password"}
                  name={"new_password"}
                  placeholder={"Create a new password."}
                  value={new_password}
                  error={new_password_error}
                  onChange={handleChange}
                  autocomplete={false}
                  helper_text={
                    "Your new password must be at least 5 characters."
                  }
                />
              </div>
            </ModalBody>
            <ButtonsBar>
              <Button
                type={"button"}
                kind={"ternary"}
                label={"Cancel"}
                onClick={closeModal}
              />
              <Button
                type="submit"
                kind="primary"
                label={"Update"}
                showSpinner={showSpinner}
              />
            </ButtonsBar>
          </form>
        </BaseModal>
      )}
    </Fragment>
  );
};
ChangePassword.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default ChangePassword;
