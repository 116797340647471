import React, { Fragment } from "react";

import Section from "../common/layout/Section";
import BoundingBox from "../common/layout/BoundingBox";
import ColumnLayout from "../common/layout/ColumnLayout";
import Column from "../common/layout/Column";
import Header from "../common/typography/Header";
import Paragraph from "../common/typography/Paragraph";
import ButtonsBar from "../common/modal/ButtonsBar";
import Button from "../common/buttons/Button";
import TextInput from "../common/TextInput";
import TextArea from "../common/TextArea";

import stylesheet from "./Pages.module.scss";

const DesignSystem = (props) => {
  return (
    <Fragment>
      <Section>
        <BoundingBox>
          {" "}
          <Header level={1} size={"big"} message={"Design system"} />
          <Paragraph
            message={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent eu dolor ac velit porttitor consequat. Etiam malesuada faucibus metus sit amet pretium. Sed at libero lorem. Morbi fringilla ornare turpis malesuada accumsan. Fusce malesuada quam sed rhoncus laoreet. In ultricies ut felis ut mattis. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            }
          />
          <Paragraph
            message={
              "Fusce sollicitudin pretium enim, at eleifend ex fermentum vel. Aliquam erat volutpat. Sed nec consequat magna, id fringilla nisi. Aliquam tempus tellus elit, eu vestibulum odio congue ac. Morbi a urna velit. Quisque tortor ipsum, viverra aliquam sollicitudin a, venenatis sit amet purus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut non mi a est tristique commodo in ac libero. Phasellus auctor arcu in lobortis consectetur. Sed vulputate lobortis ipsum, non semper turpis euismod at. Vestibulum facilisis dolor dignissim lobortis sollicitudin. Proin eget mollis lorem. Pellentesque gravida malesuada consequat. Nunc id condimentum nulla, sed tempus orci. Nam fringilla quis diam et ultrices. Quisque hendrerit, nisl varius varius gravida, metus ipsum feugiat tellus, placerat ultrices quam mauris a quam."
            }
          />
          <Header level={2} size={"medium"} message={"Medium heading"} />
          <Paragraph
            message={
              "Donec posuere quam sed arcu placerat rhoncus. Quisque vehicula leo sed ipsum tempus efficitur. In magna diam, vestibulum ut orci vel, placerat vulputate metus. Fusce consectetur velit vitae neque aliquam semper. Nunc mollis lacus ut turpis hendrerit tincidunt. Nulla scelerisque sed ex quis consequat. Ut dui lectus, euismod eget volutpat consectetur, luctus ac risus. Curabitur nec elementum tortor. Phasellus ligula nibh, vulputate eu finibus ac, tempor faucibus leo."
            }
          />
          <Header level={3} size={"small"} message={"Small heading"} />
          <Paragraph
            message={
              "Aliquam facilisis fermentum porta. Nulla facilisi. Proin facilisis odio vel nunc ornare suscipit. Mauris ullamcorper odio eu augue mattis, nec venenatis enim tristique. Nunc molestie rutrum volutpat. Curabitur auctor consequat nunc, ut dictum nisi blandit et. Suspendisse nec sodales metus. Proin mi arcu, tincidunt in imperdiet sit amet, porta ac metus. Ut nec neque condimentum, fringilla lorem sed, placerat elit."
            }
          />
          <div style={{ width: "35%" }}>
            <TextInput
              label="Text input"
              placeholder="Enter some textual information"
              name="text"
            />
            <TextInput
              label="More input"
              placeholder="Enter some more textual information"
              name="text"
              helper_text="Enter some descriptive text"
            />
            <TextInput
              label="Error input"
              placeholder="Enter some more textual information"
              name="text"
              error={"Enter a correct value"}
            />
            <TextInput
              label="Success input"
              placeholder="Enter some more textual information"
              name="text"
              success={"A very nice value"}
            />
            <TextArea
              label="Text area"
              placeholder="Enter some textual information"
              name="text"
            />
          </div>
          {/* Standard button styles */}
          <ButtonsBar>
            <Button type="button" kind="primary" label={"Primary"} />
            <Button type="button" kind="secondary" label={"Secondary"} />
            <Button type="button" kind="tertiary" label={"Tertiary"} />
            <Button type="button" kind="ternary" label={"Ternary"} />
          </ButtonsBar>
          {/* Buttons with the spinner active */}
          <ButtonsBar>
            <Button
              type={"button"}
              kind={"primary"}
              label={"Primary"}
              showSpinner={true}
            />
            <Button
              type={"button"}
              kind={"secondary"}
              label={"Secondary"}
              showSpinner={true}
            />
            <Button
              type={"button"}
              kind={"tertiary"}
              label={"Tertiary"}
              showSpinner={true}
            />
            <Button
              type={"button"}
              kind={"ternary"}
              label={"Ternary"}
              showSpinner={true}
            />
          </ButtonsBar>
          {/* Standard buttons with icons */}
          <ButtonsBar>
            <Button
              type="button"
              kind="primary"
              label={"Icon"}
              icon={"pencil-alt"}
            />
            <Button
              type="button"
              kind="secondary"
              label={"Icon"}
              icon={"download"}
            />
            <Button
              type="button"
              kind="tertiary"
              label={"Share"}
              icon={"paper-plane"}
            />
            <Button
              type="button"
              kind="ternary"
              label={"Icon"}
              icon={"chevron-left"}
            />
          </ButtonsBar>
          <ButtonsBar>
            <Button
              type={"button"}
              kind={"primary"}
              label={"Danger"}
              isDanger={true}
            />
            <Button
              type={"button"}
              kind={"secondary"}
              label={"Danger"}
              isDanger={true}
            />
          </ButtonsBar>
        </BoundingBox>
        <ColumnLayout>
          <Column>
            <BoundingBox>
              <Header level={2} size={"medium"} message={"One column"} />
            </BoundingBox>
          </Column>
          <Column>
            <BoundingBox>
              <Header level={2} size={"medium"} message={"Two column"} />
            </BoundingBox>
          </Column>
        </ColumnLayout>
        <ColumnLayout>
          <Column>
            <BoundingBox>
              <Header level={2} size={"medium"} message={"One column"} />
            </BoundingBox>
          </Column>
          <Column>
            <BoundingBox>
              <Header level={2} size={"medium"} message={"Two column"} />
            </BoundingBox>
          </Column>
          <Column>
            <BoundingBox>
              <Header level={2} size={"medium"} message={"Three column"} />
            </BoundingBox>
          </Column>
        </ColumnLayout>

        {/* Profile layout */}
        <ColumnLayout>
          <Column>
            <BoundingBox styles={{ height: "220px" }} classes={"sidebar"}>
              <Header level={2} size={"medium"} message={"One column"} />
            </BoundingBox>
          </Column>
          <Column width={7}>
            <BoundingBox styles={{ height: "520px" }} classes={"feedbox"}>
              <Header level={2} size={"medium"} message={"Two column"} />
            </BoundingBox>
          </Column>
        </ColumnLayout>

        <ColumnLayout>
          <Column width={8}>
            <BoundingBox styles={{ height: "520px" }} classes={stylesheet.feed}>
              <Header level={2} size={"medium"} message={"Two column"} />
            </BoundingBox>
          </Column>
          <Column>
            <BoundingBox styles={{ height: "220px" }} classes={stylesheet.side}>
              <Header level={2} size={"medium"} message={"One column"} />
            </BoundingBox>
          </Column>
        </ColumnLayout>
      </Section>
    </Fragment>
  );
};
export default DesignSystem;
