import React, { useState, Fragment } from "react";
import { useHistory, useLocation } from "react-router";
import PropTypes from "prop-types";

import Button from "../buttons/Button";
import Collect from "../../artwork/View/Collect";

const CollectButton = (props) => {
  const { artwork, currentUser, showToast } = props;
  const [showCollectModal, setShowCollectModal] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const openCollectModal = () => {
    if (!currentUser.id) {
      history.push(`/signin`);
      return;
    } else {
      setShowCollectModal(!showCollectModal);
    }
  };

  return (
    <Fragment>
      <Collect
        showModal={showCollectModal}
        onRequestClose={() => setShowCollectModal(!showCollectModal)}
        showToast={showToast}
        artwork={artwork}
        currentUser={currentUser}
        location={location}
        history={history}
      />
      <Button
        type="button"
        kind="tertiary"
        icon={"folder-open"}
        isCircle={true}
        onClick={openCollectModal}
      />
    </Fragment>
  );
};
CollectButton.propTypes = {
  artwork: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  currentUser: PropTypes.object.isRequired,
  showToast: PropTypes.func.isRequired,
};
export default CollectButton;
