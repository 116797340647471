import React, { Suspense, lazy } from "react";
import Loading from "../../pages/Loading";
const GalleryTiles = lazy(() => import("./GalleryTiles"));

function Gallery(props) {
  const {
    title,
    works,
    maximum,
    mosaic,
    collectionId,
    actions,
    controls,
    smallTiles,
    thumnbnailIcon,
  } = props;
  return (
    <div>
      <Suspense fallback={<Loading />}>
        <GalleryTiles
          title={title}
          works={works}
          maximum={maximum}
          mosaic={mosaic}
          collectionId={collectionId}
          actions={actions}
          controls={controls}
          smallTiles={smallTiles}
          thumnbnailIcon={thumnbnailIcon}
        />
      </Suspense>
    </div>
  );
}
export default Gallery;
