import React, { memo } from "react";
import PropTypes from "prop-types";
import stylesheet from "./Profile.module.scss";

const ProfileCover = memo((props) => {
  const { cover } = props;

  const coverArt = {
    backgroundImage: `url(${cover ? cover.url : ""})`,
  };

  return (
    <section
      className={`hero is-primary ${
        cover ? stylesheet.coverArt : stylesheet.coverEmptyState
      }`}
      style={coverArt}
    >
      <div className="hero-body"></div>
    </section>
  );
});
ProfileCover.propTypes = {
  cover: PropTypes.object,
};
export default ProfileCover;
