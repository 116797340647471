import React, { useState, useEffect, Fragment, useCallback } from "react";
import { fetchCritiques } from "../artwork/ArtworkAPI";
import { Link } from "react-router-dom";

import stylesheet from "./Critiques.module.scss";
import Header from "../common/typography/Header";
import Loading from "../pages/Loading";
import Hero from "../common/layout/Hero";
import Gallery from "../common/gallery/Gallery";
import Section from "../common/layout/Section";
import MoreButton from "../common/MoreButton";
import CritiqueIcon from "./CritiqueIcon";

const Critiques = (props) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState();
  const [artwork, setArtwork] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    document.title = "Critiques / Animatik";
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    const startingPage = {
      params: {
        page: 1,
      },
    };
    buildCritiquesPage(startingPage);
  }, []);

  /* This effect is only triggered when the user triggers "Load more". It will not be called on page 1/initial page load. */
  useEffect(() => {
    if (page === 1) return;
    const startingPage = {
      params: {
        page: page,
      },
    };
    setTimeout(
      function () {
        loadMoreArt(startingPage);
      }.bind(this),
      300
    );
  }, [page]);

  const buildCritiquesPage = useCallback((page) => {
    fetchCritiques(page)
      .then((response) => {
        if (response.data.artwork) {
          setArtwork(response.data.artwork);
          setPage(parseInt(response.data.page));
          setPages(parseInt(response.data.pages));
        }
        setIsLoading(false);
        setShowSpinner(false);
      })
      .catch((error) => console.log("api errors:", error));
  });

  const loadMoreArt = useCallback((page) => {
    fetchCritiques(page)
      .then((response) => {
        if (response.data) {
          const newArray = [...artwork, ...response.data.artwork]; // This is how you get quasi-infinite scroll
          setArtwork(newArray);

          setPage(parseInt(response.data.page));
          setPages(parseInt(response.data.pages));
          setIsLoading(false);
          setShowSpinner(false);
        } else {
          console.log("ERROR");
        }
      })
      .catch((error) => console.log("api errors:", error));
  });

  const handlePageChange = () => {
    setShowSpinner(true);
    if (page < pages) {
      setPage((page) => page + 1);
    }
  };

  return (
    <Fragment>
      <Hero>
        <div className="has-text-centered">
          <Header
            level={1}
            size={"big"}
            message={"Critiques"}
            styles={{ marginBottom: "0.5rem" }}
          />
          Browse artwork open to critiques.
          {/* Browse artwork open to critiques. For tips on writing constructive
          comments, see{" "}
          <Link
            className={"link"}
            to={`/guides/writing-feedback`}
            target="_blank"
          >
            how to provide feedback
          </Link>
          . */}
        </div>
      </Hero>
      <Section>
        {isLoading ? (
          <Loading />
        ) : artwork.length > 0 ? (
          <Fragment>
            <Gallery
              works={artwork}
              mosaic={false}
              thumnbnailIcon={<CritiqueIcon />}
            />
            <MoreButton
              showSpinner={showSpinner}
              onClick={handlePageChange}
              pages={pages}
              page={page}
            />
          </Fragment>
        ) : (
          <div className={stylesheet.emptyState}>
            Artwork open to critiques will appear here. You can list artwork by
            turning on the "Request critiques and feedback" switch when editing.
          </div>
        )}
      </Section>
    </Fragment>
  );
};
export default Critiques;
