import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SocialLinks = () => {
  return (
    <Fragment>
      <a href="https://twitter.com/joinanimatik" target="_blank">
        <span className="icon">
          <FontAwesomeIcon icon={["fab", "twitter"]} />
        </span>
      </a>
      <a href="https://discord.gg/TfS83e7" target="_blank">
        <span className="icon">
          <FontAwesomeIcon icon={["fab", "discord"]} />
        </span>
      </a>
      <a href="https://www.instagram.com/joinanimatik/" target="_blank">
        <span className="icon">
          <FontAwesomeIcon icon={["fab", "instagram"]} />
        </span>
      </a>
      <a href="https://joinanimatik.tumblr.com" target="_blank">
        <span className="icon">
          <FontAwesomeIcon icon={["fab", "tumblr-square"]} />
        </span>
      </a>
    </Fragment>
  );
};
export default SocialLinks;
