import React, { memo } from "react";
import stylesheet from "./Feed.module.scss";
import Header from "../common/typography/Header";
import UserBar from "../common/users/UserBar";
import Loading from "../pages/Loading";

const Recommended = memo((props) => {
  const { recommended, isLoading, showHeader } = props;

  const userList = recommended.map((user) => (
    <UserBar
      key={user.id}
      user={user}
      showFollowButton={true}
      isFollowing={false}
    />
  ));

  return (
    <div className={stylesheet.recommendedSection}>
      {showHeader && (
        <Header
          level={1}
          size={"small"}
          message={"Spotlight"}
          classes={stylesheet.recommendedHeader}
        />
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <div className={stylesheet.recommendedList}>{userList}</div>
      )}
    </div>
  );
});
export default Recommended;
