import React from "react";
import PropTypes from "prop-types";
import stylesheet from "./Buttons.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = (props) => {
  const {
    type,
    kind,
    label,
    id,
    icon,
    iconRight,
    placeholder,
    onClick,
    showSpinner,
    isDanger,
    isCircle,
    classes,
    styles,
  } = props;

  const buttonIcon = (iconName) => (
    <span className={`icon ${isCircle ? stylesheet.iconInCircleButton : ""}`}>
      <FontAwesomeIcon icon={["fa", iconName]} />
    </span>
  );

  let buttonKindStyles = "";

  if (kind === "primary") {
    buttonKindStyles = "is-primary";
  } else if (kind === "secondary") {
    buttonKindStyles = `is-primary is-outlined`;
  } else if (kind === "tertiary") {
    buttonKindStyles = `is-outlined ${stylesheet.tertiary}`;
  } else if (kind === "ternary") {
    buttonKindStyles = `${stylesheet.ternary}`;
  } else if (kind == "link") {
    buttonKindStyles = `${stylesheet.linkButton}`;
  } else if (kind == "overlay") {
    buttonKindStyles = `${stylesheet.overlayButton}`;
  }

  return (
    <button
      className={`button ${buttonKindStyles} 
      ${kind !== "link" ? "is-rounded" : ""} ${
        showSpinner ? "is-loading" : ""
      } ${isDanger ? "is-danger" : ""} ${classes ? classes : ""} ${
        isCircle ? stylesheet.circleButton : ""
      }`}
      id={id}
      placeholder={placeholder}
      onClick={onClick}
      type={type}
      style={styles}
    >
      {icon && buttonIcon(icon)}
      <span>{label}</span>
      {iconRight && buttonIcon(iconRight)}
    </button>
  );
};
Button.propTypes = {
  type: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.string,
  iconRight: PropTypes.string,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  showSpinner: PropTypes.bool,
  isDanger: PropTypes.bool,
  isCircle: PropTypes.bool,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  styles: PropTypes.object,
};
export default Button;
