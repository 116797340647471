import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { fetchArtByUsername } from "../UserAPI";
import PaginatedGallery from "../../common/gallery/PaginatedGallery";

const ProfileGallery = (props) => {
  const {
    galleryOwner,
    artwork,
    artworkPagination,
    currentUser,
    location,
    history,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = galleryOwner.display_name + "'s Gallery / Animatik";
  }, []);

  /* Fetches the next set of artwork via callback */
  const loadMoreArtwork = useCallback((page) => {
    let pageParam = {
      params: {
        page: page,
      },
    };
    return fetchArtByUsername(galleryOwner.username, pageParam);
  });

  const editArtworkAction = (id) => {
    history.push({
      pathname: `/art/edit/${id}`,
      state: { background: location, currentUser: currentUser },
    });
  };

  return (
    <PaginatedGallery
      galleryTitle={galleryOwner.display_name + "'s Gallery"}
      artwork={artwork}
      page={artworkPagination.page}
      pages={artworkPagination.pages}
      fetchMethod={loadMoreArtwork}
      artworkActions={
        currentUser.id === galleryOwner.id
          ? [
              {
                name: "Edit artwork",
                action: editArtworkAction,
              },
            ]
          : null
      }
    />
  );
};

ProfileGallery.propTypes = {
  artwork: PropTypes.array,
  artworkPagination: PropTypes.object,
  galleryOwner: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  currentUser: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};
export default ProfileGallery;
