import React, { Fragment } from "react";

import BaseModal from "../../common/modal/BaseModal";
import ModalBody from "../../common/modal/ModalBody";
import Paragraph from "../../common/typography/Paragraph";

function Share(props) {
  const { onRequestClose, showModal } = props;
  return (
    <Fragment>
      {showModal && (
        <BaseModal
          shouldShowCloseAction={true}
          showModal={showModal}
          onRequestClose={onRequestClose}
          modalSize="small"
        >
          <ModalBody modalTitle={"Share"}>
            <Paragraph
              message={
                "Coming soon—once guests are able to browse artwork, we'll add the option to share art to other social networks."
              }
            />
          </ModalBody>
        </BaseModal>
      )}
    </Fragment>
  );
}
export default Share;
