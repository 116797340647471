import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import stylesheet from "./Navigation.module.scss";
import DropdownMenu from "../common/dropdown/DropdownMenu";
import DropdownItem from "../common/dropdown/DropdownItem";
import Button from "../common/buttons/Button";

const CreateDropdown = (props) => {
  /* Handle showing and hiding the dropdown */
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();

  return (
    <DropdownMenu
      id={"create-links"}
      showDropdown={showDropdown}
      hideDropdown={() => setShowDropdown(false)}
      position={"right"}
      trigger={
        <Button
          type={"button"}
          kind={"primary"}
          label={"New"}
          icon={"pencil-alt"}
          onClick={() => setShowDropdown(!showDropdown)}
          classes={stylesheet.createNewButton}
        />
      }
      classes={stylesheet.createNewDropdown}
    >
      <DropdownItem
        type={"link"}
        label={"Artwork"}
        linkTo={{
          pathname: `/new/art`,
          state: { background: location },
        }}
        onClick={() => setShowDropdown(false)}
        iconLeft={["fa", "images"]}
      />
      <DropdownItem
        type={"link"}
        label={"Post"}
        linkTo={{
          pathname: `/new/post`,
          state: { background: location },
        }}
        onClick={() => setShowDropdown(false)}
        iconLeft={["fa", "font"]}
      />
      <DropdownItem
        type={"link"}
        label={"Collection"}
        linkTo={{
          pathname: `/new/collection`,
          state: { background: location },
        }}
        onClick={() => setShowDropdown(false)}
        iconLeft={["fa", "folder-open"]}
      />
    </DropdownMenu>
  );
};
export default CreateDropdown;
