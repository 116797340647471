import React, { useState } from "react";
import { Link } from "react-router-dom";

import stylesheet from "./Navigation.module.scss";
import DropdownMenu from "../common/dropdown/DropdownMenu";
import DropdownItem from "../common/dropdown/DropdownItem";
import Button from "../common/buttons/Button";

const MoreLinks = (props) => {
  /* Handle showing and hiding the dropdown */
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <DropdownMenu
      id={"more-links"}
      showDropdown={showDropdown}
      hideDropdown={() => setShowDropdown(false)}
      position={"right"}
      trigger={
        <Button
          type={"button"}
          kind={"link"}
          label={"More"}
          iconRight={"angle-down"}
          onClick={() => setShowDropdown(!showDropdown)}
          classes={stylesheet.moreLinksButton}
        />
      }
      footer={
        <ul className={stylesheet.aboutLinkList}>
          <li>
            <Link
              className="link"
              to={`/about`}
              onClick={() => setShowDropdown(false)}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              className="link"
              to={`/about/terms`}
              onClick={() => setShowDropdown(false)}
            >
              Terms
            </Link>
          </li>
          <li>
            <Link
              className="link"
              to={`/about/privacy`}
              onClick={() => setShowDropdown(false)}
            >
              Privacy
            </Link>
          </li>
        </ul>
      }
    >
      <DropdownItem
        type={"link"}
        label={"Critiques"}
        linkTo={`/critiques`}
        onClick={() => setShowDropdown(false)}
      />
    </DropdownMenu>
  );
};
export default MoreLinks;
