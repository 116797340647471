import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebouncedEffect } from "./useDebouncedEffect";
import { fetchSearchHints } from "../search/SearchAPI";

import styles from "./Navigation.module.scss";
import Loading from "../pages/Loading";
import UserTag from "../common/users/UserTag";
import DropdownMenu from "../common/dropdown/DropdownMenu";
import DropdownHeading from "../common/dropdown/DropdownHeading";
import DropdownDivider from "../common/dropdown/DropdownDivider";
import DropdownItem from "../common/dropdown/DropdownItem";

const SearchBar = () => {
  const [showSpinner, setShowSpinner] = useState(true);
  const [dropdownExpanded, setDropdownExpanded] = useState(false);

  const [query, setQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [tags, setTags] = useState([]);

  const history = useHistory();

  /* Debounce search hints as the user types a new query */
  useEffect(() => {
    setShowSpinner(true);
  }, [query]);

  useDebouncedEffect(
    () => {
      _fetchSearchHints(query);
    },
    700,
    [query]
  );

  const _fetchSearchHints = (queryParam) => {
    const query = {
      params: {
        query: queryParam,
      },
    };
    fetchSearchHints(query)
      .then((response) => {
        if (response.data) {
          response.data.users ? setUsers(response.data.users) : null;
          response.data.tags ? setTags(response.data.tags) : null;
          setShowSpinner(false);
        } else {
          console.log("ERROR");
        }
      })
      .catch((error) => console.log("api errors:", error));
  };

  /* Submit the search request and go to the results page */
  const submitSearchForm = (evt) => {
    evt.preventDefault();
    setDropdownExpanded(false);

    history.push({
      pathname: "/search",
      search: "?query=" + query,
      state: { query: query },
    });
  };

  const tagHeading = <DropdownHeading label={"Popular"} />;

  const tagResults = tags.map((tag, index) => (
    <DropdownItem
      key={tag.name + index}
      type={"link"}
      label={tag.name.charAt(0).toUpperCase() + tag.name.slice(1)}
      linkTo={{
        pathname: "/search",
        search: "?query=" + tag.name,
        state: { query: tag.name },
      }}
      onClick={() => setDropdownExpanded(false)}
      iconLeft={["fa", "search"]}
    />
  ));

  const userHeading = (
    <Fragment>
      <DropdownDivider />
      <DropdownHeading label={"Members"} />
    </Fragment>
  );

  const userResults = users.map((user, index) => (
    <DropdownItem
      key={user.id + "-" + index}
      type={"notification"}
      linkTo={`/${user.username}`}
      onClick={() => setDropdownExpanded(false)}
    >
      <UserTag user={user} link={false} />
    </DropdownItem>
  ));

  const searchDropDown = (
    <DropdownMenu
      id={"search-menu"}
      showDropdown={dropdownExpanded}
      hideDropdown={() => setDropdownExpanded(false)}
      classes={styles.searchDropdown}
      trigger={
        <form
          className={styles.search}
          onSubmit={submitSearchForm}
          style={{ width: "100%" }}
        >
          <div className={"control has-icons-left"}>
            <input
              className={`input is-search`}
              placeholder={`Search tags to find and explore art`}
              type={"text"}
              name={"Search"}
              id={"Search"}
              autoComplete={"off"}
              value={query}
              onFocus={(e) => setDropdownExpanded(true)}
              onChange={(e) => {
                setQuery(e.target.value);
                setDropdownExpanded(true);
              }}
            />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={["fa", "search"]} />
            </span>
          </div>
        </form>
      }
    >
      {showSpinner ? (
        <Loading showCopy={false} />
      ) : (
        <Fragment>
          {tagHeading}
          {tagResults}

          {userHeading}
          {userResults}
        </Fragment>
      )}
    </DropdownMenu>
  );

  return searchDropDown;
};
export default SearchBar;
