import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function IconButton(props) {
  const { onClick, icon, ariaLabel, label, kind, showSpinner } = props;

  let styles = "button is-rounded is-outlined is-action-button";

  if (kind == "fresh") {
    styles += " is-fresh-like";
  }
  if (kind == "like") {
    styles += " is-like-button";
  }
  if (kind == "liked") {
    styles += " is-liked";
  }
  if (kind == "loader") {
    styles += " is-load-button";
  }
  if (kind == "round") {
    styles += " is-action-round"
  }
  if (showSpinner) {
    styles += " is-loading";
  }

  return (
    <button className={styles} aria-label={ariaLabel} onClick={onClick} type="button">
      {icon &&
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
      }
      {label &&
        <span>
          {label}
        </span>
      }
    </button>
  );
}