import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import stylesheet from "./Navigation.module.scss";
import DropdownItem from "../common/dropdown/DropdownItem";

const OnboardingItem = (props) => {
  const { linkTo, isComplete, label, helperCopy, onClick } = props;

  return (
    <DropdownItem type={"notification"} linkTo={linkTo} onClick={onClick}>
      <div className={stylesheet.onboardingItem}>
        <div className={stylesheet.icon}>
          {isComplete ? (
            <span className={`icon is-small ${stylesheet.complete}`}>
              <FontAwesomeIcon icon={["fa", "check-circle"]} />
            </span>
          ) : (
            <div className={stylesheet.incomplete}></div>
          )}
        </div>
        <div className={stylesheet.label}>
          <span>{label}</span>
          <p className={`${stylesheet.helperCopy}`}>{helperCopy}</p>
        </div>
      </div>
    </DropdownItem>
  );
};
export default OnboardingItem;
