import React, { lazy } from "react";
import { Suspense } from "react";

import Loading from "../../pages/Loading";
import PropTypes from "prop-types";

const CollectionContainer = lazy(() => import("./CollectionContainer"));

const CollectionList = (props) => {
  const { collections } = props;
  return (
    <div>
      <Suspense fallback={<Loading />}>
        <CollectionContainer collections={collections} />
      </Suspense>
    </div>
  );
};
CollectionList.propTypes = {
  collections: PropTypes.array.isRequired,
};
export default CollectionList;
