import React, { Component, useEffect } from 'react';
import BaseModal from './BaseModal';
import ModalBody from './ModalBody';
import ButtonsBar from './ButtonsBar';
import { useHistory } from 'react-router-dom';

class ConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.showConfirmModal
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.showConfirmModal !== prevProps.showConfirmModal) {
      this.setState({
        showModal: this.props.showConfirmModal
      });
    }
  }

  onClose = () => {
    this.setState({
      showModal: false
    });
    this.props.toggleModal();
  }

  render() {
    const {
      modalTitle,
      modalText,
      confirmButtonLabel,
      backButtonLabel,
      onConfirm } = this.props;

    const { showModal } = this.state;

    return (
      <div>
        <BaseModal
          showModal={showModal}
          onRequestClose={this.onClose}
          modalSize="small"
          shouldShowCancelActions={true}
          cancelButtonLabel={confirmButtonLabel ? confirmButtonLabel : "Confirm"}
          backButtonLabel={backButtonLabel ? backButtonLabel : "Cancel"}
          onConfirm={onConfirm}
          onBack={this.onClose}
          isSecondModal={true} >
          <ModalBody
            modalTitle={modalTitle ? modalTitle : "Confirm"}>
            <p>{modalText ? modalText : "You're about to delete this record. Are you sure?"}</p>
          </ModalBody>
        </BaseModal>
      </div>
    );
  }
}
export default ConfirmModal;