import React, { useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "../../common/typography/Header";
import Paragraph from "../../common/typography/Paragraph";

const Terms = () => {
  useEffect(() => {
    document.title = "Terms of service / Animatik";
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Header
        level={2}
        size={"medium"}
        message={"Terms of service"}
        styles={{ marginBottom: "0.75rem" }}
      />
      <Paragraph>Updated November 12, 2020.</Paragraph>
      <Header
        level={3}
        size={"small"}
        message={"Thank you for using Animatik!"}
      />
      <Paragraph>
        These Terms of service govern your access and use of Animatik. Read
        these terms carefully, and{" "}
        <Link className="link" to={`/about/contact`}>
          contact us
        </Link>{" "}
        if you have any questions or suggestions. By accessing or using
        Animatik, you agree to be bound by these Terms and our{" "}
        <Link className="link" to={`/about/privacy`}>
          Privacy policy
        </Link>{" "}
        . We reserve the right to change, modify, add, or delete sections of the
        Terms of service at our sole discretion.
      </Paragraph>
      <Header level={3} size={"small"} message={"Eligibility"} />
      <Paragraph>
        You may not use Animatik if you are under the minimum age of thirteen
        (13) or sixteen (16) in the Eurupean Union (or the higher age that your
        country has indicated for you to consent to the processing of your
        personal data). You may only use Animatik if you can form a binding
        contract and are not legally prohibited from using the site.
      </Paragraph>
      <Paragraph>
        You agree to provide accurate information upon joining. This includes
        your email address and your date of birth. This information must remain
        accurate, and must be updated if your circumstances change.
      </Paragraph>
      <Header level={3} size={"small"} message={"Conduct"} />
      <Paragraph>
        Your language and behaviour towards other users must be civilized:
        harassment, stalking, intimidation, blackmail and other forms of
        cyber-bullying are not tolerated. When noticing abusive behaviour, it is
        your responsibility to report it to Animatik.
      </Paragraph>
      <Paragraph>
        You agree to not publish your, or anybody else's, sensitive information,
        as well as any content that could otherwise be considered criminal by
        U.S., Canadian, or local laws.
      </Paragraph>
      <Paragraph>
        You agree to view items flagged as "mature content" at your own risk and
        to disable mature content warnings only at your own discretion.
      </Paragraph>
      <Paragraph>
        Spam and hack attempts are not permitted in any form.
      </Paragraph>
      <Paragraph>
        We reserve the right to have the final say in disputes and incidents,
        and the right to monitor public activity and private disputes.
      </Paragraph>
      <Header level={3} size={"small"} message={"Your content"} />
      <Paragraph>
        Animatik allows you to submit images. Anything that you post or
        otherwise make available on Animatik is referred to as "User Content."
        You retain all rights in, and are solely responsible for, the User
        Content you post to Animatik.
      </Paragraph>
      <Paragraph>
        User Content posted in public space (including but not limited to your
        display image and linked images) must not contain mature content of any
        kind, with the exception of submissions flagged as "mature content."
      </Paragraph>
      <Paragraph>
        You agree that by submitting images as "artwork", you either own the
        full rights to the artwork to or have the content owner's explicit
        permission to upload this image.
      </Paragraph>
      <Paragraph>
        You accept responsibility for anything posted by your account Animatik.
        We reserve the right to delete content of any kind if it violates our
        Terms of service or if we feel it puts any of our users or the site at
        risk.
      </Paragraph>
      <Header level={3} size={"small"} message={"Termination"} />
      <Paragraph>
        We will indefinitely suspend users without prior notice if we feel they
        are in violation of our Terms of service or any of our policies. We may
        also delete any or all of the content produced by this user.
      </Paragraph>
      <Paragraph>
        Upon exclusion, you agree to refrain from interacting with any other
        users, with the exception of team members only when disputing the
        exclusion, in any way until you are re-admitted to the site. During this
        time, you must not create any new accounts.
      </Paragraph>
      <Paragraph>
        Repeat or extreme violations may lead to legal action.
      </Paragraph>
      <Header level={3} size={"small"} message={"Legal"} />
      <Paragraph>
        Animatik is operated from Canada and, as such, abides by Canadian law.
        The servers are located in the United States, so any content passed
        through them must also abide by U.S. and any other local laws.
      </Paragraph>
    </Fragment>
  );
};
export default Terms;
