import React, { memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import stylesheet from "./Feed.module.scss";

import BoundingBox from "../common/layout/BoundingBox";
import Header from "../common/typography/Header";
import UserTag from "../common/users/UserTag";
import Paragraph from "../common/typography/Paragraph";
import TagList from "../common/tagging/TagList";
import LikeButton from "../common/artwork/LikeButton";
import CollectButton from "../common/artwork/CollectButton";

const FeedPost = memo((props) => {
  const {
    kind,
    objectData,
    buttonBarItems,
    bottomContent,
    showCommentLink,
    currentUser,
    showToast,
    classes,
  } = props;

  if (!objectData.id) {
    return null;
  }

  const isArtworkPost = kind === "artwork";
  const isTextPost = kind === "post";

  return (
    <BoundingBox classes={classes ? classes : stylesheet.feedPost}>
      <div className={stylesheet.feedHeader}>
        <div className={stylesheet.userTag}>
          <UserTag user={objectData.user} />
        </div>
        <div className={stylesheet.controls}>
          {isArtworkPost && (
            <CollectButton
              artwork={objectData}
              currentUser={currentUser}
              showToast={showToast}
            />
          )}
          <LikeButton
            objectType={isArtworkPost ? "artwork" : isTextPost ? "post" : ""}
            objectId={objectData.id}
            objectOwnerId={objectData.user.id}
            isLiked={objectData.liked_by_current_user}
            likeCount={objectData.likes}
            currentUser={currentUser}
          />
          {buttonBarItems}
        </div>
      </div>

      {isArtworkPost && (
        <Link to={`/art/${objectData.id}`}>
          <figure className="image">
            {objectData.nsfw && (
              <div className={stylesheet.contentCover}>
                <div className={stylesheet.matureWarning}>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={["fa", "eye-slash"]} />
                  </span>
                  <span className={stylesheet.label}>Mature content</span>
                </div>
              </div>
            )}
            <img src={objectData.image.url} />
          </figure>
        </Link>
      )}

      <div className={isArtworkPost ? stylesheet.artInfo : stylesheet.postInfo}>
        {objectData.title && (
          <Header
            level={2}
            size={"medium"}
            message={objectData.title}
            styles={{ marginBottom: "0.5rem" }}
          />
        )}
        <Paragraph
          message={isArtworkPost ? objectData.caption : objectData.body}
          applyFormatting={true}
        />
        {isArtworkPost && (
          <TagList tags={objectData.tags} classes={stylesheet.feedTags} />
        )}
        {showCommentLink && (
          <Link
            to={`/${isArtworkPost ? "art" : "posts"}/${objectData.id}#comments`}
            target="_blank"
            className={stylesheet.commentLink}
          >
            <span className={stylesheet.label}>
              {objectData.total_comments === 1
                ? "View comment"
                : objectData.total_comments > 1
                ? "View all " + objectData.total_comments + " comments"
                : "Add a comment"}
            </span>
            <span className={`icon is-small ${stylesheet.icon}`}>
              <FontAwesomeIcon icon={["fa", "angle-right"]} />
            </span>
          </Link>
        )}

        {bottomContent}

        <div className={stylesheet.mobileControls}>
          {isArtworkPost && (
            <CollectButton
              artwork={objectData}
              currentUser={currentUser}
              showToast={showToast}
            />
          )}
          <LikeButton
            objectType={isArtworkPost ? "artwork" : isTextPost ? "post" : ""}
            objectId={objectData.id}
            objectOwnerId={objectData.user.id}
            isLiked={objectData.liked_by_current_user}
            likeCount={objectData.likes}
            currentUser={currentUser}
          />
        </div>
      </div>
    </BoundingBox>
  );
});
FeedPost.propTypes = {
  kind: PropTypes.string.isRequired,
  objectData: PropTypes.object.isRequired,
  buttonBarItems: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  bottomContent: PropTypes.object,
  showCommentLink: PropTypes.bool,
  currentUser: PropTypes.object.isRequired,
  showToast: PropTypes.func,
  classes: PropTypes.string,
};
export default FeedPost;
