import React from "react";
import PropTypes from "prop-types";
import stylesheet from "./Layout.module.scss";

const BoundingBox = (props) => {
  const { children, styles, classes } = props;

  return (
    <div className={`${stylesheet.boundingBox} ${classes}`} style={styles}>
      {children}
    </div>
  );
};
BoundingBox.propTypes = {};
export default BoundingBox;
