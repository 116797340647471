// Field name constants
const USERNAME_FIELD_NAME = "username";
const EMAIL_FIELD_NAME = "email";
const PASSWORD_FIELD_NAME = "password";
const NEW_PASSWORD_FIELD_NAME = "new_password";
const DISPLAY_NAME_FIELD_NAME = "display_name";
const BIO_FIELD_NAME = "bio";
const WEBSITE_FIELD_NAME = "website";
const PRONOUNS_FIELD_NAME = "pronouns";
const BIRTHDATE_FIELD_NAME = "birthdate";

// Validation requirements
const USERNAME_EXPRESSION = /^(?![0-9]*$)[a-zA-Z0-9]+$/;
const USERNAME_MAX = 15;
const USERNAME_MIN = 4;
const EMAIL_EXPRESSION = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
const PASSWORD_MIN = 5;
const DISPLAY_NAME_MAX = 35;
const BIO_MAX = 280;
const WEBSITE_EXPRESSION = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
const PRONOUNS_MAX = 20;

function _validateUsername(username) {
  var userError = "";
  if (username.length < USERNAME_MIN) {
    return "Username must at least 4 characters";
  } else if (username.length > USERNAME_MAX) {
    return "Username can't be more than 15 characters";
  } else if (!USERNAME_EXPRESSION.test(username.toLowerCase())) {
    return "Username must be alphanumeric and must include at least 1 letter";
  }
  return "";
}

function _validateEmail(email) {
  return EMAIL_EXPRESSION.test(email.toLowerCase())
    ? ""
    : "Email must be in name@example.com format.";
}

function _validatePassword(password) {
  return password.length >= PASSWORD_MIN
    ? ""
    : "Enter your current password to create a new one.";
}

function _validateNewPassword(newPassword) {
  return newPassword.length >= PASSWORD_MIN
    ? ""
    : "Your new password must be at least 5 characters.";
}

function _validateDisplayName(displayName) {
  if (!displayName) {
    return "Enter a display name";
  }
  if (displayName.length > DISPLAY_NAME_MAX) {
    return "Enter a display name 35 characters or less.";
  }
  return "";
}

function _validateBio(bio) {
  return bio.length > BIO_MAX ? "Enter a bio 280 characters or less." : "";
}

function _validateWebsite(website) {
  return WEBSITE_EXPRESSION.test(website) || website === ""
    ? ""
    : "Enter a real website url. Urls commonly begin with http:// or www.";
}

function _validatePronouns(pronouns) {
  return pronouns.length > PRONOUNS_MAX
    ? "Enter pronouns that are 20 characters or less."
    : "";
}

function _validateBirthdate(birthDate) {
  var today = new Date();
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age < 13
    ? "You must be 13 years or older to have an account. Providing a false date of birth is against our terms of service."
    : "";
}

export function mapUserModelErrors(apiErrors) {
  var errors = {};
  apiErrors.forEach(function (error) {
    if (error.toLowerCase().indexOf(USERNAME_FIELD_NAME) !== -1) {
      errors.username_error = error;
    }
    if (error.toLowerCase().indexOf(EMAIL_FIELD_NAME) !== -1) {
      errors.email_error = error;
    }
    if (
      error
        .toLowerCase()
        .indexOf(DISPLAY_NAME_FIELD_NAME.replace(/_/g, " ")) !== -1
    ) {
      errors.display_name_error = error;
    }
    if (error.toLowerCase().indexOf(PASSWORD_FIELD_NAME) !== -1) {
      errors.new_password_error = error;
    }
  });
  return errors;
}

export function isValid(errors) {
  return Object.values(errors).every((error) => error === "");
}

export function validate(userInput) {
  var errors = {};
  for (const field in userInput) {
    // If the input is undefined it means the value is unchanged when updating from Settings.
    if (userInput[field] === undefined) {
      continue;
    } else if (field === USERNAME_FIELD_NAME) {
      errors.username_error = _validateUsername(userInput[field]);
    } else if (field === EMAIL_FIELD_NAME) {
      errors.email_error = _validateEmail(userInput[field]);
    } else if (field === PASSWORD_FIELD_NAME) {
      errors.current_password_error = _validatePassword(userInput[field]);
    } else if (field === NEW_PASSWORD_FIELD_NAME) {
      errors.new_password_error = _validateNewPassword(userInput[field]);
    } else if (field === DISPLAY_NAME_FIELD_NAME) {
      errors.display_name_error = _validateDisplayName(userInput[field]);
    } else if (field === BIO_FIELD_NAME) {
      errors.bio_error = _validateBio(userInput[field]);
    } else if (field === WEBSITE_FIELD_NAME) {
      errors.website_error = _validateWebsite(userInput[field]);
    } else if (field === PRONOUNS_FIELD_NAME) {
      errors.pronouns_error = _validatePronouns(userInput[field]);
    } else if (field === BIRTHDATE_FIELD_NAME) {
      errors.birthdate_error = _validateBirthdate(userInput[field]);
    }
  }
  return errors;
}
