import React, { Fragment, memo } from "react";
import { Link } from "react-router-dom";
import DisplayImage from "./DisplayImage";

const UserTag = memo((props) => {
  const { user, link, size } = props;

  const displayName = (
    <span
      className={`${size === "small" ? "display-name-small" : "display-name"}`}
    >
      {user.display_name}
    </span>
  );

  const username = (
    <span className="subtext">
      <span className="username">@{user.username}</span>
    </span>
  );

  const details = (
    <Fragment>
      {displayName}
      {username}
    </Fragment>
  );

  const linkWrapper = (
    <Link className="wrapped-link" to={`/${user.username}`}>
      {details}
    </Link>
  );

  return (
    <div className="media">
      <div className="media-left">
        <DisplayImage
          user={user}
          size={size === "small" ? 40 : 48}
          link={link}
        />
      </div>
      <div className="media-content user-tag">
        {link === false ? details : linkWrapper}
      </div>
    </div>
  );
});
export default UserTag;
