import React from 'react';

export default function Divider(props) {
  const { kind } = props;

  let styles = kind ? "soft" : "";

  return (
    <hr className={styles} />
  );
}