import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Loading from "../../pages/Loading";
import UserBar from "../../common/users/UserBar";
import MoreButton from "../../common/MoreButton";
import BaseModal from "../../common/modal/BaseModal";
import ModalBody from "../../common/modal/ModalBody";

function UserList(props) {
  const {
    title,
    parentId,
    showModal,
    onRequestClose,
    isLoading,
    showSpinner,
    users,
    pages,
    page,
    onLoadMore,
  } = props;

  /* The API will return either a true/false boolean or null on a User call. If an actual boolean is returned, we want to show the follow button.
   * If null is returned, do not show the follow button. */
  const allUsers = users.map((user) => (
    <UserBar
      key={user.id + "-" + parentId}
      user={user}
      showFollowButton={
        typeof user.is_followed_by_current_user === "boolean" ? true : null
      }
      isFollowing={
        typeof user.is_followed_by_current_user === "boolean"
          ? user.is_followed_by_current_user
          : null
      }
    />
  ));

  const hasRecords = users.length > 0;

  return (
    <BaseModal
      shouldShowCloseAction={true}
      showModal={showModal}
      onRequestClose={onRequestClose}
      modalSize="small"
      hasSmallMargins={hasRecords ? true : false}
    >
      <ModalBody modalTitle={title} hasSmallMargins={hasRecords ? true : false}>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            {hasRecords
              ? allUsers
              : "Nothing to see here yet, but check back soon!"}
            <MoreButton
              showSpinner={showSpinner}
              wide={true}
              onClick={onLoadMore}
              pages={pages}
              page={page}
            />
          </Fragment>
        )}
      </ModalBody>
    </BaseModal>
  );
}

UserList.propTypes = {
  title: PropTypes.string.isRequired,
  parentId: PropTypes.number,
  showModal: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  showSpinner: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onLoadMore: PropTypes.func.isRequired,
};
export default UserList;
