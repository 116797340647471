import React, { Fragment, memo } from "react";
import DisplayImage from "../users/DisplayImage";
import { Link } from "react-router-dom";
import stylesheet from "./Layout.module.scss";

const Hero = memo((props) => {
  const { children, feature, bottomLeftContent } = props;

  const heroBanner = {
    backgroundImage: `url(${
      feature ? (feature.image ? feature.image.url : "") : ""
    })`,
  };

  const uploadTag = (
    <Fragment>
      {feature
        ? feature.user && (
            <Link className={stylesheet.heroArtist} to={`/art/` + feature.id}>
              <div className={stylesheet.heroInfo}>
                Featured art by <strong>{feature.user.display_name}</strong>
              </div>
              <DisplayImage user={feature.user} size={24} link={false} />
            </Link>
          )
        : null}
    </Fragment>
  );

  const bottomContent = (
    <Fragment>
      {bottomLeftContent && (
        <div className={stylesheet.bottomLeftContent}>{bottomLeftContent}</div>
      )}
    </Fragment>
  );

  return (
    <section
      className={`hero is-primary is-medium ${
        !feature && stylesheet.heroEmptyState
      }`}
      style={feature && heroBanner}
    >
      <div className={`hero-body ${!feature && stylesheet.bodyEmptyState}`}>
        <div className={"container"}>{children}</div>
      </div>
      <div className={stylesheet.tagContainer}>
        {uploadTag}
        {bottomContent}
      </div>
    </section>
  );
});
export default Hero;
