import React, { memo, useState } from "react";
import Button from "../buttons/Button";
import stylesheet from "./Uploading.module.scss";

const FileThumb = memo((props) => {
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);
  const { file, removeFile } = props;

  return (
    <article className="media uploaded-media">
      <figure className="media-left">
        <div className="image-thumb">
          <img
            className={`${stylesheet.loadingThumbnail} ${
              thumbnailLoaded ? stylesheet.completeLoading : ""
            }`}
            alt={file.path}
            src={file.preview}
            onLoad={() => setThumbnailLoaded(true)}
          />
        </div>
      </figure>
      <div className="media-content file-uploaded">
        <div className="content uploaded">
          <h2 className="subtitle is-6 filename">{file.path}</h2>
          <Button
            label="Remove file"
            kind="secondary"
            type="button"
            isDanger={true}
            onClick={() => removeFile(file)}
          />
        </div>
      </div>
    </article>
  );
});
export default FileThumb;
