import React from "react";
import PropTypes from "prop-types";
import stylesheet from "./Layout.module.scss";

const Section = (props) => {
  const { id, children, style, styles, classes, showBackground } = props;

  let sectionStyles = "";
  let defaultStyle = "section";
  let slimStyle = "section is-slim";

  if (style === "slim") {
    sectionStyles = slimStyle;
  } else {
    sectionStyles = defaultStyle;
  }

  return (
    <section
      style={styles}
      className={`${sectionStyles} ${
        showBackground ? stylesheet.sectionBg : ""
      } ${classes ? classes : ""}`}
      id={id}
    >
      <div className="container">{children}</div>
    </section>
  );
};
Section.propTypes = {
  id: PropTypes.string,
  style: PropTypes.string,
  styles: PropTypes.object,
  classes: PropTypes.string,
  showBackground: PropTypes.bool,
};
export default Section;
