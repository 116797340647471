// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Post-module__viewPost___3NtXK{padding:0}@media only screen and (min-width: 992px){.Post-module__viewPost___3NtXK{max-width:600px;margin:auto}}\n", "",{"version":3,"sources":["/tmp/build_951a6463/app/javascript/components/posts/Post.module.scss"],"names":[],"mappings":"AAIA,+BACE,SAAU,CACV,0CAFF,+BAGI,eAAgB,CAChB,WAAY,CAEf","file":"Post.module.scss","sourcesContent":["@import \"bulma/sass/utilities/initial-variables\";\n@import \"bulma/sass/utilities/derived-variables\";\n@import \"stylesheets/design-tokens.scss\";\n\n.viewPost {\n  padding: 0;\n  @media only screen and (min-width: 992px) {\n    max-width: 600px;\n    margin: auto;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"viewPost": "Post-module__viewPost___3NtXK"
};
module.exports = exports;
