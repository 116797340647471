import React, { useState, useEffect, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PropTypes from "prop-types";
import styles from "./Collections.module.scss";

const CollectionButton = (props) => {
  const { collection, onClick } = props;

  const [isSelected, setSelected] = useState(false);
  const [isCollected, setCollected] = useState(collection.is_collected);
  const [toggleRemoveIcon, setToggleRemoveIcon] = useState(false);

  /* Show a green checkmark icon to indicate the collections in the queue. */
  const shouldShowCollectedIndicator = isCollected || isSelected;

  /* If the parent component refreshes, fetch the updated collected status so that the list is up to date. */
  useEffect(() => {
    setCollected(collection.is_collected);
  }, [collection]);

  /* On button click, add or remove the collection Id from the submit queue and update the selected state icons. */
  const onCollectionClick = () => {
    onClick(collection.id);

    if (isCollected) {
      setSelected(false);
      setCollected(false);
      setToggleRemoveIcon(false);
    } else {
      setSelected(!isSelected);
      setToggleRemoveIcon(false);
    }
  };

  /* On mouse over, toggle the remove icon if the collection has already been checked. */
  const onHover = () =>
    shouldShowCollectedIndicator
      ? setToggleRemoveIcon(true)
      : setToggleRemoveIcon(false);

  /* Hide the remove icon on mouse leave if the collection is checked. */
  const onHoverExit = () =>
    shouldShowCollectedIndicator ? setToggleRemoveIcon(false) : null;

  /* Truncate long collection names with an ellipsis. */
  const truncate = (input) =>
    input.length > 25 ? `${input.substring(0, 25)}...` : input;

  /* Show a small thumbnail of the collection cover art. Also show a checkmark or minus icon to indicate status. */
  const collectionCoverArt = (
    <Fragment>
      {shouldShowCollectedIndicator ? (
        toggleRemoveIcon ? (
          <div className={styles.removeIcon}>
            <span className="icon">
              <FontAwesomeIcon icon={["fa", "minus"]} />
            </span>
          </div>
        ) : (
          <div className={styles.isCollectedIcon}>
            <span className="icon">
              <FontAwesomeIcon icon={["fa", "check"]} />
            </span>
          </div>
        )
      ) : (
        ""
      )}
      <figure className={styles.cover}>
        {collection.cover_art[0] ? (
          <img src={collection.cover_art[0].thumbnail.url} />
        ) : (
          <div className={styles.coverPlaceholder}></div>
        )}
      </figure>
    </Fragment>
  );

  /* Show the collection's name, truncated, as well as its secret/locked status. */
  const collectionLabel = (
    <div className={styles.buttonLabel}>
      <p>
        {truncate(collection.name)}
        {collection.secret && (
          <span className={`icon ${styles.lockIconSmall}`}>
            <FontAwesomeIcon icon={["fa", "lock"]} />
          </span>
        )}
      </p>
    </div>
  );

  return (
    <button
      className={
        shouldShowCollectedIndicator
          ? styles.collectionButtonActive
          : styles.collectionButton
      }
      onClick={() => onCollectionClick()}
      onMouseEnter={() => onHover()}
      onMouseLeave={() => onHoverExit()}
    >
      <div className={styles.buttonContainer}>
        {collectionCoverArt}
        {collectionLabel}
      </div>
    </button>
  );
};

CollectionButton.propTypes = {
  collection: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default CollectionButton;
