import React, { useMemo, useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import stylesheet from "./Uploading.module.scss";
import ModalBody from "../modal/ModalBody";
import ButtonsBar from "../modal/ButtonsBar";
import Button from "../buttons/Button";
import ErrorWell from "../wells/ErrorWell";
import FileThumb from "./FileThumb";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "24px",
  borderWidth: 2,
  borderRadius: 8,
  borderColor: "#DBDBDB",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#636363",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#00d1b2",
};

const acceptStyle = {
  borderColor: "#00d1b2",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
};

export default function ImageUploader(props) {
  const [myFiles, setMyFiles, setFiles, files] = useState([]);
  const [showError, setError] = useState(false);
  const [showUploader, toggleUploader] = useState(true);
  const [showAdditionalUploader, toggleAdditionalUploader] = useState(false);
  const [queueCount, setQueueCount] = useState(0);
  const MAX_ALLOWED_UPLOADS = 5;

  const [errorLabel, setErrorLabel] = useState(
    "Something went wrong. Refresh the page and try again."
  );

  const onDropAccepted = useCallback(
    (myFiles) => {
      setError(false);

      if (!props.allowMultiple) {
        myFiles.map((file) => {
          props.selectImage(file);
        });
        toggleUploader(false);
      } else {
        toggleUploader(false);
        toggleAdditionalUploader(true);
      }
    },
    [myFiles]
  );

  useEffect(() => {
    if (!props.setModalSize) {
      return;
    }
    document.onkeydown = null;

    if (props.isActive) {
      props.setModalSize("small");
    } else if (props.setsProfileCover) {
      props.setModalSize("editor");
    } else {
      props.setModalSize("card");
    }
  }, [props.isActive]);

  useEffect(() => {
    if (queueCount === 0) {
      toggleUploader(true);
      toggleAdditionalUploader(false);
      return;
    } else if (queueCount > MAX_ALLOWED_UPLOADS) {
      setError(true);
      setErrorLabel(
        "You can only upload " + MAX_ALLOWED_UPLOADS + " files at a time."
      );
    } else {
      setError(false);
    }
  }, [queueCount]);

  useEffect(() => {
    if (props.allowMultiple) {
      props.setUploadQueue(myFiles);
    }
  }, [myFiles]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      if (props.allowMultiple) {
        setMyFiles([...myFiles, ...acceptedFiles]);
        props.setUploadQueue([...myFiles, ...acceptedFiles]);

        let filesLength = parseInt(myFiles.length);
        let acceptedFilesLength = parseInt(acceptedFiles.length);
        setQueueCount(filesLength + acceptedFilesLength);
      } else {
        setQueueCount(1);
        setMyFiles([...acceptedFiles]);
      }
    },
    [myFiles]
  );

  const onDropRejected = (event) => {
    if (event[0].errors[0].code === "file-too-large") {
      setErrorLabel("Artwork files can't be larger than 30 MB.");
      setError(true);
    } else if (event[0].errors[0].code === "file-invalid-type") {
      setErrorLabel("File types can only be png, jpeg, or gif.");
      setError(true);
    } else if (event[0].errors[0].code === "too-many-files") {
      setErrorLabel("You can only choose one file.");
      setError(true);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    open,
  } = useDropzone({
    accept: "image/jpeg,image/png,image/gif",
    maxSize: 30000000, // Max file size is 30 MB.
    multiple: props.allowMultiple ? props.allowMultiple : false,
    noClick: true,
    noKeyboard: true,
    onDropAccepted,
    onDrop,
    onDropRejected,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const removeFile = useCallback(
    (file) => {
      setMyFiles((files) =>
        files.filter((filesListed) => filesListed !== file)
      );
      setQueueCount((queueCount) => queueCount - 1);

      if (!props.allowMultiple) {
        props.unselectImage();
      }
    },
    [myFiles]
  );

  /*
   *  Handle the next button behaviour. Trigger the file choice dialog if there are no uploaded files.
   */
  const onNext = () => {
    if (showError) {
      return;
    } else if ([...myFiles].length > 0) {
      props.nextStep();
    } else {
      open();
    }
  };

  const thumbs = myFiles.map((file, index) => (
    <FileThumb key={file + "-" + index} file={file} removeFile={removeFile} />
  ));

  const uploader = (
    <div id="uploader" className="has-text-centered">
      <input {...getInputProps()} />
      <p>
        Drag and drop {props.allowMultiple ? "up to 5 files" : "a file"}, or
      </p>
      <Button
        label={props.allowMultiple ? "Choose files" : "Choose a file"}
        kind="secondary"
        type="button"
        onClick={open}
      />
    </div>
  );

  const moreFilesUploader = (
    <div id="uploader" className={stylesheet.moreFilesUploader}>
      <input {...getInputProps()} />
      <Button
        label={"Choose more files"}
        kind="secondary"
        type="button"
        onClick={open}
      />
    </div>
  );

  const uploadedFiles = <aside style={thumbsContainer}>{thumbs}</aside>;

  return (
    <div>
      <ModalBody
        modalTitle={props.uploaderTitle ? props.uploaderTitle : "Upload image"}
      >
        <div className="container">
          {showError && <ErrorWell label={errorLabel} />}
          {/* {props.showIntro && (
            <article className="message is-info">
              <div className="message-body">
                This is the upload wizard! For science, could you upload
                multiple files? Up to 5 if you’re keen! We’re tweaking under the
                hood, so the more you submit, the more we can improve!
              </div>
            </article>
          )} */}
          <div className="uploader" {...getRootProps({ style })}>
            {uploadedFiles}
            {showUploader && uploader}
            {showAdditionalUploader && moreFilesUploader}
          </div>
        </div>
      </ModalBody>
      <ButtonsBar>
        <Button label="Next" kind="primary" type="button" onClick={onNext} />
      </ButtonsBar>
    </div>
  );
}
