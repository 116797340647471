import React, { useState, useEffect, Fragment } from "react";

import { fetchLikedByList } from "../ArtworkAPI";
import { fetchPostLikedByList } from "../../posts/PostAPI";
import UserList from "../../common/users/UserList";

function AllUsers(props) {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);

  const startingPage = {
    params: {
      page: page,
    },
  };

  useEffect(() => {
    /* Only fetch if the modal is opened */
    if (props.showModal) {
      _fetchUsers(startingPage);
    }

    /* Reset the component to defaults in case the modal is closed or the user hits next or previous */
    return function cleanup() {
      setUsers([]);
      setPage(1);
      setIsLoading(true);
    };
  }, [props.objectId, props.showModal]);

  /* Fetch users on page change */
  useEffect(() => {
    if (props.showModal) {
      _fetchUsers(startingPage);
    }
  }, [page]);

  const fetchUserList = (id, pageNum) => {
    if (props.kind === "post") {
      return fetchPostLikedByList(id, pageNum);
    } else {
      return fetchLikedByList(id, pageNum);
    }
  };

  const _fetchUsers = (page) => {
    setShowSpinner(true);

    setTimeout(
      function () {
        fetchUserList(props.objectId, page)
          .then((response) => {
            if (response.data) {
              const appendedData = [...users, ...response.data.users];
              setUsers(appendedData);

              setPage(parseInt(response.data.page));
              setPages(parseInt(response.data.pages));
              setIsLoading(false);
            } else {
              console.log("ERROR");
            }
            setShowSpinner(false);
          })
          .catch((error) => console.log("api errors:", error));
      }.bind(this),
      500
    );
  };

  const handleLoadMore = () => {
    if (page < pages) {
      setPage(page + 1);
    }
  };

  return (
    <Fragment>
      {props.showModal && (
        <UserList
          title={props.likesCount > 0 ? props.likesCount + " likes" : "Likes"}
          parentId={props.objectId}
          showModal={props.showModal}
          onRequestClose={props.toggleModal}
          isLoading={isLoading}
          showSpinner={showSpinner}
          users={users}
          pages={pages}
          page={page}
          onLoadMore={handleLoadMore}
        />
      )}
    </Fragment>
  );
}
export default AllUsers;
