import React, { useState } from "react";
var noScroll = require("no-scroll");

import BaseModal from "../common/modal/BaseModal";
import ModalBody from "../common/modal/ModalBody";
import ButtonsBar from "../common/modal/ButtonsBar";
import PrimaryButton from "../common/buttons/PrimaryButton";
import Header from "../common/typography/Header";
import Paragraph from "../common/typography/Paragraph";
import stylesheet from "./Pages.module.scss";

function WelcomeModal(props) {
  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    noScroll.off();
    setShowModal(false);
  };

  return (
    <BaseModal
      showModal={showModal}
      onRequestClose={closeModal}
      modalSize="medium"
    >
      <ModalBody>
        <Header
          level={1}
          size={"medium"}
          message={"Welcome to Animatik!"}
          classes={stylesheet.welcomeHeader}
        />
        <Paragraph styles={{ textAlign: "center" }}>
          We're a brand new community for artists, designed to showcase art and
          help fans discover your work.
        </Paragraph>
        <Paragraph styles={{ textAlign: "center" }}>
          Once you're ready, say hello to the community!
        </Paragraph>
      </ModalBody>
      <ButtonsBar>
        <PrimaryButton
          type="button"
          type="submit"
          label={"Let's go!"}
          onClick={closeModal}
        />
      </ButtonsBar>
    </BaseModal>
  );
}
export default WelcomeModal;
