import React, {
  useReducer,
  useEffect,
  useState,
  useContext,
  Fragment,
} from "react";
import ReactTags from "react-tag-autocomplete";
import { withRouter } from "react-router-dom";
import getCroppedImg from "../../common/uploading/cropImage";
import PropTypes from "prop-types";
import { useDebouncedEffect } from "../../navigation/useDebouncedEffect";
import { Link } from "react-router-dom";
import stylesheet from "../Artwork.module.scss";
import { globalContext } from "../../../util/globalContext.js";
import {
  updateArtwork,
  fetchArtwork,
  deleteArtwork,
  submitArtwork,
} from "../ArtworkAPI";
import { validate, isValid } from "../ArtworkValidator";
import { fetchTagHints } from "../../search/SearchAPI";

import ImageCropper from "../../common/uploading/ImageCropper";
import BaseModal from "../../common/modal/BaseModal";
import ConfirmModal from "../../common/modal/ConfirmModal";
import TextInput from "../../common/TextInput";
import TextArea from "../../common/TextArea";
import Switch from "../../common/controls/Switch";
import ModalBody from "../../common/modal/ModalBody";
import ButtonsBar from "../../common/modal/ButtonsBar";
import Button from "../../common/buttons/Button";
import TagSuggestion from "../../common/tagging/TagSuggestion";

const initialState = {
  showCropModal: false,
  showConfirmModal: false,
  croppedThumbnail: null,
  image: null,
  title: "",
  caption: "",
  tags: [],
  suggestions: [],
  nsfw: false,
  critiques: false,
  title_error: "",
  caption_error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_CROPPER_MODAL":
      return { ...state, showCropModal: !state.showCropModal };
    case "TOGGLE_CONFIRM_MODAL":
      return { ...state, showConfirmModal: !state.showConfirmModal };
    case "LOAD_STATE":
      return { ...state, ...action.data };
    case "UPDATE":
      return {
        ...state,
        [action.field]: action.value,
        [action.field + "_error"]: "",
      };
    case "CONVERT_TAGS":
      return {
        ...state,
        tags: action.data.map(function (tag, index) {
          return { id: index, name: tag };
        }),
      };
    case "ADD_TAG":
      return { ...state, tags: [...state.tags, ...action.data] };
    case "REMOVE_TAG":
      return {
        ...state,
        tags: state.tags.filter((tag, index) => {
          return index !== action.data;
        }),
      };
    case "SET_SUGGESTED_TAGS":
      return { ...state, suggestions: action.data };
    default:
      return state;
  }
};

const ArtworkEditor = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showSpinner, setShowSpinner] = useState(false);
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);
  const [tagFocus, setTagFocus] = useState(false);
  const [tagQuery, setTagQuery] = useState("");
  const [readyToSubmit, setReadyToSubmit] = useState(false);

  const {
    showCropModal,
    showConfirmModal,
    image,
    croppedThumbnail,
    title,
    caption,
    tags,
    suggestions,
    nsfw,
    critiques,
    title_error,
    caption_error,
  } = state;

  const {
    modalTitle,
    stepCount,
    queuedImage,
    setModalSize,
    pushToQueue,
    isFinalStep,
    isActive,
    previousStep,
    nextStep,
    submitQueue,
    setSummary,
  } = props;

  const isEditingArtwork = typeof props.match.params.id !== "undefined";
  const globalState = useContext(globalContext);

  /* If isEditing artwork, fetch the art's details by API. Otherwise, fetch from the parent uploader component. */
  useEffect(() => {
    if (isEditingArtwork) {
      fetchArtworkDetails();
    }
  }, []);

  useEffect(() => {
    if (queuedImage && queuedImage !== null) {
      updateDetailsFromUploader();
      setThumbnailLoaded(false);
    }
  }, [queuedImage]);

  useEffect(() => {
    if (isActive) {
      setModalSize("editor");
    }
    if (isActive && !croppedThumbnail) {
      getDefaultThumbnail(queuedImage.preview, queuedImage.path);
    }
  }, [isActive]);

  /* If submitting new art, check if ready to submit- then loop through the queue for each queued submission and submit. */
  useEffect(() => {
    if (!readyToSubmit) return;

    submitQueue.forEach(function (submission, i) {
      submitArtwork(submission)
        .then((response) => {
          setSummary(response.data);
          if (i + 1 === submitQueue.length) {
            nextStep();

            /* If the current user hasn't been onboarded yet, fetch the latest onboarding status to update the task list. */
            if (!globalState.currentUser.onboarded_at) {
              globalState.refreshOnboarding();
            }
          }
        })
        .catch((error) => console.log("api errors:", error));
    });
    setReadyToSubmit(false);
  }, [readyToSubmit]);

  /* When tags are typed, debounce the API call to fetch hints only after the user stops typing. */
  useDebouncedEffect(
    () => {
      fetchHints(tagQuery);
    },
    450,
    [tagQuery]
  );

  /* If isEditingArtwork, fetch the artwork details */
  const fetchArtworkDetails = () => {
    fetchArtwork(props.match.params.id)
      .then((response) => {
        if (response.data.artwork) {
          dispatch({
            type: "LOAD_STATE",
            data: {
              croppedThumbnail: response.data.artwork.large_thumbnail,
              title: response.data.artwork.title
                ? response.data.artwork.title
                : "",
              caption: response.data.artwork.caption
                ? response.data.artwork.caption
                : "",
              nsfw: response.data.artwork.nsfw,
              critiques: response.data.artwork.critiques,
            },
          });
          dispatch({
            type: "CONVERT_TAGS",
            data: response.data.artwork.tags,
          });
        }
      })
      .catch(() =>
        globalState.showToast("Something went wrong. Refresh and try again.")
      );
  };

  /* When the parent uploader's state changes, we need to refresh the editor's details to match. */
  const updateDetailsFromUploader = () => {
    dispatch({
      type: "LOAD_STATE",
      data: {
        image: queuedImage,
        title: queuedImage.name.substring(0, queuedImage.name.lastIndexOf(".")),
        croppedThumbnail: null,
        caption: "",
        nsfw: false,
        critiques: false,
        title_error: "",
        caption_error: "",
      },
    });
  };

  /* Use the cropper function to retrieve a default thumbanil. */
  const getDefaultThumbnail = async (imagePreview, imagePath) => {
    try {
      const croppedImage = await getCroppedImg(imagePreview);
      var newFile = new File([croppedImage], imagePath, {
        lastModified: new Date(),
      });
      Object.assign(newFile, { preview: URL.createObjectURL(newFile) });
      dispatch({
        type: "LOAD_STATE",
        data: {
          croppedThumbnail: newFile,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  /* Show and hide the thumbnail cropper modal. */
  const toggleCropperModal = () => {
    dispatch({
      type: "TOGGLE_CROPPER_MODAL",
    });
  };

  /* Show and hide the confirm deletion modal. */
  const toggleConfirmModal = () => {
    dispatch({
      type: "TOGGLE_CONFIRM_MODAL",
    });
  };

  /* Set the newly updated thumbnail */
  const setCroppedThumbnail = (image) => {
    dispatch({
      type: "UPDATE",
      field: "croppedThumbnail",
      value: image,
    });
  };

  /* Update the reducer state when input fields are changed */
  const handleChange = (e) => {
    dispatch({
      type: "UPDATE",
      field: e.target.name,
      value: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  /* Use the validator to set errors when the user tabs or clicks out of the field. */
  const validateField = (e) => {
    var validatedInput = validate({ [e.target.name]: e.target.value });

    dispatch({
      type: "LOAD_STATE",
      data: validatedInput,
    });
  };

  const deleteUpload = () => {
    deleteArtwork(props.match.params.id)
      .then((response) => {
        if (response.data.deleted) {
          props.history.push(
            "/" + globalState.currentUser.username + "/gallery"
          );
          window.location.reload();
        }
      })
      .catch(() =>
        globalState.showToast("Something went wrong. Refresh and try again.")
      );
  };

  /* Convert the Tagger component's tag objects into a comma separated string to be accepted by the API. */
  const formatTags = (unformattedTags) => {
    return Array.prototype.map
      .call(unformattedTags, function (tag) {
        return tag.name;
      })
      .join(",");
  };

  /* The final step may either proceed to the next step or submit the form. */
  const submitHandler = (event) => {
    event.preventDefault();

    if (!croppedThumbnail) {
      return;
    }

    /* First run front-end validation before submitting the data to update. If there are errors, return. */
    const updatedProperties = {
      title: title,
      caption: caption,
    };

    var validatedInput = validate(updatedProperties);
    if (!isValid(validatedInput)) {
      dispatch({
        type: "LOAD_STATE",
        data: validatedInput,
      });
      return;
    }

    const artworkToUpload = new FormData();
    if (!isEditingArtwork) {
      artworkToUpload.append("image", image);
      artworkToUpload.append("thumbnail", croppedThumbnail);
    }
    artworkToUpload.append("title", title);
    artworkToUpload.append("caption", caption);
    artworkToUpload.append("nsfw", nsfw);
    artworkToUpload.append("critiques", critiques);
    artworkToUpload.append("tags", formatTags(tags));

    if (isEditingArtwork) {
      setShowSpinner(true);
      updateArtwork(props.match.params.id, artworkToUpload)
        .then(() => {
          globalState.showToast("You edited your artwork");
          props.history.push(`/art/${props.match.params.id}`);
          window.location.reload();
        })
        .catch(() =>
          globalState.showToast("Something went wrong. Refresh and try again.")
        );
      return;
    } else if (isFinalStep) {
      setShowSpinner(true);
      pushToQueue(artworkToUpload);
      setReadyToSubmit(true); // If Final step, trigger the useEffect to submit the form.
    } else {
      pushToQueue(artworkToUpload);
      nextStep();
    }
  };

  /* Send an API request to return suggested tags already in use, sorted by popularity. */
  const fetchHints = (hintQuery) => {
    /* Only fetch when there are at least 2 characters to fetch results. */
    if (hintQuery.length < 2) {
      return;
    }

    const query = {
      params: {
        query: hintQuery,
      },
    };
    fetchTagHints(query)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "SET_SUGGESTED_TAGS",
            data: response.data.tags.reverse(), // Reverse the returned array because we display tag hints above the input field rather than below
          });
        } else {
          globalState.showToast("Something went wrong. Refresh and try again.");
        }
      })
      .catch(() =>
        globalState.showToast("Something went wrong. Refresh and try again.")
      );
  };

  const onTagDelete = (i) => {
    dispatch({
      type: "REMOVE_TAG",
      data: i,
    });
  };

  const onTagAddition = (tag) => {
    dispatch({
      type: "ADD_TAG",
      data: [tag],
    });
  };

  /* Component layout */

  const nextButtonLabel = isFinalStep ? "Publish" : "Next";
  const thumbnailUrl = croppedThumbnail ? croppedThumbnail.url : "";
  const thumbnailPreview = croppedThumbnail ? croppedThumbnail.preview : "";

  const thumbnailCropperModal = (
    <BaseModal
      shouldShowCloseAction={false}
      showModal={showCropModal}
      modalSize={"medium"}
      onRequestClose={toggleCropperModal}
      isSecondModal={true}
    >
      <ImageCropper
        selectedImage={image}
        setCroppedThumbnail={setCroppedThumbnail}
        toggleCropperModal={toggleCropperModal}
        cropperModalTitle={"Crop thumbnail"}
        buttonLabel={"Apply changes"}
        showBackButton={false}
      />
    </BaseModal>
  );

  const modalBody = (
    <div>
      <ModalBody
        modalTitle={modalTitle ? modalTitle : "Edit artwork"}
        titleSubtext={stepCount ? stepCount : ""}
      >
        <div className={stylesheet.uploadDetails}>
          <figure className={stylesheet.previewThumbnail}>
            {isEditingArtwork && <img src={thumbnailUrl} />}
            {!isEditingArtwork && (
              <Fragment>
                <div
                  className={`${stylesheet.loadingThumbnail} ${
                    thumbnailLoaded ? stylesheet.completeLoading : ""
                  }`}
                >
                  <div className={stylesheet.cropButton}>
                    <Button
                      kind={"overlay"}
                      label={"Crop"}
                      type={"button"}
                      icon={"crop"}
                      classes={"is-small"}
                      onClick={toggleCropperModal}
                    />
                  </div>
                  <img
                    className={stylesheet.thumb}
                    onLoad={() => setThumbnailLoaded(true)}
                    src={thumbnailPreview}
                  />
                </div>
                {!thumbnailLoaded && (
                  <div
                    className={`loader-wrapper is-active ${stylesheet.previewSpinner}`}
                  >
                    <div className="loader-thick is-loading"></div>
                  </div>
                )}
              </Fragment>
            )}
          </figure>
          <div className={stylesheet.infoFields}>
            <TextInput
              label="Title"
              name="title"
              id={isEditingArtwork ? "title" : "title-" + queuedImage.path}
              value={title}
              error={title_error}
              placeholder="Title your artwork"
              onChange={handleChange}
              onBlur={validateField}
            />
            <TextArea
              label="Caption"
              name="caption"
              id={isEditingArtwork ? "caption" : "caption-" + queuedImage.path}
              value={caption}
              error={caption_error}
              placeholder="Tell the community about this artwork."
              rows={4}
              onChange={handleChange}
              onBlur={validateField}
            />
          </div>
        </div>

        <div className={stylesheet.additionalSettings}>
          <div className="field">
            <label className="label">Tags</label>
            <div className={`control ${tagFocus ? "tagger-focused" : ""}`}>
              <ReactTags
                suggestions={suggestions}
                tags={tags}
                onDelete={onTagDelete}
                onAddition={onTagAddition}
                allowNew={true}
                onInput={(query) => setTagQuery(query)}
                onFocus={() => setTagFocus(true)}
                onBlur={() => setTagFocus(false)}
                suggestionComponent={(item) => (
                  <TagSuggestion item={item.item} query={tagQuery} />
                )}
                placeholderText={"Add a tag"}
                delimiters={[
                  "Enter",
                  "Tab",
                  " ",
                  ",",
                  "#",
                  "-",
                  "_",
                  ":",
                  "@",
                  "[",
                  "]",
                ]}
              />
            </div>
          </div>
          <Switch
            label="Request critiques and feedback"
            name="critiques"
            id={
              isEditingArtwork ? "critiques" : "critiques-" + queuedImage.path
            }
            checked={critiques}
            onChange={handleChange}
            helperCopy={
              <Fragment>
                This lists your artwork on{" "}
                <Link className={"link"} to={`/critiques`} target="_blank">
                  Critiques
                </Link>{" "}
                and encourages commenters to leave feedback.
              </Fragment>
            }
          />
          <Switch
            label="Contains mature content"
            name="nsfw"
            id={isEditingArtwork ? "nsfw" : "nsfw-" + queuedImage.path}
            checked={nsfw}
            onChange={handleChange}
          />
        </div>
      </ModalBody>
      <ButtonsBar
        leftButtons={
          isEditingArtwork && (
            <Button
              kind={"secondary"}
              isDanger={true}
              label={"Delete artwork"}
              type={"button"}
              onClick={toggleConfirmModal}
            />
          )
        }
      >
        {!isEditingArtwork && (
          <Button
            kind={"ternary"}
            label={"Back"}
            type={"button"}
            onClick={previousStep}
          />
        )}
        <Button
          kind={"primary"}
          label={isEditingArtwork ? "Apply changes" : nextButtonLabel}
          type={"button"}
          showSpinner={showSpinner}
          onClick={submitHandler}
        />
      </ButtonsBar>

      {!isEditingArtwork && thumbnailCropperModal}
    </div>
  );

  const editModal = (
    <Fragment>
      <BaseModal showModal={true} modalSize={"editor"}>
        {modalBody}
      </BaseModal>
      <ConfirmModal
        showConfirmModal={showConfirmModal}
        modalTitle="Delete artwork?"
        modalText="You're about to permanently delete this artwork, and it will not be recoverable. Are you sure to want to continue?"
        confirmButtonLabel="Yes, delete"
        backButtonLabel="No"
        toggleModal={toggleConfirmModal}
        onConfirm={deleteUpload}
      />
    </Fragment>
  );

  return isEditingArtwork ? editModal : modalBody;
};
ArtworkEditor.propTypes = {
  modalTitle: PropTypes.string,
  stepCount: PropTypes.string,
  queuedImage: PropTypes.object,
  setModalSize: PropTypes.func,
  pushToQueue: PropTypes.func,
  isFinalStep: PropTypes.bool,
  isActive: PropTypes.bool,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  submitQueue: PropTypes.array,
  setSummary: PropTypes.func,
};
export default withRouter(ArtworkEditor);
