import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { submitPostComment, loadPostComments } from "../comments/CommentsAPI";
import { fetchPost } from "./PostAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import stylesheet from "./Post.module.scss";
import Divider from "../common/layout/Divider";
import Header from "../common/typography/Header";
import Section from "../common/layout/Section";
import Loading from "../pages/Loading";
import FeedPost from "../feed/FeedPost";
import CommentContainer from "../comments/CommentContainer";

const ViewPost = (props) => {
  const [post, setPost] = useState([]);
  const [comments, setComments] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const { currentUser, showToast } = props;

  /* Get location for edit modal background */
  const location = useLocation();

  /* Fetch the collection on page load */
  useEffect(() => {
    document.title = "Post / Animatik";

    /* Fetch the collection */
    fetchPost(props.match.params.id)
      .then((response) => {
        if (response.data.errors) {
          props.history.push("/not-found");
          return;
        }
        if (response.data.post) {
          setPost(response.data.post);
          document.title =
            "Post by " + response.data.post.user.display_name + " / Animatik";
          setComments(response.data.comments);
        }
        setLoading(false);
      })
      .catch((error) => {
        props.history.push("/not-found");
      });
  }, [props.match.params.id]);

  const newCommentHandler = (parentId, body) => {
    return submitPostComment(parentId, body);
  };

  const loadCommentHandler = (parentId, query) => {
    return loadPostComments(parentId, query);
  };

  const scrollToComments = (ref) => {
    if (ref && location.hash.includes("#comments")) {
      setTimeout(function () {
        ref.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 750);
    }
  };

  const editPost = (
    <Link
      className={"button is-rounded is-primary is-outlined"}
      to={{
        pathname: `/posts/edit/${post.id}`,
        state: { background: location },
      }}
      style={{ marginLeft: "0.5rem" }}
    >
      <span className="icon">
        <FontAwesomeIcon icon={["fa", "edit"]} />
      </span>
      <span>Edit</span>
    </Link>
  );

  return (
    <Section showBackground={true}>
      {isLoading ? (
        <Loading />
      ) : (
        <FeedPost
          kind={"post"}
          objectData={post}
          currentUser={currentUser}
          showToast={showToast}
          classes={stylesheet.viewPost}
          buttonBarItems={currentUser.id === post.user.id && editPost}
          bottomContent={
            <div ref={scrollToComments}>
              <Divider kind={"soft"} />
              <Header
                level={3}
                size={"small"}
                message={"Comments"}
                styles={{ marginBottom: "1.5rem" }}
              />
              <CommentContainer
                parentId={post.id}
                comments={comments}
                newCommentHandler={newCommentHandler}
                loadCommentHandler={loadCommentHandler}
              />
            </div>
          }
        />
      )}
    </Section>
  );
};
export default ViewPost;
